import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { palette } from '../../../../utils/Palette';

const Container = styled.div`
    padding: 20px 10px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.h4`
    margin: 0;
    font-size: 1.25rem;
    color: #333;
`;

const CloseIcon = styled(Cancel)`
    cursor: pointer;
    color: #888;
    font-size: 1.2rem;
`;

const SearchInput = styled.input`
    width: 95%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    background: #f3f3f3;
    margin-bottom: 20px;

    &:focus {
        outline: 1.5px solid black;
        border: 0.5px solid ${palette.charcoleBlack};
      
      }
      ::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: gray;
      }
`;

const AddressList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const AddressItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 8px;
    cursor: pointer;
`;

const AddressDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const AddressTitle = styled.h5`
    margin: 0;
    font-size: 14px;
    color: #333;
    text-align: start;
    font-weight: 500;
`;

const AddressText = styled.p`
    margin: 0;
    font-size: 13px;
    color: #666;
    text-align: start;
    margin-top: 5px;
`;

const AddressSelector = ({
    selectedContact,
    onClick,
    searchPlaceholder = "Search",
    onClose,
    value,
    nonHeader
}) => {
    const [search, setSearch] = useState("");

    const searchFilterFunction = (value) => {
        setSearch(value);
    };

    const filteredAddresses = selectedContact?.filter((item) =>
        item?.title?.toLowerCase().includes(search.toLowerCase()) ||
        item?.address?.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Container>
           {!nonHeader&& <Header>
                <Title>Select Address</Title>
                <CloseIcon onClick={onClose} />
            </Header>}
            <SearchInput
                placeholder={searchPlaceholder}
                value={search}
                onChange={(e) => searchFilterFunction(e.target.value)}
            />
            <AddressList>
                {filteredAddresses?.map((item, index) => (
                    <AddressItem key={index} onClick={() => onClick(item)}>
                        <AddressDetails>
                            <Radio
                                checked={
                                    item?.address === value?.address &&
                                    item?.title === value?.title
                                }
                                style={{ marginRight: "10px", color: "#0F52BA" }}
                            />
                            <div>
                                <AddressTitle>
                                    {item?.title || `Address ${index + 1}`}
                                </AddressTitle>
                                <AddressText>{item?.address}</AddressText>
                            </div>
                        </AddressDetails>
                    </AddressItem>
                ))}
            </AddressList>
        </Container>
    );
};

// PropTypes for the component
AddressSelector.propTypes = {
    selectedContact: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    value: PropTypes.shape({
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
    }).isRequired,
    nonHeader:PropTypes.bool.isRequired
};

export default AddressSelector;
