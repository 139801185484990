import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { palette } from '../../../../utils/Palette';
import RippleButton from '../../../../ReuseableComponets/RippleButton';
import { Cancel } from '@mui/icons-material';
import { submitPaymentMethod } from '../../../../store/Actions/JobAction';

const CheckoutForm = (props) => {
    const [value, setValue] = useState(props?.value?.name||'')
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch()

    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: value,


            },
        });
        if (!result?.error) {
            console.log(result, "result")
            const res=await dispatch(submitPaymentMethod(props?.value?.contactId,result?.paymentMethod?.id))
            console.log(res,"sdsdjbhjdsbdc")
            if(res?.status===true){
                props?.onClose()
            }
            else{
                setError(res?.data?.data)
            }
           
        }
        else {
            console.log(result, "error")
            setError(result.error.message)
        }
        console.log(elements.getElement(CardNumberElement))
        console.log(result, "result")

        if (result.error) {
            console.log(result.error.message);
        } else {
        }
        setLoader(false)
    };

    return (
        <React.Fragment>

            <form onSubmit={handleSubmit} style={{ padding: '25px 0px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>
                        {"Add Payment method"}
                    </h4>
                    <Cancel onClick={() => {
                        props?.onClose()
                    }} style={{ cursor: 'pointer' }} />
                </div>
                <Label>Cardholder Name</Label>
                <CardInputWrapper tabindex="0">

                    <Input placeholder='Cardholder Name' value={value} onChange={(event) => setValue(event.target.value)} />
                </CardInputWrapper>
                <Label>Card Number</Label>
                <CardInputWrapper>
                    <CardNumberElement
                        options={{
                            showIcon: true,
                            style: {
                                base: {
                                    iconColor: '#000',

                                    fontWeight: '500',
                                    fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
                                    fontSize: '16px',
                                    fontSmoothing: 'antialiased',
                                    ':-webkit-autofill': {

                                    },
                                    '::placeholder': {
                                    },
                                },
                            },
                        }}
                    />
                </CardInputWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '47%' }}>
                        <Label>Expiraion Day (MM/YY)</Label>
                        <CardCvInputWrapper
                            style={{ marginRight: '10px' }}
                        >
                            <CardExpiryElement options={{
                                showIcon: true,
                            }} />

                        </CardCvInputWrapper>
                    </div>
                    <div style={{ width: '47%' }}>
                        <Label>CVC</Label>
                        <CardCvInputWrapper>

                            <CardCvcElement
                                options={{
                                    showIcon: true,
                                }}
                            />
                        </CardCvInputWrapper>

                    </div>
                </div>


                {
                    error ? <div>
                        <br />
                        <p style={{ fontSize: '14px', color: '#D81159',textAlign:'start' }}>{error}</p>
                    </div> : null
                }
                {/* <Label>billing address</Label>
                <p style={{ fontSize: '13px',marginLeft:'5px', textAlign:'start',marginBottom:'0px' }}>{props?.value?.address}</p> */}

                <br />
                <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                        props?.onClose()
                    }}>Cancel</RippleButton>
                    <RippleButton onClick={handleSubmit} disabled={loader ? true : false} variant='dark' size="lg" >
                        Save
                    </RippleButton>
                </div>
            </form>
        </React.Fragment>
    )
};
export default CheckoutForm

const Label = styled.label`

                    font-weight: 600;
                    font-size: 13px;
                    margin-left:4px;
                    color: ${palette.charcoleBlack};
                    display: flex;
                    margin-bottom: 8px;
                    margin-top:20px;
                    align-self: flex-start;
                    font-family:Inter;
                
                  
                `
const CardInputWrapper = styled.div`
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    padding: 10px;
                    margin-top:5px;
                    background:#fff;
                    &:focus {
                        outline: 1.5px solid black;
                        border: 0.5px solid ${palette.charcoleBlack};
                      
                      }
                      ::placeholder {
                        font-size: 14px;
                        font-weight: 300;
                        color: gray;
                      }
                    `;
const CardCvInputWrapper = styled.div`
                    width:91%;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    padding: 10px ;
                    background:#fff;
                    margin-top:5px;
                    &:focus {
                        outline: 1.5px solid black;
                        border: 0.5px solid ${palette.charcoleBlack};
                      
                      }
                      ::placeholder {
                        font-size: 14px;
                        font-weight: 300;
                        color: gray;
                      }
                    `;

const Input = styled.input`
                    border: none;
                    display:flex;
                    width:90%;
                    background:#FAFAFA;
                    font-size:14px;
                    &:focus {
                        outline: none;
                    box-shadow: 0px 0px 0px white;
                    border:0px solid #FAFAFA;
   
  }

                    `
