import Constants from "../../../utils/Constant";

const initialState = {
    territoryData: {}
}

const ReducerContacts = (state = initialState, action) => {
    switch (action.type) {
        case Constants.TYPE.TerritoryData:
            return ({ ...state, territoryData: action.payload })
        default:
            return state;
    }

}

export default ReducerContacts;