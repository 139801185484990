import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from '../utils/Palette';
const RippleButton = ({ onClick, children,...props }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async (e) => {
        setLoading(true);
        await onClick(e);
        setLoading(false);
    };

    return (
        <SendButton style={{border:loading?`1px solid ${palette.smokeWhite} `:'1px solid #4169e1',background:loading?`${palette.smokeWhite}`:'#4169e1'}} {...props} onClick={handleClick} disabled={loading}>
            {loading ? <Loader /> : children}
        </SendButton>
    );
};

RippleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default RippleButton;



const SendButton = styled.button`
  border: 1px solid #4169e1;
  background: ${palette.newBlue};
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing:0.5px;
  padding: 0 25px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:disabled {
    background: ${palette.lightGrey};
    border: 1px solid ${palette.lightGrey};
    padding: 0 30px;
    cursor: not-allowed;

  }
`;

const Loader = styled.div`
  border: 3px solid #d3d3d3; 
  border-top: 2px solid #4169e1;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 0.6s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


