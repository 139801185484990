import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { palette } from '../../utils/Palette';
import { Cancel, CancelOutlined, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import InputBox from '../../ReuseableComponets/InputBox';
import RippleButton from '../../ReuseableComponets/RippleButton';
import { createChecklist } from '../../store/Actions/SettingsAction';
import { Avatar, IconButton } from '@mui/material';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width:100%;
  background-color: ${palette.white};

`;


const Title = styled.h5`
  font-size: 20px;
  font-weight: 500;
margin-left:8px;
  color: black;
  text-align:start;
  margin-bottom:0px;
  margin-top:15px;
`;

const ScrollView = styled.div`
  background-color: #ffffff;
  overflow-y: auto;
  padding-top:10px;


`;

const ItemContainer = styled.div`
  margin-bottom: 15px;
  width:98%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left:5px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;


const CheckboxText = styled.span`
  font-size: 14px;
  color: ${palette.black};
  font-weight: 500;
  margin-left: 10px;
`;

const LinkText = styled.a`
  padding-top: 5px;
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  text-align: left; /* Ensure text is aligned to the left */
  align-self: flex-start;
  display: inline-block;
`;
const StyledAvatar = styled(Avatar)`
  background-color: #000;
  font-size: 8px;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
`;

const ViewChecklist = (props) => {
  const { data, filledData, bookingNo,onClose } = props;
  const [value, setValue] = useState(filledData?.[0]?.data?.[0] || {});

  const getName = (channel) => {
    if (!channel) {
      return;
    }
     const trimmedChannel = channel.trim();
    const val = trimmedChannel.split(' ');

    if (val.length === 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
    } else {
      return `${val[0]?.charAt(0).toUpperCase()}`;
    }
  };

  return (
    <Container>
      <div  style={{ display: 'flex',alignItems:'center',justifyContent:'space-between' }}>
        <IconButton onClick={()=>onClose()}>
          <CancelOutlined />
        </IconButton>
        <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginRight:'30px'}}>
        <StyledAvatar sx={{ background:palette.blue,width:'25px',height:'25px',fontSize:'11px' }}>
          {getName(`${filledData?.[0]?.firstName} ${filledData?.[0]?.lastName}`)}
        </StyledAvatar>
        {filledData?.[0]?.firstName}  {filledData?.[0]?.lastName}
      </div>
  
      </div>
      <Title>{data?.title}</Title>
      <ScrollView>
        {data?.data?.map((item, index) => (
          <ItemContainer key={index}>
            {item?.type === 'Link' ? (
              <LinkText
                href={item?.value.startsWith('http') ? item.value : `https://${item.value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item?.value}
              </LinkText>
            ) : item?.type === 'Checkbox' ? (
              <CheckboxContainer

              >
                {value?.[item?.value] ? (
                  <CheckBox alt="Checked" />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
                <CheckboxText>{item?.value || '__'}</CheckboxText>
              </CheckboxContainer>
            ) : (
              <InputBox
                style={{ width: '94.5%' }}
                header={item?.value}
                readOnly
                placeholder={item?.value}
                value={value?.[item?.value]}

              />
            )}
          </ItemContainer>
        ))}
      </ScrollView>
    </Container>
  );
};

export default ViewChecklist;
