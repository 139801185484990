import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Circle, DrawingManager, GoogleMap, InfoWindow, MarkerF, Polygon } from "@react-google-maps/api";
import mapStyles from '../../Components/Tracking/mapStyles';
import { useState, useRef, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import RippleButton from '../../ReuseableComponets/RippleButton';
import { ArrowBackIos } from '@mui/icons-material';
import { palette } from '../../utils/Palette';
import FullScreenModal from '../../Components/FullScreenModal';
import AddContact from '../../Components/Settings/AddContact';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import { FiMapPin } from 'react-icons/fi';
import { FaDrawPolygon } from 'react-icons/fa';
import Header from '../../Components/Header/MainHeader';
import { useDispatch } from 'react-redux';
import { createTerritory, deleteTerritory, getContactMapData, getTerritory, updateTerritory } from '../../store/Contacts/Actions/ActionContacts';
import RightModal from '../../Components/RightSideModal';
import AssignTerritory from './Components/AssignTerritory';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { calendarFormat } from 'moment';
import { calendarTeamColor, getName } from '../../utils/Commonfunction';
import { Typography } from '@mui/material';

function MapContactView() {



    const [selectedProgress, setSelectedProgress] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const [opendAddContact, setOpenAddContact] = useState(false);
    const userDetails = useSelector(state => state?.auth?.userData)
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedMarker, setSelectedMarker] = useState({});
    const crewMembers = useSelector(state => state?.auth?.userData?.crewMembers)
    const [loader, setLoader] = useState(false);
    const [starDrawing, setDrawing] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const [selectedTerritory, setSelectedTeritory] = useState([]);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const dispatch = useDispatch()
    const [territory, setTerritory] = useState([
        { lat: 37.772, lng: -122.414 },
        { lat: 37.774, lng: -122.412 },
        { lat: 37.776, lng: -122.416 },
        { lat: 37.772, lng: -122.414 },
    ]);
    const [polygons, setPolygons] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(5);

    const mapContainerStyle = {
        width: "100%",
        height: "500px",
    };

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ["polygon"],
        },
        polygonOptions: {
            fillColor: "#0F52BA",
            fillOpacity: 0.3,
            strokeWeight: 2,
            strokeColor: "#0F52BA",
            clickable: true,
            editable: true, // Allow editing
            draggable: true, // Allow dragging
        },
    };

    const handlePolygonComplete = (polygon) => {
        const path = polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
        }));

        setPolygons((prevPolygons) => [...prevPolygons, {
            area: path
        }]);
        polygon.setMap(null);
        setDrawing(false)
    };

    const init = async () => {
        const res = await dispatch(getTerritory())
        const res1 = await dispatch(getContactMapData())
        let data = []
        if (res1.status === true) {
           
            res1?.data?.map((item) => {
                data?.push({
                    ...item,
                    id: markers.length + 1,
                    position: { lat: parseFloat(item?.latitude), lng: parseFloat(item?.longitude) },
                    icon: item?.status?.icon,
                    name: item?.status?.value,
                    ...item?.mainAddress

                })
            })
        }
        console.log(data, "srkcjndsjncs")
        setMarkers(data)
        console.log(res1?.data)
        setPolygons(res?.data)
    }
    useEffect(() => {
        setLoader(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },

                (error) => {
                    setCurrentLocation({
                        lat: 38.7946,
                        lng: -106.5348,
                    });
                }
            );
        }
        else {

            setCurrentLocation({
                lat: 38.7946,
                lng: -106.5348,
            });
        }
        init()
        setLoader(false)
    }, []);

    const polygonOptions = {
        fillColor: "#0F52BA",
        fillOpacity: 0.2,
        strokeColor: "#0F52BA",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: true,
        editable: true, // Allow territory editing
        draggable: true, // Enable dragging the shape
    };

    const handlePolygonEdit = React.useCallback((newPath) => {
        const updatedTerritory = newPath.map(({ lat, lng }) => ({ lat, lng }));
        setTerritory(updatedTerritory);
    }, []);

    const handleOnLoad = (map) => {
        mapRef.current = map;
        fitBoundsToMarkers(map);
        setIsMapLoaded(true)

        map.addListener("zoom_changed", () => handleZoomChanged(map));
    };
    React.useEffect(() => {

        if (isMapLoaded && markers.length > 0) {
            const clusterMarkers = markers.map(
                (marker) => {
                    const markerD = new window.google.maps.Marker({
                        position: marker.position,
                        icon: {
                            url: marker.icon,
                            scaledSize: new window.google.maps.Size(40, 40),
                        },
                        title: marker.name,
                    })
                    markerD.addListener("click", () => {
                        setSelectedMarkerId(marker);
                    });
                    return markerD
                }

            );

            new MarkerClusterer({
                map: mapRef.current,
                markers: clusterMarkers,
                options: {
                    imagePath:
                        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                    zIndex: 10000,
                    styles: [
                        {
                            url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                            width: 50,
                            height: 50,
                            textColor: '#fff',
                            backgroundColor: '#ff5733',
                            fontSize: '16px',
                            textSize: '14px',
                            animation: window.google.maps.Animation.BOUNCE,
                        },
                    ]
                },
            });
        }

    }, [markers, isMapLoaded, loader]);


    const fitBoundsToMarkers = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng));
        map.fitBounds(bounds);
        new window.google.maps.event.addListenerOnce(map, 'idle', function () {
            if (map.getZoom() > 19) map.setZoom(19);
        });
    };

    const reverseGeocode = async (lat, lng, markerId) => {
        const geocoder = new window.google.maps.Geocoder();
        const location = { lat, lng };
        geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[0]) {
                console.log(results[0], "cdcdsddc")

                const components = results[0].address_components;
                const addressDetails = {
                    street: getFullStreetAddress(components),
                    city: getAddressComponent(components, 'locality') || '',
                    state: getAddressComponent(components, 'administrative_area_level_1') || '',
                    postalCode: getAddressComponent(components, 'postal_code') || '',
                    country: getAddressComponent(components, 'country') || '',
                    address: results[0].formatted_address
                };

                updateMarkerAddress(markerId, addressDetails);
            }
        });
    };
    const getAddressComponent = (components, type) => {
        const component = components.find(comp => comp.types.includes(type));
        return component ? component.long_name : null;
    };

    const getFullStreetAddress = (components) => {
        const premise = getAddressComponent(components, 'premise');
        const sublocalityLevel3 = getAddressComponent(components, 'sublocality_level_3');
        const sublocalityLevel2 = getAddressComponent(components, 'sublocality_level_2');
        const route = getAddressComponent(components, 'route');
        let fullStreet = '';

        if (premise) {
            fullStreet += premise;
        }

        if (sublocalityLevel3) {
            fullStreet += fullStreet ? `, ${sublocalityLevel3}` : sublocalityLevel3;
        }

        if (sublocalityLevel2) {
            fullStreet += fullStreet ? `, ${sublocalityLevel2}` : sublocalityLevel2;
        }

        if (route) {
            fullStreet += fullStreet ? `, ${route}` : route;
        }

        return fullStreet;
    };

    const updateMarkerAddress = (markerId, address) => {
        setMarkers((prevMarkers) => prevMarkers.map(marker =>
            marker.id === markerId ? { ...marker, ...address } : marker
        ));
    };

    const handleMapClick = async (e) => {
        const clickedLocation = e.latLng;
        let matchedPolygon = null;

        polygons.forEach((polygonPath, index) => {
            const polygon = new window.google.maps.Polygon({ paths: polygonPath.area });
            if (window.google.maps.geometry.poly.containsLocation(clickedLocation, polygon)) {
                matchedPolygon = { ...polygonPath, index };
            }
        });
        if (selectedProgress) {
            const newMarker = {
                id: markers.length + 1,
                position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                icon: selectedProgress.icon,
                name: selectedProgress.value,
                color: selectedProgress.color,
                address: '',
                assignedUsers: matchedPolygon?.assignedUsers || []
            };
            setMarkers([...markers, newMarker]);
            console.log([...markers, newMarker], 'dsjdsjknjkc')
            await reverseGeocode(newMarker.position.lat, newMarker.position.lng, newMarker.id);
            setSelectedProgress(null);
            setAnchorEl(null);
        }
    };

    const handleIconSelect = (item) => {
        setSelectedProgress(item);
        setAnchorEl(null);
    };

    const handleMarkerClick = (markerId) => {
        if (selectedMarkerId === markerId) {
            setSelectedMarkerId(null);
        } else {
            setSelectedMarkerId(markerId);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const removeMarker = (markerId) => {
        console.log(markerId, markers)
        setIsMapLoaded(true)
        setMarkers((prevMarkers) => prevMarkers.filter(marker => marker.id !== markerId));
        setIsMapLoaded(false)
    };

    const submitAssignMember = async (value, id) => {
        const selectedData = []

        value?.map((item) => {
            selectedData.push(item?.email)
        })
        if (id) {
            const res = await dispatch(updateTerritory({
                "assignedUsers": selectedData,
                "area": selectedTerritory?.area,
                "color": value[0]?.color || selectedTerritory?.color,
                "background": value[0]?.background,
                territoryId: id
            }))

        }
        else {
            const res = await dispatch(createTerritory({
                "assignedUsers": [
                    value[0]?.email

                ],
                "area": selectedTerritory?.area,
                "color": value[0]?.color,
                "background": value[0]?.background,
            }))
        }
        init()
        setIsAssigned(false)
    }
    const handleZoomChanged = (map) => {
        setZoomLevel(map.getZoom());
    };
    return (
        <>
            <Body>
                {loader ? <StyledPlayer> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </StyledPlayer> : <Container>
                    <Header nonsearchable={true} />
                    <ProgressContainer>

                        <div style={{ display: 'flex', gap: 'px', alignItems: 'center' }}>
                            <StyledButton variant="contained" onClick={handleClick} style={{ background: selectedProgress?.value && '#ddd' }}>
                                {selectedProgress?.value ? (
                                    <LabelWrapper>
                                        <Avatar
                                            src={selectedProgress.icon}
                                            sx={{ marginRight: 1, width: '24px', height: '24px' }}
                                        />
                                        <Name style={{ textTransform: 'capitalize' }}>{selectedProgress.value}</Name>
                                    </LabelWrapper>
                                ) : (
                                    <>
                                        <FiMapPin style={{ marginRight: '10px' }} />
                                        <Name style={{ textTransform: 'capitalize' }}> {"Add Marker"}</Name>
                                    </>
                                )}
                            </StyledButton>
                            <StyledButton
                                variant="contained"
                                onClick={() => {
                                    setDrawing((prev) => !prev)
                                }}
                                style={{ display: 'flex', alignItems: 'center', background: starDrawing && '#ddd' }}
                            >
                                <FaDrawPolygon style={{ marginRight: '10px' }} />
                                <Name style={{ textTransform: 'capitalize' }}>{"Draw Territory"}</Name>
                            </StyledButton>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}

                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: 'auto',
                                    maxWidth: '450px'
                                },
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                {userDetails?.statusOptions?.map((item) => (
                                    <Grid item xs={4} key={item.value}>

                                        <div onClick={() => handleIconSelect(item)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer', alignSelf: 'center', alignItems: 'center' }}>
                                            <Avatar src={item.icon} sx={{ marginRight: 1, marginBottom: '5px' }} />
                                            <Name style={{ fontWeight: '500' }}>{item.value}</Name>
                                        </div>

                                    </Grid>
                                ))}
                            </Grid>
                        </Menu>
                    </ProgressContainer>

                    {currentLocation?.lat && <MapContainer>

                        <GoogleMap
                            onLoad={handleOnLoad}
                            onClick={handleMapClick}
                            clickableIcons
                            mapContainerStyle={{ width: "100%", height: "100%" }}
                            options={{ styles: mapStyles }}
                            zoom={zoomLevel}
                            center={currentLocation}

                        >
                            {currentLocation && (
                                <Circle
                                    center={currentLocation}
                                    radius={100}

                                    options={{
                                        strokeColor: "#0F52BA",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 1,
                                        fillColor: "#0F52BA",
                                        fillOpacity: 0.15,
                                        clickable: false
                                    }}
                                />
                            )}
                            {starDrawing && <DrawingManager
                                options={options}
                                onPolygonComplete={handlePolygonComplete}
                                drawingMode="polygon"
                            />}

                            {polygons.map((polygonPath, index) => (
                                <Polygon

                                    key={index}
                                    path={polygonPath?.area}
                                    options={{
                                        fillColor: `${polygonPath?.color || '#0F52BA'}`,
                                        fillOpacity: 0.3,
                                        strokeWeight: 2,
                                        strokeColor: `${polygonPath?.color || '#0F52BA'}`,
                                        editable: zoomLevel > 16 ? true : false,
                                        draggable: false,
                                        clickable: !selectedProgress?.value ? true : false,
                                        zIndex: 1,

                                    }}

                                    onClick={
                                        !selectedProgress?.value
                                            ? () => {
                                                setSelectedTeritory(polygonPath);
                                                setIsAssigned(true);
                                            }
                                            : null
                                    }
                                />
                            ))}

                            <MarkerF
                                position={currentLocation}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 8,
                                    fillColor: palette.blue,
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: "#ffffff",
                                }}
                            />
                            {selectedMarkerId && (
                                <InfoWindow position={{
                                    lat: selectedMarkerId.position.lat + 0.00015,
                                    lng: selectedMarkerId.position.lng,
                                }} onCloseClick={() => setSelectedMarkerId(null)}>
                                    <div style={{ padding: '15px 5px', paddingRight: '10px' }}>
                                        <HeaderMain>
                                            <h3 style={{ marginTop: '0px', marginBottom: '0px', fontWeight: '500' }}>Marker Detail</h3>
                                            {!selectedMarkerId?._id ? <RemoveButton onClick={() => removeMarker(selectedMarkerId.id)}>Remove Marker</RemoveButton> : selectedMarkerId?.assignedUser.firstName && <RemoveButton style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                                                <Avatar style={{ height: '22px', width: '22px', marginRight: '15px', background: calendarTeamColor[crewMembers?.findIndex(member => member.firstName === selectedMarkerId?.assignedUser.firstName)]?.light }}>
                                                    <Typography style={{ fontSize: '10px', fontWeight: '600', color: calendarTeamColor[crewMembers?.findIndex(member => member.firstName === selectedMarkerId?.assignedUser.firstName)]?.original }}>{getName(`${selectedMarkerId?.assignedUser?.firstName} ${selectedMarkerId?.assignedUser?.lastName || ''}`)}</Typography>
                                                </Avatar> {selectedMarkerId?.assignedUser?.fullName}</RemoveButton>}
                                        </HeaderMain>
                                        <Content>
                                            <div>
                                                <Label style={{ marginTop: '0px' }}>{selectedMarkerId.firstName} {selectedMarkerId.lastName}</Label>
                                                <Label style={{ marginTop: '0px' }}>{selectedMarkerId.address}</Label>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', fontWeight: '500' }}>
                                                <img
                                                    src={selectedMarkerId?.icon}
                                                    alt={selectedMarkerId?.icon}
                                                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                                />
                                                {selectedMarkerId.name}

                                            </div>
                                        </Content>
                                        <ButtonContainer>
                                            <RippleButton onClick={() => {
                                                if (selectedMarkerId?._id) {
                                                    navigate(`/dashboard/contacts/${selectedMarkerId?.contactId}`)
                                                }
                                                else {
                                                    setSelectedMarker(selectedMarkerId)
                                                    setOpenAddContact(true)
                                                }
                                            }} variant="contained">{selectedMarkerId?._id ? "View details" : "Add Contact"}</RippleButton>
                                        </ButtonContainer>
                                    </div>
                                </InfoWindow>
                            )}
                            {/* {markers.map(marker => (
                                <MarkerF
                                    key={marker.id}
                                    position={marker.position}
                                    icon={{
                                        url: marker.icon,
                                        scaledSize: new window.google.maps.Size(40, 40),
                                        fillColor: '#000000',
                                        fillOpacity: 1,
                                        strokeWeight: 2,
                                        strokeColor: '#000000',
                                        scale: 10
                                    }}

                                    onClick={() => handleMarkerClick(marker.id)}
                                >
                                    {selectedMarkerId === marker.id && (
                                        <InfoWindow position={marker.position} onCloseClick={() => setSelectedMarkerId(null)}>
                                            <div style={{ padding: '15px 5px', paddingRight: '10px' }}>
                                                <HeaderMain>
                                                    <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Marker Detail</h4>
                                                    <RemoveButton onClick={() => removeMarker(marker.id)}>Remove Marker</RemoveButton>
                                                </HeaderMain>
                                                <Content>
                                                    <strong>{marker.address}</strong>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', fontWeight: '500' }}>
                                                        <img
                                                            src={marker?.icon}
                                                            alt={marker?.icon}
                                                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                                        />
                                                        {marker.name}

                                                    </div>
                                                </Content>
                                                <ButtonContainer>
                                                    <RippleButton onClick={() => {
                                                        setSelectedMarker(marker)
                                                        setOpenAddContact(true)
                                                    }} variant="contained">Add Contact</RippleButton>
                                                </ButtonContainer>
                                            </div>
                                        </InfoWindow>
                                    )}
                                </MarkerF>
                            ))} */}
                        </GoogleMap>

                    </MapContainer>}
                </Container>}

            </Body>
            {opendAddContact && <FullScreenModal
                isOpen={opendAddContact}
                onClose={() => {
                    setOpenAddContact(false)
                }}
            >
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                    <AddContact
                        value={selectedMarker}
                        onCancel={(num) => {

                            setOpenAddContact(false)



                        }}
                        onSubmit={(num) => {

                            setOpenAddContact(false)
                            navigate(`/dashboard/contacts/${num?._id}`)

                        }}
                    />
                </div>
            </FullScreenModal>}
            {isAssigned &&
                <RightModal
                    isOpen={isAssigned}
                    onClose={() => {
                        setIsAssigned(false)
                    }}
                >
                    <AssignTerritory
                        value={selectedTerritory}
                        onClick={(value, id) => {
                            submitAssignMember(value, id)

                        }}
                        onDelete={async (id) => {
                            await dispatch(deleteTerritory(id))
                            init()
                            setIsAssigned(false)
                        }}
                        onClose={() => {
                            setIsAssigned(false)
                        }}
                    />
                </RightModal>
            }
        </>
    );
}

export default MapContactView;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ProgressContainer = styled.div`
    display: flex;
width:100%;
    gap: 20px;
    padding: 10px;
  justify-content:flex-end;
  

    overflow-y: auto;
`;

const MapContainer = styled.div`
    flex-grow: 1; /* Allow the map to grow and fill the remaining space */
`;

const Name = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin: 0;
`;
const HeaderMain = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #F44336;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
`;
const Content = styled.div`
    margin: 10px 0;
    font-size: 14px;
    color: #333;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px 18px; 
border-radius: 4px;
margin-right:12px;
cursor: pointer;
transition: background-color 0.3s ease, box-shadow 0.3s ease;
font-size: 13px;
color: rgba(32, 32, 32, 0.85);
font-weight:500;
background-color: transparent; 
margin-right:30px;
svg {
  font-size: 16px; 
  color: rgba(32, 32, 32, 0.85);
}
&:hover {
  background-color: #eeeeee;
  border-radius: 4px;
  color:#000;
  svg {
      color:#000;
  }
}



  
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const StyledPlayer = styled.div`
display:flex;
height:80vh; 
align-items:center; 
justify-content:center; 
`
const Label = styled.label`

    font-weight: 600;
    font-size: 13px;
    margin-left:4px;
    color: ${palette.charcoleBlack};
    display: flex;
    margin-bottom: 8px;
    margin-top:20px;
    align-self: flex-start;
    font-family:Inter;

  
`
