import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import { deleteLineItem, deletenewBundleItems, getLineItems } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import { sendMessage } from "../../../store/Actions/Auth.action";
import ProductImg from '../../../assets/product.png';
import RightModal from "../../RightSideModal";
import AddLineItems from "./AddLineItems";
import cross from '../../../assets/cross.png'
import Modal from "../../Modal";
import { palette } from "../../../utils/Palette";
import { Add, CallToActionOutlined, DatasetOutlined} from "@mui/icons-material";
import CustomTabs from "../../../ReuseableComponets/CustomTab";
import BundleItems from "./BundleItems";
import Header from "../../Header/MainHeader";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Box } from "@mui/system";
function Pricbook(props) {
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modaBundleOpen, setmodaBundleOpen] = useState(false)
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [activeValue, setActiveValue] = useState(0)
    const [invoice, setInvoice] = useState({})
    const [previewURL, setpreviewURL] = useState('')
    const [searchinvoice, setSearchInvoice] = useState([])
    const [bundelData, setBundleData] = useState([])
    const containerRef = useRef(null);


    const dispatch = useDispatch()
    const init = async () => {
        setLoader(true)
        let res = await dispatch(getLineItems())
        if (res.status === true) {
            setLoader(false)
            setSearchInvoice(res?.data?.lineItems)
            setBundleData(res?.data?.bundles)
        }
        setLoader(false)
    }

    useEffect(() => {
        init()
    }, [])


    const deleteLine = async (id) => {
        await dispatch(deleteLineItem({
            "itemIds": [
                id
            ]
        }))
        init()
    }

    const renderlineItem = (dataitem, type) => {
        return (
            <div ref={containerRef} style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                <Table sx={{ width: '100%' }} >
                    <CustomHead>
                        <TableRow >

                            <TableCell size='small'>

                                Product

                            </TableCell>

                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                Description

                            </TableCell>

                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Quantity</TableCell>

                            {/* <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">Unit cost</TableCell> */}
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>{type === 'Bundle' ? 'Sub total' : "Unit price"}</TableCell>

                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                Total
                            </TableCell>
                            <TableCell style={{ width: '80px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                Actions
                            </TableCell>
                        </TableRow>
                    </CustomHead>
                    {dataitem?.map((item, index) => <>
                        <TableBody key={index} onClick={() => {
                            setInvoice({ ...item, edit: true })
                            if (item?.type == 'Bundle') {
                                setmodaBundleOpen(true)
                            }
                            else {
                                setModalOpen(true)
                            }

                        }} style={{ background: '#fff', cursor: 'pointer' }} >
                            <TableRow >
                                <TableCell size='small'>
                                    <div

                                        style={{ fontWeight: '700', height: '20px', display: 'flex', maxWidth: '250px', alignItems: 'center', cursor: 'pointer', }}>
                                        <FileImage
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (item?.picUrl) {
                                                    setpreviewURL(item?.picUrl)
                                                    setImageModalOpen(true)
                                                    //window.open(item?.picUrl)
                                                }
                                            }}

                                        ><ProductFileImg src={item?.picUrl || ProductImg} /></FileImage> <div style={{
                                            width: '100%',
                                            fontSize: '13px',
                                            textAlign: 'start',

                                            flexWrap: 'wrap',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>{item?.name}</div>
                                    </div>
                                </TableCell>

                                <TableCell style={{
                                    maxWidth: '120px',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    lineHeight: '16px',
                                    flexWrap: 'wrap',
                                    wordBreak: 'break-all',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }} size='small' >
                                    {item?.description}
                                    {/* {item?.name || item?.customerPhoneNumber} */}


                                </TableCell>

                                <TableCell size='small'>
                                    {item?.quantity}

                                </TableCell>

                                {/* <TableCell
                        size='small'>
                        {item?.unitPrice &&`$${parseFloat(item?.unitPrice)?.toFixed(2)}`}
                    </TableCell> */}

                                <TableCell
                                    size='small'>
                                    {item?.price && `$${putCommaInvalue(parseFloat(item?.price)?.toFixed(2))}`}

                                </TableCell>

                                <TableCell size='small'>

                                    {
                                        item?.type === 'Bundle' ? `$${putCommaInvalue(([...item?.lineItems].reduce(function (accumulator, currentValue) {
                                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                                        }, 0))?.toFixed(2))}` :
                                            `$${putCommaInvalue(totalprice(item?.price, item?.quantity || 1, item?.tax || 0)?.toFixed(2))}`}
                                </TableCell>
                                <TableCell size='small'>
                                    <ActionIcons>
                                        <FiEdit onClick={() => {
                                            setInvoice({ ...item, edit: true })
                                            if (item?.type == 'Bundle') {
                                                setmodaBundleOpen(true)
                                            }
                                            else {
                                                setModalOpen(true)
                                            }

                                        }} />

                                        <FiTrash2 onClick={async (e) => {
                                            e.stopPropagation()
                                            if (item?.type === 'Bundle') {
                                                await dispatch(deletenewBundleItems(item?._id))
                                                init()
                                            }
                                            else {
                                                deleteLine(item?._id)
                                            }
                                        }} />


                                    </ActionIcons >

                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </>)}
                </Table>
                {dataitem?.length > 0 ? null : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="60vh"
                        sx={{ color: 'gray' }}
                    >
                        {activeValue===0?<DatasetOutlined sx={{ fontSize: '50px', marginBottom: 2 }} />:<CallToActionOutlined sx={{ fontSize: '50px', marginBottom: 2 }} />}
                        <Typography variant="h5" align="center">
                            No product available
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            You can add new product to manage your data more effectively.
                        </Typography>
                    </Box>
                )}

            </div>
        )
    }

    return (
        <>
            <Body>
                <Flexbox>
                    <Header
                        searchComponent={<input type="text" placeholder="Search..." />}
                        handleShowJobs={() => console.log('Show jobs')}
                    // value={search}
                    // onChangeSearch={(text) => searchFilterFunction(text)}
                    />
                    <StyledContactFlex>
                        <div>
                            <h2>Pricebook </h2>
                            {activeValue===0?<span><DatasetOutlined style={{ marginRight: '5px', fontSize: '20px' }} /> {searchinvoice?.length || 0}</span>:<span><CallToActionOutlined style={{ marginRight: '5px', fontSize: '20px' }} /> {bundelData?.length || 0}</span>}
                        </div>
                        <OptionContainer>
                            <Tooltip arrow title='Add product' placement="top">
                                <StyledTextBox style={{
                                    marginRight: '12px'
                                }} onClick={() => {
                                    setInvoice({})
                                    setModalOpen(true)
                                }} >
                                    <Add style={{ fontSize: '20px', marginRight: '5px' }} /> New product
                                </StyledTextBox>
                            </Tooltip>
                            <div style={{ borderLeft: '1px solid #ddd', paddingLeft: '12px' }}>
                                <Tooltip arrow title='Add bundle' placement="top">
                                    <StyledTextBox style={{

                                    }} onClick={() => {
                                        setInvoice({})
                                        setmodaBundleOpen(true)
                                    }} >
                                        <Add style={{ fontSize: '20px', marginRight: '5px' }} /> New bundle
                                    </StyledTextBox>
                                </Tooltip>
                            </div>
                        </OptionContainer>
                    </StyledContactFlex>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px 0px', paddingRight: '0px', marginRight: '0px', borderTop: '1px solid #eee' }}>
                        <CustomTable>

                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px', marginBottom: '0px' }}>
                        <h1 style={{ textAlign: 'start' }}>Pricebook</h1>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <OptionBox style={{ marginRight: '10px' }} onClick={() => {
                                setInvoice({})
                                setmodaBundleOpen(true)
                            }}>

                                Add new bundle
                            </OptionBox>
                            <OptionBox onClick={() => {
                                setInvoice({})
                                setModalOpen(true)
                            }}>

                                Add new product
                            </OptionBox>
                        </div>
                    </div> */}
                            {loader && searchinvoice?.length <= 0 ? <div style={{ display: 'flex', height: '70%', alignItems: 'center', justifyContent: 'center' }}> <Player
                                src={require('../../../assets/loaders.json')}
                                className="player"
                                style={{ width: '200px', height: '200px' }}
                                loop
                                autoplay
                            />
                            </div>

                                :
                                <>
                                    <div >
                                        <CustomTabs
                                            tabpanelWidth={"400px"}
                                            activeValue={(val)=>setActiveValue(val)}
                                            boxStyle={{
                                                p: 0, marginTop: '10px', marginBottom: '25px'
                                            }}
                                            tabsData={[
                                                {
                                                    label: 'Price book',
                                                    icon: <DatasetOutlined style={{ fontSize: '16px' }} />,
                                                    content: renderlineItem(searchinvoice)
                                                },
                                                {
                                                    label: 'Bundle items',
                                                    icon: <CallToActionOutlined style={{ fontSize: '16px' }} />,
                                                    content: renderlineItem(bundelData, "Bundle")
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                            }

                        </CustomTable>
                    </div>
                    {modalOpen && (
                        <RightModal
                            isOpen={modalOpen}
                            onClose={() => setModalOpen(false)}>
                            <AddLineItems
                                {...props}
                                value={{ ...invoice }}
                                setModalOpenInvoice={() => setModalOpen(false)}
                                onSubmitsw={(val, data) => {
                                    init()
                                    setModalOpen(false)
                                }
                                }
                            />

                        </RightModal>
                    )}
                    {modaBundleOpen && (
                        <RightModal
                            isOpen={modaBundleOpen}
                            customStyle={{
                                width: '45%', maxHeight: '100%', height: '100%', flexWrap: 'wrap', position: 'relative', overflow: 'hidden', paddingLeft: '0px',
                                paddingRight: '0px',
                            }}
                            overlayStyle={{ paddingTop: '0px', alignItems: 'center' }}
                            onClose={() => setmodaBundleOpen(false)}>
                            <BundleItems
                                {...props}
                                value={{ ...invoice }}
                                lineItem={searchinvoice}
                                setModalOpenInvoice={() => setmodaBundleOpen(false)}
                                onSubmitsw={(val, data) => {
                                    init()
                                    setmodaBundleOpen(false)


                                }
                                }
                            />
                        </RightModal>
                    )}
                    {imagemodalOpen && (
                        <Modal
                            isOpen={imagemodalOpen}
                            onClose={() => setImageModalOpen(false)}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <CloseButton onClick={() => setImageModalOpen(false)}>
                                    <Icons src={cross} />
                                </CloseButton>

                            </div>

                            <Image onClick={() => {
                                window.open(previewURL)
                            }} src={previewURL} />


                        </Modal>
                    )}



                </Flexbox>
            </Body>
        </>
    )
}

export default Pricbook;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const Image = styled.img`
width:350px;
height:350px;
margin-top:20px;
margin-bottom:20px;

`
const FileImage = styled.label`
  display:flex;
align-items:center;
justify-content:center;
  background: #f3f3f3;
  font-size:14px;
  border-radius: 4px;
  border:1px solid  #f3f3f3;
  width:20px;
  height:20px;
  cursor: pointer;
  margin-right:15px;
`;

const ProductFileImg = styled.img`
width:20px;
height:20px;
`
const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
const StyledContactFlex = styled.div`
 display:flex; 
 justify-content:space-between; 
 align-items:center; 
 margin-left:20px; 
 margin-right:0px;
 margin-top:10px;
 margin-bottom:10px; 

 h2{
  text-align:center; 
  margin-top:0px; 
  margin-bottom:3px; 
  line-spacing:0.5px;
 }
 span{
   color:grey;
   font-size:15px;
   text-align:start;
   display:flex;
   align-items:flex-end;
   margin-bottom:5px; 
   font-weight:400;
   
 }

`
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  
  margin-top:0px;
`;

const StyledTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px; 
  border-radius: 4px;
  margin-right:12px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 13px;
  color: rgba(32, 32, 32, 0.85);
  font-weight:500;
  background-color: transparent; 
  svg {
    font-size: 16px; 
    color: rgba(32, 32, 32, 0.85);
  }
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
    color:#000;
    svg {
        color:#000;
    }
  }


`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none;
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:400;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;


const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
overflow-y:hidden;
display:flex;
flex-direction:column;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}`;

const Icons = styled.img`
width: 20px;
height: 20px;
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`