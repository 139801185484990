import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { deleteAddEvent, deleteAddTask, DoneAddTask, fetchTeamData, getBookings, getCalendarData, getCalendarDataV2 } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import styled from 'styled-components'


import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState } from 'react';
import { calendarTeamColor, colorArray, dayViewColor, formatMobileNumber } from '../../../utils/Commonfunction';
import { MenuItem, Select, Tooltip, Box, Divider, Avatar } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth } from '@mui/icons-material';
import { FiCalendar, FiCheckCircle, FiRefreshCcw } from 'react-icons/fi';
import EventDetails from './Component/EventCard';

const localizer = momentLocalizer(moment);

const CustomTimeGrid = ({ events, localizer, date }) => {
    const times = [];
    let time = moment().startOf('day');
    while (time.isBefore(moment().endOf('day'))) {
        times.push(time.clone());
        time.add(1, 'hour');
    }

    return (
        <CustomTimeGridContainer>
            <div className="times">
                {times.map((timeSlot, idx) => (
                    <div key={idx} className="time-slot">
                        {localizer.format(timeSlot.toDate(), 'hh:mm A')}
                    </div>
                ))}
            </div>
            <div className="events">
                {events.map((event, idx) => {
                    const start = moment(event.start);
                    const end = moment(event.end);
                    const duration = moment.duration(end.diff(start)).asHours();
                    const startOffset = moment.duration(start.diff(moment().startOf('day'))).asHours();

                    return (
                        <div
                            key={idx}
                            className="event"
                            style={{
                                left: `${startOffset * 100}px`,
                                width: `${duration * 100}px`
                            }}
                        >
                            {event.title}
                        </div>
                    );
                })}
            </div>
        </CustomTimeGridContainer>
    );
};


function MyTIme() {
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const localizer = momentLocalizer(moment)
    const [event, setEventsss] = React.useState([])
    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Day")
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipId, setTooltipId] = useState('');
    const [value, setValue] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [events, setEvents] = useState([]);
    const [boolValue, setBoolValue] = useState(false);
    const [resources, setResources] = useState([]);
    const [opentooltip, setOpenTooltip] = React.useState(false);
    const [openIndex, setOpenIndex] = React.useState('');
    const handleTooltipClose = () => {
        setOpenTooltip(false);
        setOpenIndex(null)
    };

    const handleTooltipOpen = (index) => {
        setOpenIndex(index)
        setOpenTooltip(true);

    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;


    const getStartAndEndOfWeek = (date) => {
        const startOfWeek = moment(date).startOf('week').subtract(1, 'days').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('week').add(1, 'days').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };
    const navigate = useNavigate();
    const fetchTeam = async (val) => {
        setSelected(val)
        if (val?.email === 'AllData') {
            setEvents(AllData)

            return
        }
        setEvents([])
        const res = await dispatch(fetchTeamData({
            "emails": [val?.email]
        }))
        let arr = []
        res.data?.[0]?.bookings.map((item) => {
            arr.push({
                data: item,
                title: item?.name || item?.to,
                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                color: '#daf0ff',
                description: item?.customerAddress,

            })
        })
        setEvents(arr)
    }
    const startDate = (date) => {
        const start = moment(date).format('YYYY-MM-DD');
        const end = moment(date).add(2, 'day').format('YYYY-MM-DD'); 

        return { start, end }; 
    };
    const init = async () => {
        const { start, end } = startDate(new Date());
        const res1 = await dispatch(getCalendarDataV2(start,end))
        let eventArr = [];
        let teamArr = [];
        const dataarr = [...res1?.data?.detailData]
        dataarr?.forEach((item,index) => {
                const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');
        
            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');
        
            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');
        
            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');
                let startdat = new Date(
                    moment(newDate, 'DD-MMM-YYYY').year(),
                    moment(newDate, 'DD-MMM-YYYY').month(),
                    moment(newDate, 'DD-MMM-YYYY').date(),
                    moment(newTime, 'HH:mm').hours(),
                    moment(newTime, 'HH:mm').minutes()
                );            

            if (!isNaN(startdat)) {
                eventArr.push({
                    data: item,
                    title: item?.name || item?.to||item?.title,
                    start: startdat,
                    end: item?.endDate && item?.endTime
                    ? new Date(
                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                        moment(newEndTime, 'HH:mm').hours(),
                        moment(newEndTime, 'HH:mm').minutes()
                    )
                    : new Date(
                        moment(newDate, 'DD-MMM-YYYY').year(),
                        moment(newDate, 'DD-MMM-YYYY').month(),
                        moment(newDate, 'DD-MMM-YYYY').date(),
                        moment(newTime, 'HH:mm').hours() + 1, // Add 1 hour if no end time
                        moment(newTime, 'HH:mm').minutes()
                    ),
                    color: '#daf0ff',
                    description: item?.customerAddress||item?.task||item?.description,
                    status: item?.bookingStatus,
                    type:item?.type,
                    index:index
                });
            }
        });

        res1?.data?.team?.forEach((item, index) => {
            teamArr.push({ id: item.firstName, group: item.firstName, color: calendarTeamColor[index]?.light,border:calendarTeamColor[index]?.original });
        });

        // Map team first names to events
        const updatedEvents = eventArr.map((event, index) => {
            const teamMember = teamArr.find(team => team.group === event.data.team?.[0]?.firstName);
    
            return {
                ...event,
                group: teamMember ? teamMember.group : "Unassigned jobs",
                resourceId: teamMember ? teamMember.group : "Unassigned jobs",
                color: teamMember?.color,
                border:teamMember?.border
            };
        });
        console.log(updatedEvents, "eventsss")

        setEventsss(updatedEvents);
        setResources([{ id: "Unassigned jobs", group: "Unassigned jobs",color: "#D6E3F6",border:palette.blue },...teamArr]);
    };
    React.useEffect(() => {
        init()
    }, [])
    const handleSelectEvent = (event) => {
        // setValue({})
        // // setIsModal(true)
        // // setSelectedEvent(event?.data);
        // navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };

    const MyEvent = ({ event }) => {

        return (
            <TooltipStyled
            onClose={handleTooltipClose}

            open={(opentooltip && (event?.index) === openIndex)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive
            disablePortal
            placement={'right-start'}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#fff',
                        color: '#000',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                        border: '1px solid #ddd',
                        fontSize: '14px',
                        maxHeight: '550px',

                        paddingBottom: (event?.data?.type == 'task' || event?.data?.type == 'event') ? "0px" : '40px',
                        width: '350px',

                        overflowY: 'scroll',
                    },
                },
                arrow: {
                    sx: {
                        color: '#fff',
                    },
                },
            }}
                title={
                    <EventDetails
                    event={event}
                    onClickDeleteTask={async (date, id, type) => {
                        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);
                        if (opentooltip) {
                            handleTooltipClose()
                        }
                        if (type === 'task') {
                          
                            await dispatch(deleteAddTask(id));
                            initRender(startOfWeek, endOfWeek);
                        }
                        else {
                            await dispatch(deleteAddEvent(id));
                            initRender(startOfWeek, endOfWeek);
                        }
                    }}
                    onClickEdit={async (date, data, type) => {
                        if (opentooltip) {
                            handleTooltipClose()
                        }
                        setBoolValue({ ...boolValue, isTask: true, type: type === 'task' ? 'Add Task' : 'Add Event' })
                        setSelectedSlot(data)
                    }}
                    onClickTask={async (date, id) => {
                        if (opentooltip) {
                            handleTooltipClose()
                        }
                        try {
                            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);
                            await dispatch(DoneAddTask(id));
                            initRender(startOfWeek, endOfWeek);
                        } catch (error) {
                            console.error('Error in onClickTask:', error);
                        }
                    }}

                />

                }
            >
               <EventDiv style={{ display: 'flex',marginTop:moment(event?.start).format('DD MM')!==moment(event?.end).format('DD MM')&&'-5px',paddingBottom:moment(event?.start).format('DD MM')!==moment(event?.end).format('DD MM')&&'5px' }} onClick={() => handleTooltipOpen(event?.index)} status={event?.status}>
                    {event?.status === 'Completed' ? (
                        <div>
                            <FiCheckCircle style={{ color: 'green', marginRight: '4px' }} />
                        </div>
                    ) : event?.type === 'multivist' ? (
                        <div>
                            <FiRefreshCcw style={{ color: 'black', marginRight: '4px' }} />
                        </div>
                    ) : null}
                    <div style={{ width: '90%' }}>
                        {event?.title}&nbsp;
                        <Description>{event.description}</Description>
                        {
                            <TimeRange>{`${moment(event?.start).format('hh:mm A')} - ${moment(event?.end).format('hh:mm A')}`}</TimeRange>}
                        <div style={{ display: 'flex' }}>
                            {
                                event?.data?.team?.map((item) =>
                                    <Avatar style={{ width: '20px', height: '20px', fontSize: '10px', marginRight: '5px', background: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.light, color: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.original }}>{getName(`${item?.firstName} ${item?.lastName}`)}</Avatar>
                                )
                            }
                            {
                                event?.data?.worker?.map((item) =>
                                    <Avatar style={{ width: '20px', height: '20px', fontSize: '10px', marginRight: '5px', background: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.light, color: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.original }}>{getName(`${item?.firstName} ${item?.lastName}`)}</Avatar>
                                )
                            }
                        </div>
                    </div>
                </EventDiv>
            </TooltipStyled>

        );
    };


    const eventStyleGetter = (event, start, color, end, isSelected) => {
        const style = {
            backgroundColor: "#fff",
            borderRadius: '5px',
            padding: '1px',
            opacity: 0.8,
            color: 'black',
            fontSize: '12px',
            marginLeft: '3px',
            opacity: 1.0,
            display: 'block',
            border: '1.5px solid #ddd',
            boxShadow: '0 0 10px rgba(255, 255, 255, 0.6)',
            borderLeft: event?.border ? `8px solid ${event?.border }`:`8px solid ${palette.newBlue}`

        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {

        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            navigate('/dashboard/calander?type=week')
        };

        const goToDay = () => {
            navigate('/dashboard/calander?type=day')
        };
        const goToMonth = () => {
            navigate('/dashboard/calander?type=month')
        };



        return (
            <div style={{ display: 'flex', width: '100%',marginTop:'-3px',marginBottom:'-3px', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="rbc-toolbar">

                    <span style={{ display: 'flex', marginLeft: '20px' }} className="rbc-btn-group">
                        <button style={{ marginRight: '20px', borderRadius: '4px', border: '1.2px solid gray', fontSize: '14px', fontWeight: '500' }} type="button" onClick={goToToday}>
                            Today
                        </button>
                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('PREV')}>
                            <ArrowBackIosNew style={{ fontSize: '18px' }} />
                        </button>
                        {/* <span style={{ alignSelf: 'center', fontSize: '14px',fontWeight:'500' }} className="rbc-toolbar-label">{toolbar.label}</span> */}
                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                            <ArrowForwardIos style={{ fontSize: '18px' }} />
                        </button>
                        <span style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '500' }} className="rbc-toolbar-label">
                        <FiCalendar style={{width:'16px',height:'16px'}} />
                            <div style={{ marginLeft: '8px',fontWeight:'400' }}>{toolbar.label}</div>
                        </span>

                    </span>

                    <span className="rbc-toolbar-label"></span>
                    {/* <span style={{ marginRight: '20px' }} className="rbc-btn-group">
                    <Selectbox
                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                        placeholder='select' value={'Select an option'} displayEmpty
                        renderValue={() => filter} onChange={(e) => {
                            console.log(e.target)

                        }}
                        MenuProps={{ // Add MenuProps to customize menu styling
                            PaperProps: {
                                style: {
                                    marginTop: '8px', // Adjust this value for the desired gap
                                },
                            },
                        }}
                    >
                        <StyledMenuItem type="button" onClick={() => {
                            setFilter('Day')

                        }}>
                            Day
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToWeek}>
                            Week
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToMonth}>
                            Month
                        </StyledMenuItem>

                        {/* <StyledMenuItem type="button" onClick={goToAgendaView}>
                        Agenda
                    </StyledMenuItem> */}

                </div>
                <div style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <ToggleTabs
                        labels={['Week', 'Month', "Day", "Map"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                            }
                            else {
                                setFilter('Map')
                                navigate('/dashboard/calander/map')

                            }
                        }}

                    />
                </div>
            </div>
        );
    };
    const dayHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');


        return (
            <div style={{ height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '600' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '600', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const MyTimeSlotWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', height: '17px', fontWeight: '400', marginTop: 5, borderTop: 'none', color: 'grey', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayColumnWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', marginTop: 5, flexDirection: 'row', display: 'flex', borderTop: 'none', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayFormat = 'dddd, MMMM D, YYYY';
    const monthFormat = 'MMMM YYYY';


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {
                backgroundColor: isToday && 'transparent'// Set the background color to transparent for today's date

            },
        };
    }
    const MyEventWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                background: 'none',
                borderRadius: 4,
            }}
        >
            {children}
        </div>
    );

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const handleSelectSlot = (e) => {
        if (opentooltip) {
            return handleTooltipClose()
        }

        setValue({
            date: e?.start?.toISOString(),
            endDate: e?.end?.toISOString(),
            time: e?.start?.toISOString(),
            endTime: e?.end?.toISOString(),
            edit: true

        })
        setIsModal(true)

        // setIsModal(true)
        // `start` and `end` contain the selected range of dates

        // You can perform any actions with the selected dates here
    };
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);

    const timeGutterWraper = ({ event }) => {
        console.log(event, "dccjcdn")
        return (
            <div style={{ height: 'auto', width: '125%', fontSize: '13px', marginTop: '29px', paddingTop: '5px', borderTop: '1px solid #ddd', marginLeft: '-10px', marginRight: '-20px', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px' }}>
                All day
            </div>
        )
    }
    const initRender = async (start,end) => {
        const res1 = await dispatch(getCalendarDataV2(start,end))
        let eventArr = [];
        let teamArr = [];
        const dataarr = [...res1?.data?.detailData]
        dataarr?.forEach((item,index) => {
                const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');
        
            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');
        
            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');
        
            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');
                let startdat = new Date(
                    moment(newDate, 'DD-MMM-YYYY').year(),
                    moment(newDate, 'DD-MMM-YYYY').month(),
                    moment(newDate, 'DD-MMM-YYYY').date(),
                    moment(newTime, 'HH:mm').hours(),
                    moment(newTime, 'HH:mm').minutes()
                );            

            if (!isNaN(startdat)) {
                eventArr.push({
                    data: item,
                    title: item?.name || item?.to||item?.title,
                    start: startdat,
                    end: item?.endDate && item?.endTime
                    ? new Date(
                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                        moment(newEndTime, 'HH:mm').hours(),
                        moment(newEndTime, 'HH:mm').minutes()
                    )
                    : new Date(
                        moment(newDate, 'DD-MMM-YYYY').year(),
                        moment(newDate, 'DD-MMM-YYYY').month(),
                        moment(newDate, 'DD-MMM-YYYY').date(),
                        moment(newTime, 'HH:mm').hours() + 1, // Add 1 hour if no end time
                        moment(newTime, 'HH:mm').minutes()
                    ),
                    color: '#daf0ff',
                    description: item?.customerAddress||item?.task||item?.description,
                    status: item?.bookingStatus,
                    type:item?.type,
                    index:index
                });
            }
        });

        res1?.data?.team?.forEach((item, index) => {
            teamArr.push({ id: item.firstName, group: item.firstName, color: calendarTeamColor[index]?.light,border:calendarTeamColor[index]?.original });
        });

        // Map team first names to events
        const updatedEvents = eventArr.map((event, index) => {
            const teamMember = teamArr.find(team => team.group === event.data.team?.[0]?.firstName);
    
            return {
                ...event,
                group: teamMember ? teamMember.group : "Unassigned jobs",
                resourceId: teamMember ? teamMember.group : "Unassigned jobs",
                color: teamMember?.color,
                border:teamMember?.border
            };
        });
        console.log(updatedEvents, "eventsss")

        setEventsss(updatedEvents);
        setResources([{ id: "Unassigned jobs", group: "Unassigned jobs", color: "#D6E3F6",border:palette.blue },...teamArr]);
    };

    const handleNavigate = (date, view) => {
        const { start, end } = startDate(date);
        initRender(start, end);
       
    };

    return (
        <Body>
            <div style={{ display: 'flex', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={event}
                    resources={resources}
                    resourceIdAccessor="id"
                    resourceTitleAccessor="group"
                        onNavigate={handleNavigate}
                    defaultDate={new Date()}
                    style={{ width: '100%' }}
                    views={{ week: true, day: true }}
                    step={15} // This controls the slot duration
                        timeslots={2}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    dayPropGetter={dayPropStyles}
                    dayLayoutAlgorithm={'no-overlap'}
                    selectable
                    min={new Date(1970, 1, 1, 6, 0)}
                    defaultView={'day'}
                    eventPropGetter={eventStyleGetter}
                    formats={{

                        eventTimeRangeFormat: () => {
                            return ""
                        }





                    }}
                    components={{
                        toolbar: MyToolbar,
                        event: MyEvent,


                        day: {
                            header: dayssHeaderContent,

                        },
                       
                        timeSlotWrapper: MyTimeSlotWrapper,
                        eventContainerWrapper: MyEventWrapper,

                        timeGutterHeader: () => <div style={{ textAlign: 'center' }}></div>,
                        resourceHeader: (event) => <div style={{ textAlign: 'center', padding: '15px',fontWeight:'500',color:'rgba(32, 32, 32, 0.85)' }}>
                            <OutProfile style={{ background: event?.resource?.color,color:event?.resource?.border,fontWeight:'bolder', display: 'flex' }}>{getName(`${event?.resource?.group || ''}`)}</OutProfile>
                            {event?.resource?.group}</div>,

                    }}
                />




                {
                    ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                        <CreateBooking
                            value={value?.edit ? value : selectedEvent}
                            team={team}
                            refresh={() => init()}
                            serviceRequest={value?.edit ? false : true}
                            newService={true}
                            setCreateBookingModal={() => {

                                setIsModal(false)
                            }}
                            onSubmitsw={(val, editValue) => {

                                // sendchat(val, editValue)
                                init()
                                setIsModal(false)



                            }
                            }

                        />
                    </FullScreenModal>
                }
            </div>
        </Body>
    );
}
export default MyTIme;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:20px;
width:25px;
display:flex;
justify-content:center;
align-items:center;
font-size:12px;
padding:5px;
margin-right:12px;
border-radius:25px;

`
const EventBox = styled(Box)`
  padding: 4px;

`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
`;
const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;
const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  
`;
const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  
  padding-top: 8px;
  font-weight:500;
  color:${palette.newBlue};
  font-size: 13px;
  font-weight:500;
  padding-left:5px;
  overflow: hidden;
  width:100%;
  white-space: nowrap; 
  overflow: hidden;  
  text-overflow: ellipsis;

`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;

  font-size: 12px;
  line-height: 14px;
  color:${palette.grey};
  font-weight:400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 11px;
  line-height: 14px;
  font-weight:400;
  color:${palette.grey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Icon = styled.img`
height:13px;

margin-right:10px;
padding-left:8px;
color:black
`

const CalanderTime = styled(Calendar)`

.rbc-time-view .rbc-row{
 
    max-height: calc(100vh - 200px); 
    border-color:#eee;
}
.rbc-time-slot {
  
    border: 0px solid #eee; 
  }

  
  
.rbc-day-slot {
    position:none;
}
.rbc-timeslot-group {
    max-height:200px;
    border-color:#eee;
  }
  .rbc-time-view .rbc-allday-cell{
    border-color:#eee;
    
  }
  .rbc-selected-cell {
    background-color: rgba(0, 123, 255, 0.4) !important; 
  }
  
  .rbc-active-range {
    background-color: rgba(255, 123, 0, 0.4) !important; 
  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    height: auto !important;
    display:flex;
    border-color:#eee;
    align-items:center;

}

 
  .rbc-events-container{
     overflow: visible;
  }
  .rbc-month-row {
    min-height: 120px;
  }

  .rbc-addons-dnd-resize-handle {
    background-color: red;
    height: 8px;
    cursor: row-resize;
}

.rbc-calendar {
    background-color: #f3f3f3;
    border-color:#eee;
}
.rbc-time-header {
    border-color:#eee;
}
.rbc-day-bg {
    border-color:#eee;
}
.rbc-time-header.rbc-overflowing {
    border-color:#eee;
}
.rbc-time-header-content{
    border-color:#eee;
}

.rbc-time-view .rbc-time-header.rbc-overflowing {
    border-color: #eee;
}

.rbc-label.rbc-time-header-gutter{
    border-color:#eee; 
}
.rbc-timeslot-group:nth-child(odd) {
    border-bottom: 1px dotted #eee;
  }

  
.rbc-header {
    text-align: center;
    vertical-align: middle;
    horizontal-align:middle;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    border-color:#eee;
}
  


`

const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const Selectbox = styled(Select)`
padding-right:10px;
border-radius:8px;
text-align:start;
margin-left:10px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`



const CustomTimeGridContainer = styled.div`
  .times {
    display: flex;
    justify-content: space-between;
  }
  .time-slot {
    padding: 5px;
    border: 1px solid #ddd;
    width: 100px;
    text-align: center;
  }
  .events {
    position: relative;
  }
  .event {
    position: absolute;
    top: 40px; /* Adjust this as needed */
    background-color: lightblue;
    border: 1px solid #ddd;
    padding: 5px;
  }
`;