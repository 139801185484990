import { CancelOutlined, Person2Outlined } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { color } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { calendarTeamColor, dayViewColor, getName } from "../../../utils/Commonfunction";

const Container = styled.div`
  max-width: 100%;
  margin-top:10px;

  @media (max-width: 500px) {
    max-width: 90%;
  }
`;

const Title = styled.h3`
  margin:0;
  color: #333;
  text-align: start;
  margin-left:10px;
`;

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownInput = styled.input`
  width: calc(100% - 24px);
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  outline: none;

  &:focus {
    border-color: #0f52ba;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  z-index: 1000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.li`
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align:start;
  display:flex;
  align-items:center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f8ff;
  }
`;

const AssignButton = styled.button`
  width: 170px;
  padding: 9px;
  font-size: 14px;
  margin-left:20px;
  height:40px;
  color: #fff;
  background-color: #0f52ba;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: #094a97;
  }

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;

const UserList = styled.div`
  margin-top: 20px;
`;

const UserHeader = styled.h3`
  margin-bottom: 10px;
  color: #333;
  text-align:start;
  padding-bottom:10px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
`;

const UserName = styled.h3`
  font-size: 13px;
  color: #333;
  margin:0px;
  font-weight:500;
  text-align:start;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding:10px 0px;
    justify-content: space-between;
   
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #0F52BA;
`;



const DeleteButton = styled.button`
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: red;
`;

const AssignTerritory = (props) => {
    const [search, setSearch] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [assignedUsers, setAssignedUsers] = useState(props?.value?.assignedUsers||[]);
    const crewMembers = useSelector(state => state?.auth?.userData?.crewMembers)
    const [filteredUsers, setFilteredUsers] = useState(crewMembers);


    const users = [
        "Lucas Admin",
        "Jim Halpert",
        "Pam Beesly",
        "Dwight Schrute",
        "Michael Scott",
    ];

    useEffect(()=>{
        setFilteredUsers(crewMembers)
    },[crewMembers])

    const handleInputFocus = () => {
    
        setFilteredUsers(crewMembers);
        setDropdownOpen(true);
    };

    const handleSearch = (value) => {
        setSearch(value);
        const filtered = crewMembers.filter((user) =>
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase())
        );
    
        setFilteredUsers(filtered);
        setDropdownOpen(true);
    };

    const handleSelectUser = (user, color, background) => {
        setSearch('');
        setAssignedUsers([{...user, color: color, background: background}])
        // setAssignedUsers(prevAssignedUsers => {
        //     const userExists = prevAssignedUsers.some(existingUser => existingUser.email === user.email);
        //     if (!userExists) {
        //         return [...prevAssignedUsers, { ...user, color: color, background: background }];
        //     }
        //     return prevAssignedUsers;
        // });
        setDropdownOpen(false);
    };

    const handleAssign = () => {
        if (assignedUsers?.length>0) {
            setSearch("");
            props?.onClick(assignedUsers,props?.value?.id||'')
        }
    };

    console?.log(crewMembers)

    const handleRemove = (user) => {
        setAssignedUsers(assignedUsers.filter((u) => u !== user));
        setFilteredUsers(assignedUsers.filter((u) => ![...assignedUsers.filter((u) => u?.email !== user?.email)].includes(u?.email)));
    };

    console.log(crewMembers)
    return (
        <Container>
           <Header>
               <div style={{display:'flex',alignItems:'center'}}> 
               <IconButton onClick={()=>props?.onClose()}>
                   <CancelOutlined/>
               </IconButton>
    
            <Title>Assign Territory</Title>
            </div>
            {props?.value?.id&&<DeleteButton onClick={()=>props?.onDelete(props?.value?.id)}>Delete</DeleteButton>}
        </Header>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <DropdownWrapper>
                    <DropdownInput
                        type="text"
                        placeholder="Search or select a crew..."
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        onFocus={handleInputFocus}
                        onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
                    />
                    {dropdownOpen && (
                        <DropdownList>
                            {filteredUsers?.map((user, index) => (
                                <DropdownItem key={index} onClick={() => handleSelectUser(user, calendarTeamColor[index]?.original||calendarTeamColor[index]?.original|| calendarTeamColor[crewMembers?.findIndex(member => member.firstName === user.firstName)]?.original, calendarTeamColor[index]?.light||'')}>
                                    <Avatar style={{ height: '22px', width: '22px', marginRight: '15px', background: calendarTeamColor[index]?.light||calendarTeamColor[crewMembers?.findIndex(member => member.firstName === user.firstName)]?.light}}>
                                        <Typography style={{ fontSize: '10px', fontWeight: '600', color: calendarTeamColor[index]?.original|| calendarTeamColor[crewMembers?.findIndex(member => member.firstName === user.firstName)]?.original  }}>{getName(`${user?.firstName} ${user?.lastName || ''}`)}</Typography>
                                    </Avatar>
                                    {user?.firstName} {user?.lastName}
                                </DropdownItem>
                            ))}
                            {crewMembers?.length === 0 && (
                                <DropdownItem>No users found</DropdownItem>
                            )}
                        </DropdownList>
                    )}
                </DropdownWrapper>
                <AssignButton onClick={handleAssign} disabled={!assignedUsers?.length > 0}>
                    Assign
                </AssignButton>
            </div>
            <UserList>
                <UserHeader>Assign member</UserHeader>
                {assignedUsers.map((user, index) => (
                    <User key={index}>
                        <div style={{display:'flex',alignItems:'center'}}>
                        <Avatar style={{ height: '22px', width: '22px', marginRight: '15px', background:calendarTeamColor[crewMembers?.findIndex(member => member.firstName === user.firstName)]?.light }} color={dayViewColor[index]}>
                            <Typography style={{ fontSize: '10px', fontWeight: '600', color:calendarTeamColor[crewMembers?.findIndex(member => member.firstName === user.firstName)]?.original   }}>{getName(`${user?.firstName} ${user?.lastName || ''}`)}</Typography>
                        </Avatar>
                        <div>
                        <UserName> {user?.firstName} {user?.lastName}</UserName>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', }}>{user?.email}</Typography>
                        </div>
                        </div>
                        <FiTrash2 onClick={() => handleRemove(user)}/>
                    </User>
                ))}
            </UserList>
            {assignedUsers?.length > 0 ? null : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="60vh"
                    sx={{ color: 'gray' }}
                >
                    <Person2Outlined sx={{ fontSize: '50px', marginBottom: 2 }} />
                    <Typography variant="h5" align="center">
                        No Member assigned
                    </Typography>

                </Box>
            )}
        </Container>
    );
};

export default AssignTerritory;
