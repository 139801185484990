
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { Device } from '@twilio/voice-sdk';
import { getAllconversations } from "./Auth.action";
export const quickbookAuth = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.quickBookAuth)
  return res
}

export const getUserDetails = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.userDetails)
  dispatch({ type: "USERDATA", payload: res?.data })
  dispatch(quickbookAuth())
  return res
}

export const sendNotification = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.notificationSend, data)
  // dispatch({ type: Constants.TYPE.notificationData, payload: res?.data })
  return res
}
export const getContactDetail = (data) => async (dispatch, getstate) => {
  dispatch({
    type: 'CONTACTDETAIL',
    payload: {}
  })
  const res = await NetworkOps.post(`${ServiceEnum.getContactDetail}`, {
    "phoneNumber": data
  })
  if (res.status === true) {
    if (res?.data?.[0]?._id) {
      dispatch({
        type: 'CONTACTDETAIL',
        payload: res?.data?.[0]
      })
    }

  }
  await dispatch(getUserDetails());
  return res
}
export const getContactDetailV2 = (id) => async (dispatch, getstate) => {
  dispatch({
    type: 'CONTACTDETAIL',
    payload: {}
  })
  const res = await NetworkOps.post(`${ServiceEnum.getActivitiyV2}`, {
    "id": id
  })
  if (res.status === true) {

    dispatch({
      type: 'CONTACTDETAIL',
      payload: res?.data?.[0]
    })


  }
  await dispatch(getUserDetails());
  return res
}
export const fetchAllContacts = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.fetchAllContactsV2}`)
  let sortdata = res.data
  sortdata.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
  dispatch({
    type: 'ALLCONTACTS',
    payload: res?.data
  })
  // dispatch({ type: Constants.TYPE.contactDetails, payload: sortdata })
  return res
}
export const fetchAllLeads = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getLeads}`)
  let sortdata = res.data
  sortdata.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
  dispatch({
    type: 'ALLCONTACTS',
    payload: res?.data
  })
  // dispatch({ type: Constants.TYPE.contactDetails, payload: sortdata })
  return res
}

export const submitEditContacts = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.editContact}?contactId=${data?._id}`, data)
  return res
}

export const CreateBookings = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.createBooking}`, value)
  dispatch(getUserDetails())
  return res
}
export const CreateBookingsV2 = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.createBookingV2}`, value)
  dispatch(getUserDetails())
  return res
}
export const editCreateBookings = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.editBookingDetail}`, value)
  return res
}
export const sendInvoice = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.sendInvoice}`, value)
  dispatch(getUserDetails())
  return res
}
export const sendMailInvoice = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.sendmailInvoice}`, value)
  return res
}
export const sendMailQuote = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.sendmailQuote}`, value)
  return res
}

export const submitNotes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.saveBookingNotes}`, data)
  return res
}

export const getTravelTime = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getTravelTime}?referenceNo=${val}`)
  return res
}

export const sendBookingInvoice = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.bookingInvoice}`, value)

  return res
}
export const saveTableFilter = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.saveTableFilter}`, value)
  return res
}
export const savejobTableFilter = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.saveJobTable}`, value)
  return res
}

export const sendQuote = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.sendQoute}`, value)
  dispatch(getUserDetails())
  return res
}

export const updateDetail = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.updateDetail, data)
  await dispatch(getuserInfo())
  return res
}

export const getuserInfo = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.getInfoDetail)
  dispatch({ type: "USERDETAILS", payload: res?.data })
  return res
}
export const getBookings = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getBookingsApi}`, value)
  return res
}
export const getBookingsV2 = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getBookingsApiV2}`, value)
  return res
}
export const getCalendarData = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.clanderData}`)
  return res
}
export const getCalendarDataV2 = (startDate, endDate) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.calendarDataV2}?startDate=${startDate}&endDate=${endDate}`)
  return res
}
export const getCalendarMapDataV2 = (startDate, endDate) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.clanderData}?status=Completed`)
  return res
}

export const getunreadCount = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.unreadCount}`)
  dispatch({ type: "UNREADCOUNT", payload: res?.data })
  return res
}
export const getInvoiceQuote = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.QuoteInvoice}`)
  dispatch({ type: "QUOTEINVOICE", payload: { "invoice": res?.data?.invoice, "quote": res?.data?.quotes } })
  return res
}
export const resetPassword = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.resetpassword, data)
  return res
}
export const saveTaxes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.saveTax}`, data)
  await dispatch(getUserDetails());
  return res
}

export const addNumber = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.AddNumber}`, value)
  dispatch(fetchAllContacts())
  return res
}

export const sendInviteToworker = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.inviteWorker, data)

  return res;
}
export const editQuote = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.editQuote}`, value)
  dispatch(getInvoiceQuote())
  return res
}
export const editInvoice = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.editInvoice}`, value)
  dispatch(getInvoiceQuote())
  return res
}

export const submitDlcBusinessRegistration = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.dlcbusinessVerfication}`, data)
  return res
}

export const fetchTeamData = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.teamFetch}`, data)
  return res
}
export const assignedJob = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.assignedJob, data)
  return res;
}
export const submitBookingStatus = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.status, data)
  return res
}
export const changeQuatestatus = (data, referenceNo) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.changeQuote}?referenceNo=${referenceNo}`, data)
  return res
}
export const changeInvoiceStatus = (data, referenceNo) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.changeinvoice}?referenceNo=${referenceNo}`, data)
  return res
}
export const getAccessToken = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.AccesTokeen}?type=Web`)
  const token = new Device(res?.data)

  token.register();
  const handleSuccessfulRegistration = () => {
    console.log('The device is ready to receive incoming calls.')
  }

  token.on('registered', handleSuccessfulRegistration);
  dispatch({ type: "CALLTOKEN", payload: token })
  return res
}
export const changePaymentMethod = (data) => async (dispatch, getstate) => {
  console.log(data, "data")
  const res = await NetworkOps.get(`${ServiceEnum.paymentMethod}?paymentType=${data?.val}&referenceNo=${data?.invoiceNo}`)
  // const res1 = await dispatch(getInvoiceQuote());
  return res
}
export const changeQuotePaymentMethod = (data) => async (dispatch, getstate) => {
  console.log(data, "data")
  const res = await NetworkOps.get(`${ServiceEnum.quotePaymnetMethod}?statusType=${data?.val}&referenceNo=${data?.invoiceNo}`)
  // const res1 = await dispatch(getInvoiceQuote());
  return res
}

export const submitchangeStatus = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.changeStatus, data)
  return res
}
export const submitassignTag = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.assignTag, data)
  return res
}
export const saveAdditionalContact = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.saveAdditionContact, data)
  return res
}
export const saveAdditionalAddress = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.saveAdditionalAddress, data)
  return res
}
export const saveAdditionalNotes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.additionalNotes, data)
  return res
}
export const deleteAdditionalAddress = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteAddress}?contactId=${data?.id}&additionalAddressId=${data.addressId}`)
  return res
}
export const deleteAdditionalNotes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteAdditionalNote}?contactId=${data?.id}&additionalContactId=${data.addressId}`)
  return res
}

export const addHasgtag = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.addHashTag, data)
  dispatch(getUserDetails())
  return res
}
export const addDetaisNote = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.addNoteContact, data)
  return res
}
export const submitassignCustomField = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.assignCustomField, data)
  return res
}
export const createNewCustomField = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.createCustomField, data)
  dispatch(getUserDetails())

  return res
}
export const getLineItems = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.getLineItems)
  return res
}
export const submitAddTask = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.addTask, data)
  return res
}
export const submitEditTask = (data, id) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.editTask}?taskId=${id}`, data)
  return res
}
export const submitEditEvent = (data, id) => async (dispatch, getstate) => {
  const res = await NetworkOps.put(`${ServiceEnum.editEvent}?id=${id}`, data)
  return res
}
export const submitAddEvent = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.addEvent, data)
  return res
}
export const submitFormNotification = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.formNotification, data)
  return res
}

export const addnewLineItems = (data, value) => async (dispatch) => {
  var formData = new FormData();
  formData.append('files', data);
  formData.append("data", JSON.stringify(value));
  console.log([...formData.entries(), "dhyvdjhcdsjcdhvbhjvghj"])
  const res = await NetworkOps.post(ServiceEnum.addLineItems, formData, {
    "content-Type": 'multipart/form-data'
  });
  await dispatch(getUserDetails())
  return res
}
export const addnewBundleItems = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.saveBundles, data);
  await dispatch(getUserDetails())
  return res
}
export const editnewBundleItems = (data, id) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.editBundles}?bundleId=${id}`, data);
  await dispatch(getUserDetails())
  return res
}
export const deletenewBundleItems = (id) => async (dispatch) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteBundles}?bundleId=${id}`);
  await dispatch(getUserDetails())
  return res
}
export const addContactPhotos = (data, value) => async (dispatch) => {
  var formData = new FormData();
  console.log(value, "data")
  formData.append('contactId', data);
  value?.map((item, index) => {
    formData.append(`file${index}`, item);
  })
  console.log([...formData.entries(), "dhyvdjhcdsjcdhvbhjvghj"])
  const res = await NetworkOps.post(ServiceEnum.addContactPhotos, formData, {
    "content-Type": 'multipart/form-data'
  });

  return res
}
export const UploadNotesImage = (data) => async (dispatch, getstate) => {
  console.log(data, "data")
  var formData = new FormData();
  data?.map((item, index) => {
    formData.append(`file${index}`, item);
  })
  const res = await NetworkOps.post(ServiceEnum.UploadNotesImage, formData, {
    "content-Type": 'multipart/form-data'
  });
  return res;
}
export const UploadImage = (data) => async (dispatch, getstate) => {
  console.log(data, "data")
  var formData = new FormData();
  formData.append(`file`, data);
  const res = await NetworkOps.post(ServiceEnum.UploadNotesImage, formData, {
    "content-Type": 'multipart/form-data'
  });
  return res;
}
export const deleteAddTask = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteTask}?taskId=${val}`)
  dispatch(getunreadCount())
  return res
}
export const deleteAddEvent = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteEvent}?id=${val}`)
  dispatch(getunreadCount())
  return res
}

export const DoneAddTask = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.doneTask}?taskId=${val}&mark=Done`)
  dispatch(getunreadCount())
  return res
}

export const submitEditLineItem = (data, value) => async (dispatch, getstate) => {
  var formData = new FormData();
  formData.append('files', data);
  formData.append("data", JSON.stringify(value));
  console.log([...formData.entries(), "dhyvdjhcdsjcdhvbhjvghj"])
  const res = await NetworkOps.post(`${ServiceEnum.editLineItem}`, formData, {
    "content-Type": 'multipart/form-data'
  });
  await dispatch(getUserDetails())
  return res
}
export const deleteLineItem = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.deleteItem}?typeOfData=product`, val)

  return res
}
export const getCallLogs = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(ServiceEnum.callLog, {
    "phoneNumber": `+${localStorage.getItem('number')}`
  })
  return res
}
export const getMissionControl = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.missionControl}`)

  return res
}
export const fetchbookingDetails = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.fetchBookingDetails}?referenceNo=${val}`)
  return res
}
export const submitForm = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.sendForm, data)

  return res
}

export const saveDeviceId = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.deviceId, data)
  return res
}
export const getForm = (referenceNo) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getForm}?formNo=${referenceNo}`)
  return res
}
export const editForm = (referenceNo, data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.editForm}?formNo=${referenceNo}`, data)
  return res
}
export const getrequestForm = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getRequestForm}`)
  return res
}
export const getListForm = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getFormList}`)
  return res
}
export const saveMessageTemp = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.messageTemp}`, data)
  await dispatch(getUserDetails());
  return res
}
export const getHighlights = () => async (dispatch, getstate) => {
  const session = localStorage.getItem('fromnumber')
  var cleaned = ("" + session).replace(/\D/g, "");

  const res = await NetworkOps.post(ServiceEnum.gethightlights, {
    "from": parseInt(cleaned)
  })
  return res
}
export const markAsread = (data) => async (dispatch, getstate) => {
  const val = {
    "sids": data
  }
  const res = await NetworkOps.post(ServiceEnum.markAsread, val)
  await dispatch(getAllconversations())
  await dispatch(getunreadCount())
  return res
}

export const getFormDetails = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.getFormDetails)
  return res
}
export const deleteForm = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteForm}?referenceNo=${val}`)

  return res
}
export const deleteCrew = (val) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteAccount}?workerEmail=${val}`)
  return res
}
export const submitEditForm = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.editSendForm}`, data)

  return res
}
export const submitUploadProfile = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.UploadProfile}`, data)
  await dispatch(getUserDetails())
  return res
}
export const createLead = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.createLeads}`, data)
  return res
}

export const convertTorequest = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.converTorequest}`, data)
  return res
}
export const addTermAndCondition = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.addterm}`, data)
  return res
}



export const pendingInvite = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.pendingInvite)
  return res
}
export const invoiceGraph = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.invoiceGraph}?interval=${value}`)
  return res
}


export const callGraph = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.callLogsGraph}?interval=${value}`)
  return res
}
export const filterGraph = (date, endDate) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.filterGraph}?startDate=${date}&endDate=${endDate}`)
  return res
}
export const deleteContact = (value) => async (dispatch, getstate) => {
  console.log(value, "value")
  const res = await NetworkOps.post(`${ServiceEnum.deleteContact}?contactId=${value}`)
  return res
}
export const submitchecklist = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.userChecklist}`, value)
  await dispatch(getUserDetails())
  return res
}
export const updatejobData = (data, referenceNo) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.updateJob}?referenceNo=${referenceNo}`, data)
  return res
}

export const deleteJob = (value) => async (dispatch, getstate) => {
  console.log(value, "value")
  const res = await NetworkOps.post(`${ServiceEnum.deleteJob}?bookingId=${value}`)
  return res
}
export const deleteAdditionalContact = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteAdditionalContact}?contactId=${data?.id}&additionalContactId=${data.contactId}`)
  return res
}