import React, { useState } from 'react';
import styled from 'styled-components';
import { Cancel, DeleteOutlined } from '@mui/icons-material';

import { useDispatch } from 'react-redux';

import { submitchecklist } from '../../store/Actions/dashboard.action';
import { palette } from '../../utils/Palette';
import RippleButton from '../../ReuseableComponets/RippleButton';
import InputPicker from '../../ReuseableComponets/InputPicker';
import InputBox from '../../ReuseableComponets/InputBox';
import { submitEditchecklist } from '../../store/Actions/SettingsAction';
import { FiTrash2 } from 'react-icons/fi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top:80px;
`;


const SectionTitle = styled.h5`
  text-align: start;
  margin: 15px 5px 0px 5px;
  font-weight: 500;
`;

const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const InputItem = styled(InputBox)`
  width: 85%;
`;


const AddButton = styled.button`
border: 1px solid #0F52BA;
margin-top: 20px;

justify-content: center;
display: flex;
padding: 7px 12px;
border-radius: 20px;
cursor: pointer;
background:#fff;
transition: background-color 0.3s ease, box-shadow 0.3s ease;

&:hover {
  background-color: rgba(15, 82, 186, 0.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
`;
const ButtonText = styled.h4`
  text-align: start;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0F52BA;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
`;

const StyledRippleButton = styled(RippleButton)`
  margin-right: 15px;
  ${({ variant }) =>
        variant === 'cancel'
            ? `
    background: #fff;
    color: #414552;
    border: 0.5px solid #414552;
  `
            : ''}
`;
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
align-self:center;
  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton{
      
  }
`;
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#fff;
margin-right:15px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const HeaderDiv = styled.div`
    display: flex;
    align-items: center;
    margin-left:14px;
    .title{
        color: ${palette.charcoleBlack};  
    }
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;
const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;

const AddChecklist = (props) => {
    const [boolValue, setBoolValue] = useState({ addchecklist: true });
    const [addChecklist, setAddChecklist] = useState({ title: props?.selectedlist?.title || '', data: [] });
    const [checklistItemData, setChecklistItemData] = useState(props?.selectedlist?.data ? [...props?.selectedlist?.data] : [{
        type: 'Checkbox',
        value: '',
    }]);
    const [selectedChecklist, setSelectedChecklist] = useState(props?.value?.checklistNo || [])
    const dispatch = useDispatch();

    return (
        <>

            <Container>
                <HeaderContainer >
                    <HeaderDiv>
                        <CloseButton onClick={() => props?.onClose(false)}>
                            <Cancel style={{ fontSize: '24px' }} />
                        </CloseButton>
                        <h4 className="title">{props?.selectedlist?._id ? "Edit checklist" : "Add checklist"}</h4>
                    </HeaderDiv>
                    <RippleButton style={{ marginRight: '20px' }}
                        onClick={async () => {
                            if (props?.selectedlist?._id) {
                                const res1 = await dispatch(
                                    submitEditchecklist({ ...addChecklist, data: checklistItemData ,"checklistNo":props?.selectedlist?.checklistNo,})
                                );
                               
                                setBoolValue({ ...boolValue, addchecklist: false });
                            }
                            else {
                                const res1 = await dispatch(
                                    submitchecklist({ ...addChecklist, data: checklistItemData })
                                );
                                setSelectedChecklist((prev) => [
                                    ...prev,
                                    { ...addChecklist, data: checklistItemData, checklistNo: res1?.data?.checklistNo },
                                ]);
                                setBoolValue({ ...boolValue, addchecklist: false });
                            }
                            props?.setSubmit()
                        }
                        }

                    >Save</RippleButton>
                </HeaderContainer>

                <InputBox
                    header="Checklist name"
                    style={{ width: '95%' }}
                    placeholder="Checklist name"
                    value={addChecklist?.title}
                    onChange={(e) =>
                        setAddChecklist({ ...addChecklist, title: e.target.value })
                    }
                />
                <SectionTitle>Checklist item</SectionTitle>
                {checklistItemData?.map((item, index) => (
                    <ChecklistItem key={index}>
                        <InputPicker
                            value={item?.type}
                            label="Type"
                            style={{ height: '40px', background: 'white' }}
                            data={[
                                { label: 'Checkbox', value: 'Checkbox' },
                                { label: 'Text', value: 'Text' },
                               
                            ]}
                            onChange={(val) => {
                                const data = [...checklistItemData];
                                data[index].type = val;
                                setChecklistItemData([...data]);
                            }}
                        />
                        <InputItem
                            placeholder="Enter item"
                            value={item?.value}
                            onChange={(e) => {
                                const data = [...checklistItemData];
                                data[index].value = e.target.value;
                                setChecklistItemData([...data]);
                            }}
                        />
                        <ActionIcons>
                        <FiTrash2 onClick={async () => {
                               const data = [...checklistItemData];
                               data.splice(index, 1);
                               setChecklistItemData(data);
                      }} />
                        </ActionIcons>
                      
                    </ChecklistItem>
                ))}
                <ButtonGroup>
                <AddButton
                    onClick={() =>
                        setChecklistItemData([
                            ...checklistItemData,
                            {
                                type: 'Checkbox',
                                value: '',
                            },
                        ])
                    }
                >
                  <ButtonText>  + Checklist item</ButtonText>
                </AddButton>
                </ButtonGroup>

            </Container>
        </>
    );
};

export default AddChecklist;
