import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import { sendMessage } from "../../store/Actions/Auth.action";
import { palette } from "../../utils/Palette";
import RightModal from "../../Components/RightSideModal";
import { deleteServicePlan } from "../../store/Actions/SettingsAction";
import { FiTrash2 } from "react-icons/fi";
import { Box, Typography } from "@mui/material";
import { PriceCheck } from "@mui/icons-material";
import AddServicePlan from "./AddServicePlan";
import { getAllServicePlan } from "../../store/Actions/JobAction";
import { putCommaInvalue } from "../../utils/Commonfunction";

function ServicePlan(props) {
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedlist, setSelectedList] = useState({})
    const [searchChecklist, setSearchChecklist] = useState([])
    const containerRef = useRef(null);


    const dispatch = useDispatch()
    const init = async () => {
        setLoader(true)
        let res = await dispatch(getAllServicePlan())
        if (res.status === true) {
            setLoader(false)
            setSearchChecklist(res?.data)
            console.log(res?.data)
        }
        setLoader(false)
    }

    useEffect(() => {
        init()
    }, [])

    const deleteLine = async (id) => {
        await dispatch(deleteServicePlan(id))
        init()
    }

    const renderlineItem = (dataitem, type) => {
        return (
            <div ref={containerRef} style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                    <CustomHead>
                        <TableRow >
                            <TableCell className="sticky" size="small" style={{ cursor: 'pointer', width: '40px', }}>
                                #</TableCell>

                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                Name
                            </TableCell>

                            {/* <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                Description

                            </TableCell> */}
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                Plan

                            </TableCell>
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                Discount (%)

                            </TableCell>

                            <TableCell style={{ width: '100px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                Actions
                            </TableCell>
                        </TableRow>
                    </CustomHead>
                    {dataitem?.map((item, index) => <>
                        <TableBody key={index} style={{ background: '#fff', cursor: 'pointer' }} >
                            <TableRow >
                                <TableCell style={{ fontWeight: '500',maxWidth:'100px' }} className="sticky" size='small'>{index + 1}</TableCell>
                                <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  >
                                    {item?.name}
                                </TableCell>

                                {/* <TableCell size='small' style={{ fontWeight: '500',maxWidth:'200px' }}  >
                                    {item?.description} 

                                </TableCell> */}
                                <TableCell size='small' style={{ fontWeight: '500' }}  >
                                   {item?.currency=='cad'?'CA$':'US$'}{putCommaInvalue(parseFloat(item?.amount)?.toFixed(2))} per {item?.interval?.intervalCount>1? `${item?.interval?.intervalCount} ${item?.interval?.interval}s`:item?.interval?.interval}

                                </TableCell>
                                <TableCell size='small' style={{ fontWeight: '500' }}  >
                                    {item?.discount} 

                                </TableCell>
                                <TableCell size='small'  >
                                    <ActionIcons>
                                    
                                        <FiTrash2 onClick={async () => {
                                            await dispatch(deleteLine(item?.productId))
                                            init()
                                        }} />

                                    </ActionIcons >
                                </TableCell>

                            </TableRow>
                        </TableBody>

                    </>)}
                </Table>
                {dataitem?.length > 0 ? null : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="60vh"
                        sx={{ color: 'gray' }}
                    >
                        <PriceCheck sx={{ fontSize: '50px', marginBottom: 2 }} />
                        <Typography variant="h5" align="center">
                            No Service plans available
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            You can add new Service plan to manage your data more effectively.
                        </Typography>
                    </Box>
                )}
            </div>
        )
    }

    return (
        <>

            <Container>
                <CustomTable>
                    <Header>
                        <h2>Service plans</h2>
                        <AddChecklistButton onClick={() => {
                            setSelectedList({})
                            setModalOpen(true)
                        }}>+ Plan</AddChecklistButton>
                    </Header>

                    {loader && searchChecklist?.length <= 0 ? <div style={{ display: 'flex', height: '70%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>

                        :
                        <>
                            {renderlineItem(searchChecklist)}
                        </>
                    }

                </CustomTable>
                {modalOpen && (
                    <RightModal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}>
                        <AddServicePlan
                            selectedlist={selectedlist}
                            setSubmit={() => {
                                setModalOpen(false)
                                init()
                            }}
                        />
                    </RightModal>
                )}





            </Container>

        </>
    )
}

export default ServicePlan;


const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }


  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:30px;
  max-width:30px;

}
`;
const Container = styled.div`

width:100%;
background:#fff;
padding-top:30px;

display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
const AddChecklistButton = styled.button`
  background-color: #0F52BA;
  color: white;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #08417c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right:20px;
  margin-left:20px;

  h2{
    margin-top:0px;
    margin-bottom:0px;
  }
`;