
export const LOCAL_HOST = 'https://apis.getnotifi.com';
export const fromnumber = '+15878064483'
export const renderTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
};

export const ServiceEnum = {
    register: "user/notify/signup",
    loginUser: 'user/notify/login',
    userDetails: "user/notify/getUserData",
    displayNumber: 'user/display',
    buyNumber: 'user/orderNumber',
    paymentLink: 'user/notify/subscription',
    locationDetails: 'user/notify/getLocation',
    livetracker: 'tracker',
    conversations: 'twilio/fetchAllConversation',
    fetchAllConvo1: 'user/v4/getAllConversations',
    chats: 'user/v6/getConversation',
    sendMessage: 'user/createConversation/v2',
    quotedata: 'booking/notify/getQuote',
    userdata: 'user/notify/getUserData',
    sendPhoneCode: 'user/getVerficationCode',
    invoiceData: 'booking/notify/getInvoice',
    phoneVerification: 'user/confirmVerficationCode',
    saveSign: 'user/notify/saveSignature',
    deviceId:'user/notify/saveDeviceId',
    ChatgptApi: 'https://api.openai.com/v1/chat/completions',
    job: 'https://api.openai.com/v1/fine_tuning/jobs',
    generateImg: 'https://api.openai.com/v1/images/generations',
    notificationSend: 'user/sendNotification',
    getContactDetail: 'user/notify/getActivity',
    fetchAllContactsV2: 'user/v2/notify/fetchContact',
    editContact: 'user/notify/editContact',
    createBooking: 'booking/notify/createBooking',
    createBookingV2: 'booking/notify/v4/createBooking',
    sendInvoice: 'booking/notify/sendInvoice',
    sendQoute: 'booking/notify/sendQuote',
    updateDetail: 'user/notify/updateDetails',
    getInfoDetail: 'user/notify/getUser',
    getBookingsApi: 'booking/notify/v3/booking/getDetial',
    getBookingsApiV2: 'booking/notify/V3/booking/getDetial',
    QuoteInvoice: 'booking/notify/getQuotesAndInvoice',
    resetpassword: 'user/notify/resetPassword',
    saveTax: 'user/notify/saveTax',
    AddNumber: 'user/notify/saveContact',
    inviteWorker: 'user/notify/sendInviteLinkToWokers',
    editInvoice: 'booking/notify/editInvoice',
    editQuote: 'booking/notify/editQuote',
    dlcbusinessVerfication: 'user/createBrand',
    teamFetch: 'booking/notify/getTeamBooking',
    assignedJob: 'user/notify/v2/assignJob',
    status: 'booking/notify/changeStatus',
    changeQuote: 'booking/notify/changeQuote',
    changeinvoice: 'booking/notify/changeInvoice',
    AccesTokeen: 'twilio/notify/accessToken',
    paymentMethod: 'booking/notify/otherPaymentMethod',
    quotePaymnetMethod: 'booking/notify/quote/otherPaymentMethod',
    changeStatus:'user/notify/changeStatus',
    assignTag:'user/notify/assignTags',
    addHashTag:'user/notify/addTags',
    addNoteContact:'user/notify/addNotesContact',
    createCustomField:'user/notify/v2/createCustomField',
    assignCustomField:'user/notify/assignCustomField',
    getLineItems:'user/v2/getLineItems',
    addLineItems:'user/saveLineItem',
    addContactPhotos:'user/notify/contact/addPhotos',
    addTask:'user/notify/contact/addTask',
    UploadNotesImage:'booking/notify/uploadImageNotes',
    deleteTask:'user/notify/contact/deleteTask',
    deleteEvent:'user/notify/calendar/deleteEvent',
    doneTask:'user/notify/contact/doneTask',
    editLineItem:'user/notify/edit/lineItem',
    deleteItem:'booking/notify/deleteLineItem',
    callLog:'user/notify/callLog',
    missionControl:'user/notify/v2/returnAllTask',
    bookingInvoice:'booking/notify/sendInvoice',
    fetchBookingDetails:'booking/notify/fetchBookingDetail',
    editBookingDetail:'booking/notify/editBooking',
    sendForm:'user/notify/custom/sendForm',
    getForm:'user/notify/custom/getForm',
    editForm:'user/notify/custom/editForm',
    getRequestForm:'user/notify/custom/getLatestForm',
    getFormList:'user/notify/custom/getAllForm',
    messageTemp:'user/notify/saveMessageTemplate',
    sendmailInvoice:'user/notify/email/sendInvoice',
    sendmailQuote:'user/notify/email/sendQuote',
    gethightlights:'twilio/notify/latestMessage',
    markAsread:'twilio/notify/markAsRead',
    getFormDetails:'user/notify/userFormData',
    deleteForm:'user/notify/custom/deleteSendForm',
    editSendForm:'user/notify/custom/editSendForm',
    createLeads:'user/notify/createLeads',
    pendingInvite:'user/notify/pendingInvite',
    invoiceGraph:'user/notify/graph/Invoice',
    callLogsGraph:'user/notify/graph/callLog',
    filterGraph:'user/notify/graph/Invoice',
    converTorequest:'booking/notify/quoteToBooking',
    deleteAccount:'user/notify/deleteWorker',
    deleteContact:'user/notify/deleteContact',
    addterm:'user/notify/custom/addTerm',
    saveTableFilter:'user/notify/saveFilter',
    getActivitiyV2:'user/notify/v2/getActivity',
    saveAdditionContact:'user/notify/saveAdditionalContact',
    saveAdditionalAddress:'user/notify/saveAdditionalAddress',
    deleteAddress:'user/notify/delete/additionalAddress',
    additionalNotes:'user/notify/saveAdditionalNotes',
    deleteAdditionalNote:'user/notify/delete/additionalNotes',
    deleteJob:'booking/notify/deleteBooking',
    deleteAdditionalContact:'user/notify/delete/additionalContact',
    userChecklist:'user/notify/createChecklist',
    updateJob:'booking/notify/v2/updateBookingDetail',
    UploadProfile:'user/notify/saveLogoImage',
    getLeads:'user/notify/getLeads',
    getTravelTime:'booking/notify/getTravelTime',
    unreadCount:'user/notify/unredCount',
    formNotification:'user/notify/formNotification',
    saveBundles:'user/notify/saveBundles',
    editBundles:'user/notify/editBundles',
    deleteBundles:'user/notify/deleteBundles',
    saveBookingNotes:'booking/notify/creatNotes',
    getTravelTime:'booking/notify/getTravelTime',
    createTemplate:'booking/notify/createJobTemplate',
    deleteTemplate:'booking/notify/deleteTemplate',
    editTemplate:'booking/notify/editTemplate',
    getChecklist:'user/notify/getAllChecklist',
    editChecklist:'user/notify/editChecklist',
    deleteChecklist:'user/notify/deleteChecklist',
    createChecklist:'user/notify/createWorkerChecklist',
    saveJobTable:'user/notify/saveFilterBooking',
    clanderData:'user/notify/calendar/getAllData',
    addEvent:'user/notify/calendar/createEvent',
    addVisit:'booking/notify/updateBooking/addMultivist',
    editVisit:'booking/notify/updateBooking/editMultivist',
    stripeSession:'account_session',
    calendarDataV2:'user/notify/v2/calendar/getAllData',
    createReoccuring:'booking/notify/v3/createBooking/reOccuring',
    bulkDelete:'user/notify/deleteContacts',
    getAllCustomField:'user/notify/getAllCustomField',
    deleteCustomField:'user/notify/deleteCustomField',
    editCustomField:'user/notify/editCustomField',
    getAllTags:'user/notify/getAllTags',
    deleteTag:'user/notify/deleteTags',
    getAllTypePriority:'user/notify/jobPriority/getAll',
    addTypePriority:'user/notify/jobPriority/create',
    deleteTypePriority:'user/notify/jobPriority/delete',
    getAllContatctStatus:'user/notify/contact/getAll',
    createContatctStatus:'user/notify/contact/addStatus',
    deleteContatctStatus:'user/notify/contact/delete',
    editTask:'user/notify/contact/editTask',
    editEvent:'user/notify/calendar/editEvent',
    savePaymentMethod:'user/notify/savePaymentMethod',
    quickBookAuth:'oAuth/refreshQbAccessToken',
    getAllServicePlan:'company/getAllProducts',
    addServicePlan:'company/createProduct',
    createSubscription:'company/createSubscription',
    deleteServicePlan:'company/deleteProduct',
    getTerritory:'api/territories/fetchTerritory',
    createTerritory:'api/territories/create',
    deleteTerritory:'api/territories/delete',
    updateTerritory:'api/territories/update',
    getContactMapData:'user/notify/contacts/withLocation',
    createCallTree:'api/callTree/create',
    updateCallTree:'api/callTree/update',
    deleteCaTree:'api/callTree/delete',
    getCallTree:'api/callTree/fetch',
    deleteAttachment:'user/notify/contacts/deleteAttachment',
    updateAutotimation:'api/automation/update'




}

export const urlFor = (services) => {
    if (services === 'https://api.openai.com/v1/chat/completions' || services === 'https://api.openai.com/v1/fine_tuning/jobs' || services === 'https://api.openai.com/v1/images/generations') {
        return `${services}`
    }
    else {
        return `${LOCAL_HOST}/${services}`
    }
}