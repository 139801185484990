import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Leads from '../../assets/Leads.png'
import cross from '../../assets/cross.png'
import Modal from "../Modal";
import AddTeamMember from "../Settings/AddTeamMember";
import { useDispatch, useSelector } from "react-redux";
import { formatMobileNumber } from "../../utils/Commonfunction";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken, getunreadCount, getUserDetails, getuserInfo } from "../../store/Actions/dashboard.action";
import {  calander, calanderColor, contactIcon, contactIconColor, FromColor, FromIcon, homeColor, homes, inbox, inboxBlue, Invoice, InvoiceColor, job, jobColor, MissionControl, MissionControlColor, plusIcon, quoteIcon, quoteIconColor, support, supportColor, team, teamColor, telephone, telephoneColor } from "../../assets";
import { palette } from "../../utils/Palette";
import {
   MessageOutlined, CallOutlined, KeyboardCommandKeyOutlined,
  AutoAwesome,
  PersonOutlineOutlined,
  CalendarMonthOutlined,
  RequestQuoteOutlined,
  PaidOutlined,
  HandymanOutlined,
  FeedOutlined,
  SettingsOutlined,
  AddCircle,
  CancelRounded,
  LocalAtmOutlined,
  AccountBalanceWalletOutlined

} from '@mui/icons-material';
import { Badge } from "@mui/material";
import io from 'socket.io-client';
import { FiHash, FiHome } from "react-icons/fi";
import { FaDrawPolygon } from "react-icons/fa";
const socket = io('https://apis.getnotifi.com',{autoConnect:false})
function LeftMenu(props) {
  const [isModalopen, setModalOpen] = useState(false);
  const userdata = useSelector(state => state?.auth?.userData)
  const unreadCount = useSelector(state => state?.auth?.unreadCount)
  const detailData = useSelector(state => state?.auth?.userDetails)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const socketRefresh=async()=>{
    await dispatch(getunreadCount())
  }

  useEffect(() => {
    socket.connect()
    socket.on('unreadCountUpdate', (msg) => {
      socketRefresh()
      socket.close()
      socket.connect()
    })
    
    return () => {
      socket.off('unreadCountUpdate');
    };
  }, []);


  const elements = [
    {
      'name': 'Home',
      'icon': homes,
      'colorIncon': homeColor,
      'outlined': FiHome,
      'screen': '/dashboard/home'
    },

    {
      'name': 'Inbox',
      'icon': inbox,
      'colorIncon': inboxBlue,
      'outlined': MessageOutlined,
      'screen': '/dashboard/inbox'
    },
    {
      'name': 'Call logs',
      'icon': telephone,
      'colorIncon': telephoneColor,
      'outlined': CallOutlined,
      "screen": '/dashboard/calllogs'
    },
    {
      'name': 'Leads',
      'icon': Leads,
      'colorIncon': Leads,
      'outlined': AutoAwesome,
      'screen': '/dashboard/leads'
    },
    {
      'name': 'Notifications',
      'icon': MissionControl,
      'colorIncon': MissionControlColor,
      'outlined': KeyboardCommandKeyOutlined,
      'screen': '/dashboard/missioncontrol'
    },

  ]
  const element2 = [
    {
      'name': 'Contacts',
      'icon': contactIcon,
      'colorIncon': contactIconColor,
      'outlined': PersonOutlineOutlined,
      "screen": '/dashboard/contacts'
    },

    {
      'name': 'Calendar',
      'icon': calander,
      'colorIncon': calanderColor,
      'outlined': CalendarMonthOutlined,
      "screen": '/dashboard/calander'
    },
    {
      'name': 'Quotes',
      'icon': quoteIcon,
      'colorIncon': quoteIconColor,
      'outlined': RequestQuoteOutlined,
      "screen": '/dashboard/quotes'
    },
    {
      'name': 'Invoices',
      'icon': Invoice,
      'colorIncon': InvoiceColor,
      'outlined': PaidOutlined,
      "screen": '/dashboard/invoices'
    },
    {
      'name': 'Jobs',
      'icon': job,
      'colorIncon': jobColor,
      'outlined': HandymanOutlined,
      "screen": '/dashboard/servicerequests'
    },
    {
      'name': 'Customer forms',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': FeedOutlined,
      "screen": '/dashboard/forms'
    },
    {
      'name': 'Pricebook',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': LocalAtmOutlined,
      "screen": '/dashboard/pricebook'
    },
    {
      'name': 'Payments',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': AccountBalanceWalletOutlined,
      "screen": '/dashboard/payments'
    },
    {
      'name': 'Sales hub',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined':FaDrawPolygon,
      "screen": '/dashboard/mapcontact'
    },




  ]


  const init = async () => {
    await dispatch(getUserDetails())
    await dispatch(getuserInfo())
    await dispatch(getunreadCount())
    await dispatch(getAccessToken())

  }

  useEffect(() => {
    init()
  }, [])
  const getName = (channel) => {
    if (!channel) {
      return
    }
    const val = channel?.split(' ')

    if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
    }
    else {
      return `${val[0]?.charAt(0).toUpperCase()}`
    }
  }

  return (
    <Main>
      <HeaderContainer>
        {userdata?.logoImage ? <ProfilePic src={userdata?.logoImage} /> : <OutProfile>
          {getName(userdata?.businessName)}
        </OutProfile>}
        <IconWrapper>
        <Heading>{detailData?.firstName} {detailData?.lastName}</Heading>
          <Header>{userdata?.businessName?.slice(0, 17)}{userdata?.businessName?.length > 17 ? '...' : ''}</Header>
        </IconWrapper>
      </HeaderContainer>
      <div>
        <Element style={{ background: anchorEl && palette.smokeWhite }} onClick={handleClick}>
          <div style={{display:'flex',alignItems:'center'}}>
          {anchorEl ? <CancelRounded
            style={{ fontSize: '18px', color: anchorEl ? palette.newBlue : '#000', marginRight: '15px', marginLeft: '8px' }}
          /> : <AddCircle
            style={{ fontSize: '18px', color: anchorEl ? palette.newBlue : '#000', marginRight: '15px', marginLeft: '8px' }}
          />
          }
          <span style={{ color: anchorEl ? palette.newBlue : '#000' }}>{"Create"}</span>
          </div>
        </Element>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                overflow: 'visible',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 8,
                  width: 10,
                  height: 10,
                  backgroundColor: 'inherit',
                  transform: 'translateY(-50%) rotate(45deg)',
                  boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                },
              },
            },
          }}
          style={{ marginLeft: '20px' }}
        >

          <Typography style={{ display: 'flex' }} sx={{ p: 2 }}>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/contacts?contact=true')
              }}
            >

              <PersonOutlineOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.green }}

              />
              Contact
            </CreateContainer>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/invoices?invoice=true')
              }}
            >

              <PaidOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.indinRed }} />
              Invoice
            </CreateContainer>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/quotes?quote=true')
              }}
            >

              <RequestQuoteOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.orange }}

              />
              Quote
            </CreateContainer>
            <CreateContainer>

              <HandymanOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.pup }}

                onClick={() => {
                  handleClose()
                  navigate('/dashboard/servicerequests?request=true')
                }}
              />
              Job
            </CreateContainer>
            {/* The content of the Popover. */}
          </Typography>
        </Popover>
      </div>
      <div style={{ paddingBottom: '10px' }}>
        {elements?.map((x, index) => {
          const OutlinedComponent = x?.outlined
          return (
            <Element className={location.pathname.startsWith(x.screen) ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              props?.setIndex(x.name)

            }}>
              <div style={{display:'flex',alignItems:'center'}}>
                
              <OutlinedComponent
                style={{ fontSize: location.pathname.startsWith(x.screen) ? '16px' : '16px', color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
              />
              <span style={{ color: location.pathname.startsWith(x.screen) ? '#fff' : 'rgba(32, 32,32, 0.90)' }}>{x.name}</span>
              </div>
              {x.name === 'Inbox' && unreadCount?.unreadCountMessage > 0 && (
                <Badge style={{background:'#fa3e3e',color:'white',width: '19px', height: '19px',justifyContent:'center',alignItems:'center', marginRight:'15px', fontSize:'9px',borderRadius:'10px'}}>
                  {unreadCount?.unreadCountMessage > 90 ? '90+' : unreadCount?.unreadCountMessage}
                </Badge>
              )}
               {x.name === 'Notifications' && unreadCount?.missionControlCount > 0 && (
                <Badge style={{background:'#fa3e3e',color:'white',width: '18px', height: '18px',justifyContent:'center',alignItems:'center',marginRight:'15px', fontSize:'9px',borderRadius:'10px'}}>
                  {unreadCount?.missionControlCount > 90 ? '90+' : unreadCount?.missionControlCount}
                </Badge>
              )}
            </Element>
          )
        })}
  
      </div>
      <ParentContainer>
        <PrimaryNumberContainer>
        <StyledIconButton>
       <FiHash/>
       </StyledIconButton>
        <IconWrapper>
        <Heading style={{fontSize:'13px',marginTop:'0px'}}>Primary number</Heading>
          <Header style={{marginBottom:'0px',fontSize:'12px'}}>{formatMobileNumber(localStorage.getItem('number'))}</Header>
        </IconWrapper>
        </PrimaryNumberContainer>
      </ParentContainer>
      <div style={{  paddingBottom: '115px',overflowY:'scroll',  scrollbarWidth: 'none', 
    msOverflowStyle: 'none',
  }}
>
  <style>{`
    div::-webkit-scrollbar {
      display: none;
    }
  `}</style>
        {element2?.map((x, index) => {
          const OutlinedComponent = x?.outlined
          return (( x?.name=='Sales hub'&&(localStorage.getItem('number') !== '16043326202' && localStorage.getItem('number') !== '14064123376'))?null:
            <Element className={location.pathname.startsWith(x.screen) ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              props?.setIndex(x.name)
            }}>
                <div style={{display:'flex',alignItems:'center'}}>
              <OutlinedComponent
                style={{ fontSize: x.screen === '/dashboard/home' ? '16px' : '16px', color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
              />
              <span style={{ color: location.pathname.startsWith(x.screen) ? '#fff' : 'rgba(32, 32,32, 0.90)' }}>{x.name}</span>
              </div>
            </Element>
          )
        })}
     
      </div>
      <BottomTexts>

        {/* <Element2 onClick={() => {
          navigate('/dashboard/crewmember')
          setActive('crew')
          props?.setIndex("crew")
        }} className={location.pathname === '/dashboard/crewmember' ? "blueclass" : ''}><GroupOutlined 
         alt='addteam' 
        style={{ fontSize: '16px', color: location.pathname === '/dashboard/crewmember' ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
        /><span style={{ color: location.pathname === '/dashboard/crewmember' ? '#fff' : '#353a44' }}>Crew members</span></Element2> */}
        <Element2 className={location.pathname === '/dashboard/settings' ? "blueclass" : ''} onClick={() => {
          navigate('/dashboard/settings/account-details')
          props?.setIndex("settings")
        }}><SettingsOutlined

            style={{ fontSize: '16px', color: location.pathname === '/dashboard/settings' ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
            alt='settings' /><span style={{ color: location.pathname === '/dashboard/settings' ? '#fff' : '#353a44' }}>Settings</span></Element2>
        {/* <Element2 className={active === 'Help' ? "blueclass" : ''} ><Icon src={question} alt='settings' /><span>Help</span></Element2> */}

      </BottomTexts>
      {isModalopen && <Modal onClose={() => setModalOpen(false)} >
        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
          <h2>Add team member</h2>
          <CloseButton onClick={() => setModalOpen(false)}>
            <Icons src={cross} />
          </CloseButton>
        </div>
        <AddTeamMember
          onCancel={() => setModalOpen(false)}
        />
      </Modal>}
  
    </Main>
  )
}
export default LeftMenu

const Main = styled.div`
width:210px;
flex-shrink: 0;
overflow: auto; 
position:fixed;
background: #FCFBFD;
border-right:1px solid #F3F3F3;
color:black;

margin-top:-10px;

display:flex;
flex-direction:column;
padding-left:15px;
padding-right:15px;
padding-top:10px;
padding-bottom:10px;
height: 100vh;
overflow-y:hidden;

.blueclass{
background-color:${palette.newBlue};
font-weight:500;
&:hover {
  background:${palette.newBlue};
  }

}
`
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeef1;
  margin-left: -15px;
  margin-right: -15px;
  padding: 1.5px 18px;
  margin-bottom: 10px;
`;

const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Heading = styled.p`
font-size: 14px;
font-weight:600;
margin-bottom:-10px;
text-align:start;
white-space: nowrap; 
overflow: hidden;  
text-overflow: ellipsis;
max-width:180px;

`

const Element = styled.div`
font-size:13px;
line-spacing:1.5;
cursor:pointer;
display:flex;
justify-content:space-between;
font-weight:500;
align-items:center;
border-radius:8px;
padding-top:7.5px;
padding-bottom:7.5px;
padding-left:5px;

margin-bottom:2px;
&:hover {
  background:${palette.smokeWhite};
  }
`
const Element2 = styled.div`
font-size:13px;
line-spacing:1.5;
cursor:pointer;
display:flex;
justify-content:start;
font-weight:500;
align-items:center;
border-radius:8px;
padding-top:7.5px;
padding-bottom:7.5px;
padding-left:5px;

margin-bottom:5px;
&:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); 
  }
`
const BottomTexts = styled.div`
display:flex;
flex-direction:column;
width:210px;
position:absolute;
bottom:-10px;
background:#FCFBFD;

justify-content:flex-end;
padding-bottom:50px;

`
const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ebeef1;
  border-top: 1px solid #ebeef1;
  margin-left: -15px;
  margin-right: -30px;
  padding: 1.5px 10px;
  margin-bottom: 10px;
`;

const PrimaryNumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  margin-right: 0px;
  padding: 8px 8px;
  width: 260px;
  border-radius: 4px;
  background: #dce8f5;
`;
const IconWrapper = styled.div`



  display:flex;
  flex-direction:column;
justify-content:start;
`;

const StyledIconButton =  styled.div`

display: flex;
justify-content: center;
align-items: center;
width: 28px;
height: 28px;
background-color: #fff;
border-radius: 6px;
cursor: pointer;
margin-right:8px;
transition: background-color 0.3s, color 0.3s;

&:hover {
  background-color: #dcdcdc; 
}

svg {
  font-size: 17px;
  color: rgba(32, 32, 32, 0.6); 
  transition: color 0.3s;

  &:hover {
    color: black; 
  }
}
`;
const Icons = styled.img`
width: 20px;
height: 20px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:32px;
width:32px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:10px;
border-radius:25px;
font-weight:bold;
margin-left:-5px;
`
const Header = styled.p`
font-size:12px;
font-weight:500;
text-align:start;
color:gray;

`
const ProfilePic = styled.img`
background:#fff;
color:white;
height:auto;
width:32px;
aspect-ratio:4/4;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:25px;
font-weight:bold;
margin-left:-5px;

`
const CreateContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
font-size:14px;
font-weight:500;
margin-right:20px;
margin-left:20px;
cursor: pointer;
`