import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { Device } from '@twilio/voice-sdk';
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";


export const getChecklistItems = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getChecklist)
    return res
  }

  export const submitEditchecklist = (value) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.editChecklist}`,value)
    await dispatch(getUserDetails())
    return res
  }
  export const Deletechecklist = (id) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.deleteChecklist}?checkListNo=${id}`)
  await dispatch(getUserDetails())
 
  return res
}
export const createChecklist = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.createChecklist, data)
    return res
  }

  export const stripeSession = () => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.stripeSession)
    return res
  }

  export const getAllCustomField = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getAllCustomField)
    return res
  }

  export const deleteCustomField = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteCustomField}?customFieldId=${id}`)
    dispatch(getUserDetails())
    return res
  }

  export const editCustomField = (id,data) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.editCustomField}?customFieldId=${id}`,data)
    dispatch(getUserDetails())
    return res
  }

  export const getAllTags = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getAllTags)
    return res
  }

  export const deleteTag = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteTag}?tag=${id}`)
    dispatch(getUserDetails())
    return res
  }
  export const getAllTypePriority = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getAllTypePriority)
    return res
  }
  export const SubmitTypePriority = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.addTypePriority,data)
    return res
  }

  export const deleteTypePriority = (id,type) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteTypePriority}?tag=${id}&field=${type}`)
    dispatch(getUserDetails())
    return res
  }

  export const deletecontactStatus = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteContatctStatus}?value=${id}`)
    dispatch(getUserDetails())
    return res
  }
  export const getAllContactStatus = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getAllContatctStatus)
    return res
  }
  export const SubmitContactStatus = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.createContatctStatus,data)
    return res
  }

  export const createSubscription = (id,contactId,data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.createSubscription}?priceId=${id}&contactId=${contactId}`,data)

    return res
  }
  export const deleteServicePlan = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteServicePlan}?productId=${id}`)
    return res
  }

  export const createCallTree = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.createCallTree}`,data)

    return res
  }
  export const deleteCallTree = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteCaTree}?callTreeId=${id}`)
    return res
  }
  export const getCallTree = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getCallTree)
    return res
  }
  export const updateCallTree = (id,data) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.updateCallTree}?callTreeId=${id}`,data)
    return res
  }

  export const updateAutomation = (id,data) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.updateAutotimation}?automationId=${id}`,data)
    return res
  }
  