import NetworkOps from "../../../services/NetworkOps";
import { ServiceEnum } from "../../../services/Urls";
import Constants from "../../../utils/Constant";

export const genAction = (type, payload) => ({ type, payload })


export const createTerritory =  (data) => async(dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.createTerritory, data)
    return res

}
export const updateTerritory =  (data) => async(dispatch, getstate) => {
    console.log(data,"fdvdfv")
    const res = await NetworkOps.put(`${ServiceEnum.updateTerritory}?territoryId=${data?.territoryId}`, data)
    return res

}


export const getTerritory = (data) =>async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getTerritory)
    dispatch(genAction(Constants.TYPE.TerritoryData, res?.data))
    return res

}
export const deleteTerritory =  (id) => async(dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteTerritory}?territoryId=${id}`)
    return res

}
export const getContactMapData = (data) =>async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.getContactMapData)
   
    return res

}