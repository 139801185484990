import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
 
`;

const TabButton = styled.button`
  flex: 1;
  padding: 8px 20px;
  border: none;
  border-radius: ${({ isLeft }) => (isLeft ? '8px' : '8px')};
  background-color: ${({ isActive, activeTabBackgroundColor }) =>
    isActive ? activeTabBackgroundColor : '#eee'};
  color: ${({ isActive, activeTabColor }) =>
    isActive ? activeTabColor : 'rgba(32, 32, 32, 0.85)'};
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
 

  &:hover {
    background-color: ${({ isActive, activeTabBackgroundColor }) =>
      isActive ? activeTabBackgroundColor : '#eee'};
  }
`;

const ToggleTabs = ({ labels, onToggle, activeTabColor, activeTabBackgroundColor,selected }) => {
  const [activeTab, setActiveTab] = useState(selected?selected: labels[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
    if (onToggle) {
      onToggle(tab);
    }
  };

  return (
      <div style={{background:'#eee',padding:'3.5px',borderRadius:'8px'}}>
    <TabContainer>
      {labels.map((label, index) => (
        <TabButton
          key={label}
          isLeft={index === 0}
          isActive={activeTab === label}
          activeTabBackgroundColor={activeTabBackgroundColor}
          activeTabColor={activeTabColor}
          onClick={() => handleClick(label)}
        >
          {label}
        </TabButton>
      ))}
    </TabContainer>
    </div>
  );
};

ToggleTabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggle: PropTypes.func,
  activeTabColor: PropTypes.string,
  activeTabBackgroundColor: PropTypes.string,
  selected:PropTypes.string
};

ToggleTabs.defaultProps = {
  onToggle: null,
  activeTabColor: 'black',
  activeTabBackgroundColor: 'white', // Default background color for active tab
};

export default ToggleTabs;
