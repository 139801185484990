import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, IconButton, Tooltip, CircularProgress, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getBookings, getCalendarDataV2 } from '../../store/Actions/dashboard.action';
import { calculateTotalTaxAmount, putCommaInvalue, totalprice } from '../../utils/Commonfunction';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../utils/Palette';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useSelector } from 'react-redux';
import { FiArrowRight } from 'react-icons/fi';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const jobs = [
  { id: 1, title: "South Beach Contractors - Job 240 / 5060 La Jolla Blvd / Painting & Drywall", time: "8:00 AM - 4:30 PM", statusColor: "#4CAF50", team: ["SLE", "CM"], price: null },
  { id: 2, title: "Mark Nelson - Job 470 / 4157 Arden Way / Exterior Painting", time: "8:00 AM - 4:30 PM", statusColor: "#4CAF50", team: ["JC", "JJM"], price: "$11.3k" },
  { id: 3, title: "Lynn Lejcher - Job 430 / 16048 Oxford Hill / Interior Painting", time: "8:00 AM - 4:30 PM", statusColor: "#4CAF50", team: ["JE", "NG"], price: null },
  { id: 4, title: "Maureen and Mike Bledsoe Jennings - Job 473 / 7555 Delfina / Exterior Painting", time: "8:00 AM - 4:30 PM", statusColor: "#4CAF50", team: ["JE", "NG"], price: null },
  { id: 5, title: "Conni Arrellano-Musser - Paint Estimate", time: "8:45 AM - 9:45 AM", statusColor: "#FF5722", team: ["BM"], price: null },
];

const JobList = () => {
  const [expanded, setExpanded] = useState(false);
  const detail = useSelector(state => state?.auth?.userDetails)
  const [jobs, setJobs] = useState([]);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [currentDate, setCurrentDate] = useState(new Date());
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const itemsToShow = expanded ? jobs?.length : 4;
  const getStartAndEndOfWeek = (date) => {
    const startOfWeek = moment(date).startOf('week').subtract(1, 'days').format('YYYY-MM-DD');
    const endOfWeek = moment(date).endOf('week').add(1, 'days').format('YYYY-MM-DD');
    return { startOfWeek, endOfWeek };
  };

  const init = async (date) => {
    setLoading(true);
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);
    const res1 = await dispatch(getCalendarDataV2(startOfWeek, endOfWeek))
    const data = [...res1?.data?.detailData]
    const filterData = data.filter(item => moment(item?.date).format('DD-MMM YYYY') === moment(date).format('DD-MMM YYYY'))
    setJobs(filterData)
    setLoading(false);
  }
  useEffect(() => {
    init(new Date())
  }, [])

  const getName = (channel) => {
    if (!channel) {
      return;
    }
    // Trim white spaces from the beginning and end of the channel
    const trimmedChannel = channel.trim();
    const val = trimmedChannel.split(' ');

    if (val.length === 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
    } else {
      return `${val[0]?.charAt(0).toUpperCase()}`;
    }
  };

  const flattenItems = (items) => {
    return items.reduce((acc, item) => {
      if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
        acc.push(...item.lineItems);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const calculateTotalAmount = (items) => {
    const flattenedItems = flattenItems(items);
    return `${([...flattenedItems].reduce(function (accumulator, currentValue) {
      return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
    }, 0) + calculateTotalTaxAmount([...flattenedItems], 0))?.toFixed(2)}`
  };
  const changeDate = (direction) => {
    const newDate = new Date(currentDate); 
    if (direction === "left") {
      newDate.setDate(currentDate.getDate() - 1); 
    } else if (direction === "right") {
      newDate.setDate(currentDate.getDate() + 1);
    }

    setCurrentDate(newDate); 
    init(newDate)
  };

  return (
    <>
         <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <h3 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'start', marginLeft: '5px' }}>Appointments</h3>
      <div style={{display:'flex',alignItems:'center'}}>
      <JobTitle style={{marginRight:'10px'}}>{moment(currentDate).format("DD MMM YYYY")}</JobTitle>
      <StyledIconBox onClick={() => changeDate("left")}>
        <KeyboardArrowLeft/>
      </StyledIconBox>
      <StyledIconBox onClick={() => changeDate("right")}>
        <KeyboardArrowRight/>
      </StyledIconBox>
      </div>
      </div>
      {loading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : jobs?.length === 0 ? (
        <Container style={{ display: 'flex', alignItems: 'center',height:'85%', justifyContent: 'center' }}>
          <NoAppointments>
            <EventBusyIcon style={{ fontSize: 50, color: palette.grey }} />
            <NoAppointmentsText>No Scheduled Jobs Yet</NoAppointmentsText>
            <Typography variant="body2" align="center" color="textSecondary">
            Any jobs you schedule will be displayed here for easy tracking.
            </Typography>
          </NoAppointments>
        </Container>
      ) : (
        <Container>
          {
            jobs?.slice(0, itemsToShow)?.map((job) => (
              <JobCard onClick={() =>{ 
                if(job?.type === "task" ||job?.type === "event" ){

                }
                else{
                navigate(`/dashboard/servicerequests/${job?.referenceNo}`)
                }
              }} key={job._id}>
                <Tooltip componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#fff',
                      color: '#000',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                      border: '1px solid #ddd',
                      fontSize: '14px',
                      fontWeight: '600',
                      padding: '8px'

                    },
                  },
                  arrow: {
                    sx: {
                      color: '#fff',
                    },
                  },
                }} arrow title={<div style={{ background: 'white', color: 'black', width: '100%' }}>{job?.type === 'task'
                  ? 'Task'
                  : job?.type === 'event'
                    ? 'Event'
                    : job?.bookingStatus === 'Waiting for confirmation' ? 'Open' : job?.bookingStatus}</div>} placement='top'>
                  <StatusIndicator color={job?.type === "task" ? "red" : job?.type === "event" ? 'green' : job?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : job?.bookingStatus === 'In Progress' ? '#29740f' : job?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f'} />
                </Tooltip>
                <JobInfo>
                  <JobTitle>{job?.name || job?.to || job?.title} </JobTitle>
                  <JobTime>{job?.customerAddress || job?.task || job?.description}</JobTime>
                  <JobTime> {`${moment(job?.date).format('DD MMM')} ${moment(job?.time)?.format('hh:mm A')} - ${moment(job?.endDate).format('DD MMM')} ${moment(job?.endTime)?.format('hh:mm A')}`}</JobTime>
                </JobInfo>
                <div>
                  <IconGroup>
                    {job?.team?.length > 0 ? job?.team?.map((member) => (
                      <Tooltip componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            fontWeight: '600',
                            padding: '8px'

                          },
                        },
                        arrow: {
                          sx: {
                            color: '#fff',
                          },
                        },
                      }} arrow title={<div style={{ background: 'white', color: 'black', width: '100%' }}>{member?.firstName}</div>} placement='top'>
                        <StyledAvatar sx={{ background: palette.blue }} key={member?.firstName}>{getName(member?.firstName)}</StyledAvatar>
                      </Tooltip>

                    )) : <Tooltip componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#fff',
                          color: '#000',
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                          border: '1px solid #ddd',
                          fontSize: '14px',
                          fontWeight: '500',
                          padding: '8px'

                        },
                      },
                      arrow: {
                        sx: {
                          color: '#fff',
                        },
                      },
                    }} arrow title={<div style={{ background: 'white', color: 'black', width: '100%' }}>{job?.name}</div>} placement='top'>
                      <StyledAvatar sx={{ background: palette.blue }} key={detail?.firstName}>{getName(job?.name)}</StyledAvatar>
                    </Tooltip>}
                  </IconGroup>
                  {/* {job?.items?.length > 0 && <PriceTag>${putCommaInvalue(calculateTotalAmount(job?.items))}</PriceTag>} */}
                </div>

              </JobCard>
            ))
          }

          {jobs.length > 4 && (
            <ShowMore onClick={toggleExpand}>
              {expanded ? "Show less" : `See ${jobs.length - 4} more visit(s)`}
              <IconButton>
                <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0)' }} />
              </IconButton>
            </ShowMore>
          )}

        </Container>
      )
      }
    </>
  )
};

export default JobList;
const Container = styled.div`
  width: 100%;
  max-width: 98%;
  border-radius: 8px;
  margin-top:15px;
  margin-bottom:25px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc; 
  }

  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.6); 
    transition: color 0.3s;

    &:hover {
      color: black; 
    }
  }
`;

const JobCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor:pointer;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StatusIndicator = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color || '#ccc'};
  margin-right: 16px;
  border-radius: 4px;

  @media (max-width: 768px) {
    height: 10px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const JobInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width:75%;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const JobTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align:start;
 

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const JobTime = styled.div`
  font-size: 13px;
  text-align:start;
  color: #666;
  margin-Top: 4px;
  white-space: nowrap; 
  overflow: hidden;  
  text-overflow: ellipsis;
  width:80%;
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 8px;
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.newBlue};
    font-size: 13px;
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;
const PriceTag = styled.div`
  font-size: 14px;
  font-weight:600;
  color: #666;
 
  text-align:end;
  margin-right:10px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 4px;
  }
`;

const ShowMore = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #0073e6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:50px;
  padding: 30px;
`;
const NoAppointments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  color: ${palette.grey};
`;

const NoAppointmentsText = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
  color: ${palette.grey};
`;