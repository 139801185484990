import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from '../../../../utils/Palette';
import { FaExclamationCircle } from 'react-icons/fa';
import { formatMobileNumber } from '../../../../utils/Commonfunction';
import { CircularProgress, Tooltip } from '@mui/material';
import EventDetails from './EventCard';
import { deleteAddEvent, deleteAddTask, getBookingsV2, getCalendarDataV2 } from '../../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;


const Card = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  borderLeft:6px solid ${palette.newBlue};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-left:20px;
  cursor:pointer;
  margin-bottom:5px;
  max-width:170px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
`;

const Title = styled.span`
  color: ${palette.newBlue};
  font-size: 13px;
  text-align: start;
  white-space: nowrap; 
    overflow: hidden;  
    text-overflow: ellipsis;
    width:80%;
`;

const Number = styled.span`
  color: rgba(32, 32, 32, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  
`;

const Address = styled.div`
  color: grey;
  font-size: 12px;
  text-align: start;
  p{
    white-space: nowrap; 
    overflow: hidden;  
    text-overflow: ellipsis;
    width:87%;
  }
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f8c8d;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:50px;
  padding: 30px;
`;

const CalendarUnscheduledJob = (props) => {
    const { data, onclick, type, isRoute } = props
    const [loader, setLoader] = useState(false)
    const [tabData, setTabData] = useState([])
    const dispatch = useDispatch()

    const init = async () => {
        setLoader(true);


        let res = await dispatch(getBookingsV2());
        if (res.status === true) {
            const data = [...res.data?.present, ...res.data?.past]?.filter(items => !items?.date);
            console.log(data, "csdsnkj")
            const promises =
                await Promise.all(
                    data.map((item, index) => {
                        return {
                            data: item
                        };

                    })
                )
            const filteredData = promises.filter(item => item !== null);

            setTabData(filteredData)

            setLoader(false);

        }
    };

    useEffect(() => {
        init()
    }, [])
    useEffect(() => {
        if(props?.onDrop){
        init()
        }
    }, [props?.onDrop])
    return (


        <Container>
            {loader? <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
        : 
        tabData?.map((item, index) => (

                <Card style={{ borderLeft: item?.type === 'task' ? `8px solid red` : item?.type === 'event' ? `8px solid red` : item?.type === 'multiVisit' ? `8px solid grey` : `8px solid ${palette.newBlue}` }} key={item.id} onClick={() => onclick(item)} draggable
                    onDragStart={(e) =>
                        props?.onDragStart(item?.data)
                    }
                    onDragEnd={(e) => {
                        init()
                    }}
                >
                    <CardHeader>
                        <Title>{item?.data?.name || item?.data?.customerNumber || item?.item?.name}</Title>
                        {item?.data['job number'] && <Number>#{item?.data['job number']}</Number>}
                    </CardHeader>
                    <Address>
                        <p style={{ marginTop: '6px', marginBottom: '5px' }}>{formatMobileNumber(item?.data?.customerNumber || item?.item?.customerNumber)}</p>
                        <p style={{ marginTop: '6px', marginBottom: '5px' }}>{item?.data?.customerAddress || item?.item?.customerAddress}</p>
                    </Address>
                </Card>

            ))}
            {
                (tabData?.length > 0||loader) ? null : <Container>
                    <NoDataMessage>
                        <IconWrapper>
                            <FaExclamationCircle />
                        </IconWrapper>
                        No jobs
                    </NoDataMessage>
                </Container>
            }

        </Container>

    );
};

export default CalendarUnscheduledJob;
