import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Menu, MenuItem, TextField, InputAdornment, IconButton } from '@mui/material';
import { FiCalendar, FiHelpCircle, FiMessageCircle } from 'react-icons/fi';
import { ArrowDropDown, CalendarMonth, SearchOutlined, Support } from '@mui/icons-material';
import styled from 'styled-components';
import { useState } from 'react';
import { palette } from '../../utils/Palette';
import JobList from '../../Pages/Reports/AppointmentJob';
import RightModal from '../RightSideModal';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebeef1;
  padding: 15px 0px;
  padding-top:8px;
  padding-bottom:7.5px;

  width: 100%;
 
  background-color: #fff;
`;

const SearchContainer = styled.div`
  flex: 1;
`;

const RightIconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right:9px;

 
`;

const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 32px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc; 
  }

  svg {
    font-size: 18px;
    color: rgba(32, 32, 32, 0.6); 
    transition: color 0.3s;

    &:hover {
      color: black; 
    }
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 180px;
    overflow: visible;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 12px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 14px;
      width: 10px;
      height: 10px;
      background: #fff;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;

const ScrollableMenu = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
const CustomTextField = styled(TextField)`
&& {
    border-radius: 8px;
    border: none; 
    padding: 5px;
    margin-left:12px;

    .MuiOutlinedInput-root {
      & fieldset {
        border: none; 
      }
      &:hover fieldset {
        border: none; 
      }
      &.Mui-focused fieldset {
        border: 1px solid black; 
      }
    }
  }

`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;


const Header = ({
    searchComponent,
   onChangeSearch,
   value,
   nonsearchable
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openAssign, setOpenAssign] = useState(false);
    const [showJob, setShowJob] = useState(false);

    const handleAssignClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenAssign(true);
    };

    const handleCloseAssign = () => {
        setAnchorEl(null);
        setOpenAssign(false);
    };
    return (
        <>
        <HeaderContainer style={{justifyContent:nonsearchable&&'flex-end',padding:nonsearchable&&'15px 10px' }}>
         {!nonsearchable&&   <CustomTextField
                placeholder="Search"
                size="small"
                value={value}
                onChange={(e) => onChangeSearch(e.target.value)}
                InputProps={{
                    style: {
                        paddingLeft: '10px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        width: '450px',

                        background: '#f3f3f3',

                    },
                    startAdornment: (
                        <CustomInputAdornment position="start">
                            <CustomIconButton>
                                <SearchOutlined style={{ fontSize: '16px' }} />
                            </CustomIconButton>
                        </CustomInputAdornment>
                    ),

                }}
            />}

            <RightIconsContainer style={{marginRight:nonsearchable&&'19px'}}>
                <Tooltip arrow title="Show jobs">
                    <StyledIconBox onClick={() => setShowJob(true)}>
                        <FiCalendar />
                    </StyledIconBox>
                </Tooltip>
                <StyledIconBox onClick={handleAssignClick}>
                    <FiHelpCircle />
                    <ArrowDropDown />
                </StyledIconBox>
                <StyledMenu
                    anchorEl={anchorEl}
                    open={openAssign}
                    onClose={handleCloseAssign}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <ScrollableMenu>
                        <MenuItem
                            style={{ color: palette.black, fontSize: '13px' }}
                            onClick={() => (window.location.href = 'mailto:contact@getnotifi.com')}
                        >
                            <FiMessageCircle style={{ fontSize: '16px', marginRight: '10px' }} />
                            Contact Support
                        </MenuItem>
                        <MenuItem
                            style={{ color: palette.black, fontSize: '13px' }}
                            onClick={() => (window.location.href = 'mailto:contact@getnotifi.com')}
                        >
                            <Support style={{ fontSize: '16px', marginRight: '10px' }} />
                            Support Centre
                        </MenuItem>
                        <MenuItem
                            style={{ color: palette.black, fontSize: '13px' }}
                            onClick={() => window.open('https://www.getnotifi.com/contact-us')}
                        >
                            <CalendarMonth style={{ fontSize: '16px', marginRight: '10px' }} />
                            Request a Demo
                        </MenuItem>
                    </ScrollableMenu>
                </StyledMenu>
            </RightIconsContainer>
           
          
        </HeaderContainer>
        {showJob && <RightModal
                customStyle={{ width: '30%' }}
                isOpen={showJob}
                onClose={() => {
                    setShowJob(false)
                }}
            >
                <div style={{ height: '100%', paddingTop: '25px' }}>
                    <JobList />
                </div>
            </RightModal>}
        </>
    );
};


Header.propTypes = {
    searchComponent: PropTypes.node, 
    handleShowJobs: PropTypes.func.isRequired, 
    handleAssignClick: PropTypes.func.isRequired, 
    anchorEl: PropTypes.object, 
    openAssign: PropTypes.bool.isRequired,
    onCloseAssign: PropTypes.func.isRequired, 
    nonsearchable:PropTypes.bool.isRequired, 
};

export default Header;
