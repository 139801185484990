import { Cancel } from "@mui/icons-material";
import React from "react";
import { FiTrash2 } from "react-icons/fi";
import PhoneInput from 'react-phone-number-input'
import styled from "styled-components";
import { palette } from "../../../utils/Palette";
import 'react-phone-number-input/style.css'
import { useState } from "react";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { useDispatch } from "react-redux";
import { createCallTree, updateCallTree } from "../../../store/Actions/SettingsAction";


const AddCallTreeDetails = (props) => {
    const [menuOptions, setMenuOptions] = useState(props?.data?.actions || [
        { digit: "1", label: "", target: "", actionType: 'dial' },
    ]);
    const [greetingMessage, setGreetingMessage] = useState(props?.data?.greetingMessage || '')
    const [error,setError]=useState(false)
    const dispatch = useDispatch()

    const handleAddOption = () => {
        if (menuOptions.length < 9) {
            const newKey = findAvailableKey();
            setMenuOptions((prev) => [
                ...prev,
                { digit: newKey, label: "", target: "", actionType: 'dial' },
            ]);
        } else {
            alert("You cannot add more than 9 options.");
        }
    };

    const findAvailableKey = () => {
        const usedKeys = menuOptions.map((option) => option.digit);
        for (let i = 1; i <= 9; i++) {
            if (!usedKeys.includes(i.toString())) {
                return i.toString();
            }
        }
        return null;
    };

    const handleRemoveOption = (key) => {
        setMenuOptions((prev) => prev.filter((option) => option.digit !== key));
    };

    const handleInputChange = (key, field, value) => {
        setMenuOptions((prev) =>
            prev.map((option) =>
                option.digit === key ? { ...option, [field]: value } : option
            )
        );
    };

    const onPress = async () => {
        if (props?.data?._id) {
            const res = await dispatch(updateCallTree(props?.data?._id,{
                "name": "Calltree",
                "greetingMessage": greetingMessage,
                actions: menuOptions
            }))
            if(res.status===true){
                props?.onSubmit()
            }
            else{
                setError(res?.message)
            }
        }
        else {
            const res = await dispatch(createCallTree({
                "name": "Calltree",
                "greetingMessage": greetingMessage,
                actions: menuOptions
            }))
            if(res.status===true){
                props?.onSubmit()
            }
            else{
                setError(res?.message)
            }
        }
       
    }

    return (
        <Container>
            <HeaderContainer>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CloseButton onClick={() => props?.onClose()}>
                        <Cancel />
                    </CloseButton>
                    <h4>{props?.data?._id ? "Edit Menu" : ` Phone Menu`}</h4>
                </div>
                <RippleButton onClick={() => onPress()}>Save</RippleButton>
            </HeaderContainer>

            <Section>
                <Subtitle>Greeting Message</Subtitle>
                <SubExample>
                    Example: Hi! Thanks for calling. For sales, press 1. For
                    support, press 2 .
                </SubExample>
                <InputText value={greetingMessage} onChange={(e) => setGreetingMessage(e.target.value)} placeholder="Write your message" />
            </Section>

            <Section>
                <Subtitle>Menu Options</Subtitle>
                <SubExample>
                    Options are triggered by keypad and can route to phone numbers.
                </SubExample>

                {menuOptions.map((option, index) => (
                    <div
                        key={option.key}
                        style={{
                            padding: "8px",
                            borderRadius: "8px",
                            border: "1px solid #eee",
                            marginBottom: "10px",
                        }}
                    >
                        <InputGroup>
                            <SelectDrpoDown
                                value={option.key}
                                onChange={(e) =>
                                    handleInputChange(option.digit, "digit", e.target.value)
                                }
                            >
                                {[...Array(9).keys()]
                                    .map((i) => (i + 1).toString())
                                    .filter(
                                        (k) =>
                                            k === option.digit ||
                                            !menuOptions.some((opt) => opt.digit === k)
                                    )
                                    .map((k) => (
                                        <option key={k} value={k}>
                                            {k}
                                        </option>
                                    ))}
                            </SelectDrpoDown>
                            <input
                                type="text"
                                placeholder="Command"
                                value={option.label}
                                onChange={(e) =>
                                    handleInputChange(option.digit, "label", e.target.value)
                                }
                            />
                            <FiTrash2
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => handleRemoveOption(option.digit)}
                            />
                        </InputGroup>
                        <div className='phoneInput' style={{ width: '93%',marginLeft:'-1px' }}>
                            <PhoneFiled
                                type="tel"
                                placeholder="Enter phone number"
                                defaultCountry="US"
                                countryCallingCodeEditable={false}
                                value={option.target}
                                onChange={(value) =>
                                    handleInputChange(option.digit, "target", value)
                                }
                                international
                                tabIndex="0"
                            />
                        </div>
                    </div>
                ))}

                <AddOption onClick={handleAddOption}>+ another option</AddOption>

                {
                    error ? <div>
                        <br />
                        <p style={{ fontSize: '14px', color: '#D81159',textAlign:'start' }}>{error}</p>
                    </div> : null
                }
            </Section>
        </Container>
    );
};

export default AddCallTreeDetails;


const Container = styled.div`
width: 100%;
background-color: #f9f9f9;
border-radius: 10px;
color: #333;
`;

const SubExample = styled.p`
margin:0;
margin-top: 5px;
margin-bottom:5px;
font-size:12px;
color:grey;
font-weight: 400;
text-align:start;
`

const Section = styled.div`
margin-top: 20px;
`;

const Subtitle = styled.h4`
margin-bottom: 10px;
font-weight: 500;
text-align:start;
`;

const InputGroup = styled.div`
display: flex;
align-items: center;
margin-bottom: 15px;
gap: 15px;



input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: #fff;
  color: #333;

  &:focus {
    outline: none;
    border: 1px solid #000;
  }
}
.select{

  flex: 1;
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  width:150px;

  &:focus {
    outline: none;
    border: 1px solid #4caf50;
  }
}

button {
  background: #ff5c5c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #ff7878;
  }
}
`;

const AddOption = styled.button`
border: 1px solid #0F52BA;
margin-top: 20px;

justify-content: center;
display: flex;
padding: 7px 12px;
border-radius: 20px;
cursor: pointer;
background:#fff;
transition: background-color 0.3s ease, box-shadow 0.3s ease;
color: #0F52BA;
&:hover {
background-color: rgba(15, 82, 186, 0.1);
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
`;

const DefaultAction = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 20px;

select {
  width: auto;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  font-size: 0.9rem;
}
`;
const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const CloseButton = styled.button`
background: none;
border: none;
cursor: pointer;
`;
const InputText = styled.textarea`
font-family: Inter;
height: 40px;
font-size: 14px;
padding: 10px;
word-wrap: break-word;
width:95%;
display: block;
border-radius: 5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
resize: vertical; 
::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}
&:focus {
  outline: none;
  border: 1px solid #000;
}
@media (min-width: 260px) and (max-width: 721px) {
  width: 90%;
}
`;
const PhoneFiled = styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;

.PhoneInputCountry{
  border: 0.5px solid #ddd;
  height:41px;
}
.PhoneInputInput{
  height:25px;
 
  border: ${(props) => props.error ? '1px' : '0.5px'} solid ${(props) => props.error ? 'red' : '#ddd'};
  &:focus {
      outline: none;
      border: 1px solid ${palette.charcoleBlack};
    
    }
}

`
const SelectDrpoDown = styled.select`
padding: 10.5px;
font-size: 13px;
width:19%;
border: 1px solid #ddd;
border-radius: 4px;
appearance: none;

color: #344054;
background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
background-size: 12px 12px;

&:focus {
  outline: none;
  border-color: #000;
  box-shadow: none;
}
`;
