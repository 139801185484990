export const teamReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_TEAM_MEMBER':
            if (state.selectedTeam.some(member => member.email === action.payload.email)) {
                return {
                    ...state,
                    selectedTeam: state.selectedTeam.filter(member => member.email !== action.payload.email)
                };
            }
            return {
                ...state,
                selectedTeam: [...state.selectedTeam, action.payload]
            };

            case 'TOGGLE_TYPE':
                if (state.selectedType.some(member => member.name === action.payload.name)) {
                    return {
                        ...state,
                        selectedType: state.selectedType.filter(member => member.name !== action.payload.name)
                    };
                }
                return {
                    ...state,
                    selectedType: [...state.selectedType, action.payload]
                };  

        case 'CLEAR_FILTER':
          
            return {
                ...state,
                selectedTeam: [],
                selectedType:[]
            };

        case 'TOGGLE_SHOW_MODAL':
            return {
                ...state,
                isShowModal: !state.isShowModal
            };

        default:
            return state;
    }
};