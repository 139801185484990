import './App.css';
import React, { Suspense, lazy, useEffect, useCallback, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import GoogleLog from './Pages/GoogleLog';
import Quote from './Pages/SendQuotee';
import Invoice from './Pages/SendInvoice';
import SendInvoice from './Pages/Invoice';
import SendQuotee from './Pages/Quote';
import Chatbot from './Pages/ChatBot';
import RecieptReport from './Pages/Reciept';
import LeftMenu from './Components/Dashboard/LeftMenu';
import CustomerRequestForm from './Components/Dashboard/CustomerRequestForm';
import ResponsePage from './Components/Dashboard/ResponsePage';
import CustomerOnlineRequestForm from './Components/Dashboard/CustomerOnlineForm';
import LeadTable from './Components/Dashboard/Contacts/LeadTable';
import MissionControl from './Components/Dashboard/Missioncontrol';
import Reports from './Pages/Reports';
import ContactList from './Components/Dashboard/Contacts/ContactTable';
import Bookings from './Components/Dashboard/Bookings/TimeSchedule';
import Estimate from './Components/Dashboard/Payments/Estimates';
import Pricebook from './Components/Dashboard/Payments/ProductCatalogue';
import EstimatePayments from './Components/Dashboard/Payments';
import ServiceRequestList from './Components/Dashboard/Payments/ServiceRequestList';
import RequestFormList from './Pages/Forms';
import CrewMember from './Pages/CrewMember';
import Settings from './Components/Settings';
import IncomingUI from './Components/Dashboard/IncomingUI';
import { useSelector } from 'react-redux';
import CallingUI from './Components/Dashboard/CallingUI';
import Map from './Pages/unScheduledJobs';
import MyTIme from './Components/Dashboard/Bookings/NewTime';
import CallLogs from './Components/Dashboard/Contacts/CallLogs';
import ContactDetails from './Components/Dashboard/Contacts/ContactDetails';
import JobDetails from './Components/Dashboard/JobsDetails';
import MapView from './Components/Dashboard/Bookings/MapView';
// import UploadData from './Pages/Dataupload';
import JsonUpload from './Pages/DataUpload/JSONupload';
// import UploadData from './Pages/Dataupload';
// import { sendNativeNotification, toastNotification } from './notificationHelpers';
// import {messaging, setupNotifications} from './firebase'
// import useVisibilityChange from './useVisibilityChange';
// import { Alert, Snackbar } from '@mui/material';
// import { palette } from './utils/Palette';
// import { Cancel, CancelOutlined } from '@mui/icons-material';
// import incoming from "./assets/incoming.wav";
import StripeSetting from './Pages/Stripe';
import AccessDeniedPage from './Pages/AccessDenied/AccessDenied';
import MapContactView from './Pages/MapContacts/MapContactView';
const Payment = lazy(() => import('./Pages/Payment'));
const GoogleBusiness = lazy(() => import('./Pages/GoogleBusiness'));
// const Chats=lazy(()=>import('./Pages/Chats'));
const Inbox = lazy(() => import('./Pages/Inbox'));
const Conatcts = lazy(() => import('./Pages/Contacts'));
const Reviews = lazy(() => import('./Pages/Reviews'));
const Builder = lazy(() => import('./Pages/Builder'))
const SignUpPage = lazy(() => import('./Pages/SignUpPage'));
const Tracking = lazy(() => import('./Pages/Tracking'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Signed = lazy(() => import('./Pages/Signed'));
const Review = lazy(() => import('./Pages/Review'));

//  const Header =lazy(()=>import("./Header"));
// const Footer =lazy(()=>import("./Footer"));
// const FooterBottom =lazy(()=>import("./FooterBottom"));
const Login = lazy(() => import("./Pages/Login"));

function App() {
  const loading = () => (
    <p className="animated fadeIn pt-3 text-center" >...loading</p>
  );
  // Get the current location using useLocation()

  return (
    <div className="App">
      <Suspense fallback={loading()}
      >
        <BrowserRouter>

          <AppContext />
          {/* {window.location.pathname==='/login'||window.location.pathname==='/'?
       '':<Header/>} */}

          {/* {window.location.pathname==='/login'||window.location.pathname==='/'?
      ''
      :<>
      <Footer/>
      <FooterBottom/>
      </>} */}
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

const AppContext = () => {
  const location = useLocation();
  const incomingcallShow = useSelector(state => state?.auth?.showIncomingCall)
  const callShow = useSelector(state => state?.auth?.showCall)
  const connection = useSelector(state => state?.auth?.connection)
  const [notification, setNotification] = useState(null);
  // useEffect(useCallback(() => {
  //   const intercomSettings = {
  //     api_base: "https://api-iam.intercom.io",
  //     app_id: "xkray6a0"
  //   };

  //   window.intercomSettings = intercomSettings;

  //   (function () {
  //     var w = window;
  //     var ic = w.Intercom;
  //     if (typeof ic === "function") {
  //       ic('reattach_activator');
  //       ic('update', w.intercomSettings);
  //     } else {
  //       var d = document;
  //       var i = function () { i.c(arguments); };
  //       i.q = []; i.c = function (args) { i.q.push(args); };
  //       w.Intercom = i;
  //       var l = function () {
  //         var s = d.createElement('script');
  //         s.type = 'text/javascript';
  //         s.async = true;
  //         s.src = 'https://widget.intercom.io/widget/xkray6a0';
  //         var x = d.getElementsByTagName('script')[0];
  //         x.parentNode.insertBefore(s, x);
  //       };
  //       if (document.readyState === 'complete') {
  //         l();
  //       } else if (w.attachEvent) {
  //         w.attachEvent('onload', l);
  //       } else {
  //         w.addEventListener('load', l, false);
  //       }
  //     }
  //   })();
  // }, []));

  // Check if the current path starts with '/dashboard'
  // const isForeground = useVisibilityChange();
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  // useEffect(() => {

  //   setupNotifications((message) => {
  //     console.log("hello",message)
  //     if (isForeground) {
  //       // App is in the foreground, show toast notification
  //       toastNotification({
  //        title:message.title,
  //         description:message.body,
  //         status: "info",


  //       });
  //       setNotification({
  //         title: message.title,
  //         body: message.body,
  //       });
  //       console.log("hello")
  //       const audio = new Audio(incoming);
  //       audio.play()
  //     } else {
  //       // App is in the background, show native notification
  //       sendNativeNotification({
  //        title: message.title,
  //         body:message.body,
  //       });
  //       setNotification({
  //         title: message.title,
  //         body: message.body,
  //       });
  //       const audio = new Audio(incoming);
  //       audio.play()
  //       console.log("hello")
  //     }
  //   });
  // }, [isForeground]);

  return (
    <>
      {isDashboardRoute && <LeftMenu />}

      <Routes>
        <Route path="/blog" element={<Builder />} />
        <Route element={<ProtectedRoute />}>
          {/* <Route path='/dashboard' element={<Dashboard/>}></Route> */}
          <Route path='dashboard/home' element={<Reports />}></Route>
          <Route path='dashboard/inbox' element={<Inbox />}></Route>
          <Route path='dashboard/leads' element={<LeadTable />}></Route>
          <Route path='dashboard/missioncontrol' element={<MissionControl />}></Route>
          <Route path='dashboard/contacts' element={<ContactList />}></Route>
          <Route path='dashboard/contacts/:number' element={<ContactDetails />}></Route>
          <Route path='dashboard/leads/:number' element={<ContactDetails />}></Route>
          <Route path='dashboard/calllogs' element={<CallLogs />}></Route>
          <Route path='dashboard/calander' element={<Bookings />}></Route>
          <Route path='dashboard/calander/:id' element={<JobDetails />}></Route>
          <Route path='dashboard/timeline' element={<MyTIme />}></Route>
          <Route path='dashboard/calander/map' element={<MapView />}></Route>
          {(localStorage.getItem('number') === '16043326202' || localStorage.getItem('number') === '12086688040') && (
            <Route path='dashboard/mapcontact' element={<MapContactView />} />
          )}
          <Route path='dashboard/quotes' element={<Estimate />}></Route>
          <Route path='dashboard/pricebook' element={<Pricebook />}></Route>
          <Route path='dashboard/invoices' element={<EstimatePayments />}></Route>
          <Route path='dashboard/servicerequests' element={<ServiceRequestList />}></Route>
          <Route path='dashboard/servicerequests/:id' element={<JobDetails />}></Route>
          <Route path='dashboard/forms' element={<RequestFormList />}></Route>
          <Route path='dashboard/crewmember' element={<CrewMember />}></Route>
          <Route path='dashboard/settings/:section?' element={<Settings />}></Route>
          <Route path='dashboard/payments' element={<StripeSetting />}></Route>
          <Route path='unscheduled' element={<Map />}></Route>
          <Route path='json' element={<JsonUpload />}></Route>
          {/* <Route path='dataupload' element={<UploadData/>}></Route>  */}
          <Route path='dashboard/chatbot' element={<Chatbot />}></Route>
          <Route path='dashboard/reviews' element={<Reviews />}></Route>
          <Route path='/payment/:id/:id2/:priceid' element={<Payment />}></Route>

        </Route>
        <Route path='/login/:priceid' element={<Login />}></Route>
        <Route path='/tradeswarrior' element={<Chatbot />}></Route>
        {/* <Route path='/:priceid' element={<SignUpPage/>}></Route>
        <Route path='/signup' element={<SignUpPage/>}></Route> */}
        <Route path='/accessdenied' element={<AccessDeniedPage />}></Route>
        <Route path='/' element={<Login />}></Route>
        <Route path='/tracking/:id/:id2/:refno' element={<Tracking />}></Route>
        <Route path='/google-business' element={<GoogleBusiness />}></Route>
        <Route path='/googlelogin' element={<GoogleLog />}></Route>
        <Route path='/quote/:id' element={<Quote />}></Route>
        {/* <Route path='/dataupload' element={<UploadData/>}></Route> */}
        <Route path='/previewquote/:id' element={<SendQuotee />}></Route>
        <Route path='/invoice/:id' element={<Invoice />}></Route>
        <Route path='/form/:id' element={<CustomerRequestForm />}></Route>
        <Route path='/onlineform/:id' element={<CustomerOnlineRequestForm />}></Route>
        <Route path='/response/:id' element={<ResponsePage />}></Route>
        <Route path='/Signed' element={<Signed />}></Route>
        <Route path='/previewinvoice/:id' element={<SendInvoice />}></Route>
        <Route path='/receipt/:id' element={<RecieptReport />}></Route>
        <Route path='/review/:id/:id2' element={<Review />}></Route>

        {/* <Route path='/dashboard/messenger' element={<Chats/>}></Route> */}


      </Routes>


      {incomingcallShow ? <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <IncomingUI />
      </div> : null}
      {callShow ? <CallingUI
        call={connection}
      /> : null}
      {
        //    <Snackbar
        //    anchorOrigin={{ vertical:'top', horizontal:'right' }}
        //    open={notification !== null}
        //    autoHideDuration={4000}
        //    onClose={() => setNotification(null)}
        //    key={"top" + "right"}

        //  >

        //      <div style={{background:palette.smokeWhite,padding:'20px',width:'300px', boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.5)'}}>
        //        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'10px'}}>
        //      <div style={{textAlign:'start',fontSize:'16px',fontWeight:'600'}}>{notification?.title}</div>
        //      <Cancel fontSize='16px' onClick={()=>setNotification(null)}/>
        //      </div>
        //      <div style={{textAlign:'start',fontSize:'14px',fontWeight:'500'}}>{notification?.body}</div>
        //      </div>

        //  </Snackbar>
      }
    </>
  )
}

export default App;
