import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Modal from '../Modal';
import { formatMobileNumber, TimeListAm, TimeListPM } from '../../utils/Commonfunction';
import cross from '../../assets/cross.png'
import { Box, Checkbox, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import plus from '../../assets/plus.png'
import logoName from '../../assets/LogoName.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editForm, getForm, submitFormNotification } from '../../store/Actions/dashboard.action';
import '../../App.css'
import { palette } from '../../utils/Palette';

const CustomerOnlineRequestForm = () => {
    const navigate = useNavigate()

    let { id } = useParams()

    const [showModal1, setShowModal1] = useState(false);
    const [filter, setFilter] = useState("Input Field")
    const userdata = useSelector(state => state?.auth?.userData)
    const detail = useSelector(state => state?.auth?.userDetails)
    const dataList = ['Input Field', "Date", "Time"]
    const [copy, setCopy] = useState('Copy Link')
    const [linl, setLink] = useState('')
    const [datadetail, setDatadetail] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [customFieldValue, setCustomFieldValue] = useState({
        "customerEmail": "",
        "customerName": "",
        "customerNumber": ""
    })

    const [customData, setCustomData] = useState([])
    const deleteHandler = (index) => {

        const data = [...customData]
        data.splice(index, 1)
        setCustomData(data)


    }
    const dispatch = useDispatch()
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    function onChangePhone(value) {
        setPhoneNumber(value);
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText("inputValue");
        setCopy('Copied !')
    };

    const init = async () => {
        const res1 = await dispatch(getForm(id))
        setDatadetail(res1?.data)
        setCustomData(res1?.data?.customField)
    }

    useEffect(() => {
        init()
    }, [])

    const submitValue = async (val) => {
        console.log({ ...customFieldValue, customerNumber: phoneNumber })
        const res1 = await dispatch(editForm(id, { ...customFieldValue, customerNumber: phoneNumber }))
        const res2 = await dispatch(submitFormNotification({
            "name": customFieldValue?.customerName,
            "formNumber": id,
            "userEmail": datadetail?.email,
            "formName": datadetail?.formName || 'New request'
        }))
        if (res1?.status === true) {
            navigate(`/response/${id}`)
        }

    }


    return (
        <Container>
            <MainContainer>

                <Form>
                    <h2 style={{ marginBottom: 0, textAlign: 'start', marginTop: '10px' }}>{datadetail?.header || "New request"}</h2>

                    {datadetail?.profileRequest && <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginTop: '25px', marginBottom: '25px', marginLeft: ' 15px' }}>
                        <div style={{ display: 'flex', width: '100%', }}>
                            {datadetail?.logoImage ? <ProfilePic src={datadetail?.logoImage} /> : <OutProfile>
                                {getName(datadetail?.businessName)}
                            </OutProfile>}
                            <IconWrapper style={{ marginTop: '-10px' }}>
                                <Heading>{datadetail?.businessName?.slice(0, 57)}{datadetail?.businessName?.length > 57 ? '...' : ''}</Heading>
                                <Header style={{ marginBottom: '-10px', textAlign: 'start' }}>{formatMobileNumber(datadetail?.twilioPhone)}</Header>
                                <Header >{datadetail?.email}</Header>

                            </IconWrapper>
                        </div>



                    </div>}
                    {datadetail?.subHeader && <div style={{ textAlign: 'start', fontSize: '14px', marginBottom: '15px', marginTop: '10px' }}>
                        {datadetail?.subHeader}
                    </div>}
                    <InputBoxContainer style={{ marginTop: '15px' }}>
                        <Label htmlFor={'Create a password'}>Name</Label>
                        <Inputbox id="outlined-basic" size="small" placeholder="Name" variant="outlined" onChange={(e) => {
                            setCustomFieldValue({ ...customFieldValue, customerName: e.target.value })
                        }} />
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <Label htmlFor={'Create a password'}>Email</Label>
                        <Inputbox id="outlined-basic" size="small" placeholder="Email" variant="outlined"

                            onChange={(e) => {
                                setCustomFieldValue({ ...customFieldValue, customerEmail: e.target.value })
                            }}
                        />
                         <Label
                            style={{
                                color: 'gray',
                                cursor: 'initial',
                                marginBottom:'0px',
                                marginTop:'8px',
                                fontWeight:'400',
                                width:'100%',
                               
                                fontSize:'12px',
                                marginLeft:'10px'
                            }}
                        >
                            {`By providing your email, you consent to receiving marketing emails and promotions. You can unsubscribe at any time.`}
                        </Label>
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <Label htmlFor={'First address'}>Phone number</Label>
                        <div className='phoneInput'>
                            <PhoneFiled
                                style={{ width: '100%' }}
                                placeholder="Enter phone number"
                                value={customFieldValue?.customerNumber}
                                onChange={onChangePhone}

                                defaultCountry="US"

                                international
                                focusInputOnCountrySelection='true'
                            />
                        </div>
                        <Label
                            style={{
                                color: 'gray',
                                cursor: 'initial',
                                marginBottom:'0px',
                                marginTop:'8px',
                                fontWeight:'400',
                                width:'100%',
                                fontSize:'12px',
                                marginLeft:'10px'
                            }}
                        >
                            {`By providing your phone number, you agree to receive text messages (SMS) from ${datadetail?.businessName}. You can unsubscribe at anytime by replying STOP. Message and data rates may apply. Message frequency varies.`}
                        </Label>
                    </InputBoxContainer>

                    {
                        customData?.map((item, index) =>
                            item?.fieldType === 'Short text' ? <InputBoxContainer>
                                <Label htmlFor={item?.fieldName}>{item?.fieldName}  {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                <Inputbox id="outlined-basic" size="small"
                                    placeholder={item?.placeHolder}
                                    onChange={(e) => {
                                        setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                    }}
                                    variant="outlined" />

                            </InputBoxContainer>
                                : item?.fieldType === 'Date' ? <InputBoxContainer>
                                    <Label htmlFor={item?.fieldName}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                    <Inputbox id="outlined-basic" size="small"
                                        type={"date"}
                                        placeholder={item?.placeHolder}
                                        onChange={(e) => {
                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                        }}
                                        variant="outlined" />


                                </InputBoxContainer>
                                    : item?.fieldType === 'Long text' ? <InputBoxContainer>
                                        <Label htmlFor={item?.fieldName}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                        <InputText id="outlined-basic" size="small"
                                            type={"date"}
                                            placeholder={item?.placeHolder}
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                            variant="outlined" />


                                    </InputBoxContainer> : item?.fieldType === 'Time' ?
                                        <InputBoxContainer >
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                            {/* <TimeSelect
                                        onChange={(e) => {
                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                        }}
                                    >
                                        <option value="">{item?.placeHolder}</option>
                                        {[...item?.data].map((time) => (
                                            <option key={time} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </TimeSelect> */}
                                            <Selectbox
                                                style={{ marginTop: '0px', borderRadius: '6px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                                placeholder='select' value={'Select an option'} displayEmpty
                                                renderValue={() => customFieldValue[item?.valueKey] ? customFieldValue[item?.valueKey] : 'Select time'} onChange={(e) => {
                                                    console.log(e.target)
                                                    setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })

                                                }}
                                                MenuProps={{ // Add MenuProps to customize menu styling
                                                    PaperProps: {
                                                        style: {
                                                            marginTop: '8px', // Adjust this value for the desired gap
                                                        },
                                                    },
                                                }}
                                            >
                                                {[...TimeListPM, ...TimeListAm]?.map((item, index) =>
                                                    <StyledMenuItem key={index} value={item}>
                                                        <FilterBox
                                                            onChange={(e) => {
                                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                                            }}
                                                            style={{ color: customFieldValue?.filed === item ? '#4169e1' : '#000' }}>


                                                            {item}</FilterBox>
                                                    </StyledMenuItem>
                                                )}
                                            </Selectbox>

                                        </InputBoxContainer>
                                        : item?.fieldType === 'Multi options' ? <InputBoxContainer style={{ width: '98.5%', }}>
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                            {/* <TimeSelect
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                        >
                                            <option value="">{item?.placeHolder}</option>
                                            {[...item?.data].map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </TimeSelect> */}
                                            {[...item?.data]?.map((items, index) => {

                                                return (
                                                    <div
                                                        // onClick={(e) => {
                                                        //     const currentValues = customFieldValue[item?.valueKey] || [];

                                                        //     let newValues;

                                                        //     if (isSelected) {
                                                        //         newValues = currentValues.filter(value => value !== items);
                                                        //     } else {
                                                        //         newValues = [...currentValues, items];
                                                        //     }

                                                        //     setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: newValues });
                                                        // }}
                                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                        key={index}
                                                    >
                                                        <Checkbox
                                                            checked={customFieldValue[item?.valueKey]?.includes(items)}
                                                            onChange={() => {
                                                                const currentValues = customFieldValue[item?.valueKey] || [];
                                                                const isSelected = customFieldValue[item?.valueKey]?.includes(items);
                                                                let newValues;

                                                                if (isSelected) {
                                                                    newValues = currentValues.filter(value => value !== items);
                                                                } else {
                                                                    newValues = [...currentValues, items];
                                                                }

                                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: newValues });
                                                            }}
                                                        />
                                                        <FilterBox
                                                            style={{ color: customFieldValue?.filed === items ? 'rgba(32, 32,32, 0.75)' : 'rgba(32, 32,32, 0.75)', cursor: 'initial' }}
                                                        >
                                                            {items}
                                                        </FilterBox>
                                                    </div>
                                                )
                                            })}
                                            {/* <Selectbox
                                                multiple
                                                value={customFieldValue?.[item?.valueKey] || []}
                                                input={<OutlinedInput id="select-multiple-chip" placeholder="Chip" />}
                                                style={{ marginTop: '0px', borderRadius: '6px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                                placeholder='select'
                                                onChange={(event) => {
                                                    {
                                                        const {
                                                            target: { value },
                                                        } = event;
                                                        setCustomFieldValue({
                                                            ...customFieldValue, [item?.valueKey]:
                                                                // On autofill we get a stringified value.
                                                                typeof value === 'string' ? value.split(',') : value,
                                                        });
                                                    }
                                                    // console.log(e.target)
                                                    // setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })

                                                }}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span style={{ color: '#808080' }}>select option</span>;
                                                    }
                                                    return (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            {selected.map((value, index) => (
                                                                <React.Fragment key={value}>
                                                                    {value}
                                                                    {index < selected.length - 1 && `, `}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    );
                                                }}

                                                MenuProps={{ // Add MenuProps to customize menu styling
                                                    PaperProps: {
                                                        style: {
                                                            marginTop: '8px', // Adjust this value for the desired gap
                                                        },
                                                    },
                                                }}
                                            >
                                                {[...item?.data]?.map((items, index) =>
                                                    <StyledMenuItem key={index} value={items}>
                                                        <Checkbox checked={customFieldValue[item?.valueKey]?.indexOf(items) > -1} />
                                                        <FilterBox
                                                            onChange={(e) => {
                                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                                            }}
                                                            style={{ color: customFieldValue?.filed === items ? '#4169e1' : '#000' }}>


                                                            {items}</FilterBox>
                                                    </StyledMenuItem>
                                                )}
                                            </Selectbox> */}

                                        </InputBoxContainer> : item?.fieldType === 'Select' ? <InputBoxContainer style={{ width: '100%', }}>
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                            {/* <TimeSelect
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                        >
                                            <option value="">{item?.placeHolder}</option>
                                            {[...item?.data].map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </TimeSelect> */}
                                            <Selectbox
                                                style={{ marginTop: '0px', borderRadius: '6px', border: '0.5px solid #d0d5dd', fontSize: '14px' }}
                                                placeholder='select' value={'Select an option'} displayEmpty
                                                renderValue={() => customFieldValue[item?.valueKey] ? customFieldValue[item?.valueKey] : 'Select an option'} onChange={(e) => {
                                                    console.log(e.target)
                                                    setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })

                                                }}
                                                MenuProps={{ // Add MenuProps to customize menu styling
                                                    PaperProps: {
                                                        style: {
                                                            marginTop: '8px', // Adjust this value for the desired gap
                                                        },
                                                    },
                                                }}
                                            >
                                                {[...item?.data]?.map((item, index) =>
                                                    <StyledMenuItem key={index} value={item}>
                                                        <FilterBox
                                                            onChange={(e) => {
                                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                                            }}
                                                            style={{ color: customFieldValue?.filed === item ? '#4169e1' : '#000' }}>


                                                            {item}</FilterBox>
                                                    </StyledMenuItem>
                                                )}
                                            </Selectbox>

                                        </InputBoxContainer> : null
                        )
                    }
                    {datadetail?.disclaimer && <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'auto 1fr',
                            alignItems: 'center',
                            cursor: 'pointer',
                            rowGap: '4px', 
                        }}
                    >
                        <Checkbox
                         sx={{
                            padding: 0, 
                            marginRight:'10px',
                            marginLeft:'4px',
                            marginTop:'0px'
                          
                            
                          }}
                            checked={customFieldValue[datadetail?.disclaimer]}
                            onChange={(e) => {
                                setCustomFieldValue({ ...customFieldValue, [datadetail?.disclaimer]: e.target.checked });
                            }}
                        />
                        <Label
                            style={{
                                color: 'rgba(32, 32, 32, 0.70)',
                                cursor: 'initial',
                                marginBottom:'0px',
                                marginTop:'5px'
                            }}
                        >
                            {datadetail?.disclaimer}
                        </Label>
                    </div>}

                    <ButtonContainer>
                        <SubmitButton onClick={submitValue} type="submit">Submit</SubmitButton>
                    </ButtonContainer>



                </Form>
                <div style={{ margin: '15px', paddingBottom: '30px', paddingTop: '30px', fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', color: 'gray', borderTop: '1px solid #ddd', justifyContent: 'center' }}>
                    <Desc2>By continuing, you agree that you have read and accept the Notifi <a style={{ color: '#4169e1' }} href='https://www.getnotifi.com/legal/terms-of-service'>Terms of Service</a> and <a style={{ color: '#4169e1' }} href='https://www.getnotifi.com/legal/privacy-policy'>Privacy Policy</a>.</Desc2>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        Powered by
                        <Icons style={{ width: '100px', height: '30px', marginLeft: '15px' }} src={logoName} />
                    </div>
                </div>
            </MainContainer>
        </Container>
    );
};
const ButtonContainer = styled.div`
display:flex;
justify-content:end;

@media (min-width: 260px) and (max-width: 621px){

    }
`

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  width: 95%;
  @media (min-width: 260px) and (max-width: 621px){
    width: 92%;
    }


`;

const Desc2 = styled.p`
text-align:center;
font-weight:300;
font-size:13px;
color:gray;
width:97%;
color:gray;
margin-top:0px;
margin-bottom:25px;
`
const StyledSpan = styled.span`
  background: #ddd;
  margin-left: 4px;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  font-size:10px;
`;


const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  background: #fafafa;
  width: 100%;
  min-height:100vh;
   overflow-x:hidden;

`;

const ProfilePic = styled.img`
color:white;
height:70px;

display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
font-weight:bold;
margin-right:25px;

`


const PhoneFiled = styled(PhoneInput)`
--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;
.PhoneInputCountry{
    border: 0.5px solid #d0d5dd;
    height:41px;
}
.PhoneInputInput{
    height:25px;
   
    border: ${(props) => props.error ? '1px' : '0.5px'} solid ${(props) => props.error ? 'red' : '#d0d5dd'};
    &:focus {
        outline: none;
        border: 1.5px solid ${palette.charcoleBlack};
      
      }
}
@media (min-width: 260px) and (max-width: 820px){
    --PhoneInputCountrySelectArrow-marginLeft:5px;
}
`

const Form = styled.div`
width:100%;
height:auto;


  background-color: #fff;
  border: 1px #f3f3f3;
  border-radius: 8px;
 

`;
const MainContainer = styled.div`
  height: auto;
  width: 100%;
  border-radius: 8px;
  background:white;
  display:flex;
  flex-direction:column;
  align-items:center;
  border:1px solid #ddd;
  justify-content:center;
    padding:20px;
    margin:0px;
  @media (min-width: 720px) {
    max-width: 600px;
    margin: auto;

   
   
  }
`;



const SubmitButton = styled.button`
  margin-top: 10px;

  border-radius: 4px;
  background-color: #0F52BA;
  margin-bottom: 10px;
  boder-radius:8px;
  color: #fff;
  font-weight:500;
  border: none;
  cursor: pointer;
  height: 45px;
  width: 50%;
  font-size:15px;
  margin-right:2px;

  @media (min-width: 260px) and (max-width: 621px){
    width: 45%;
    margin-right:10px;
}

`;

const Inputbox = styled.input`
width: 100%;
border-radius: 5px;
font-size: 14px;
height: 20px;
font-weight: 400;
font-family: Inter, sans-serif;
color: #344054;
background: #ffffff;
color:${palette.charcoleBlack}; 
border: 0.5px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;

&:focus {
  outline: 1.5px solid black;
  border: 0.5px solid ${palette.charcoleBlack};

}
::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}
`;

const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    color: rgba(32, 32, 32, 0.90);
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-break: break-word; 
    overflow-wrap: anywhere; 
    display: inline-block;
`;

const Icons = styled.img`
width: 20px;
height: 20px;
`


const Selectbox = styled(Select)`
  width: 100%;
  border-radius: 4px;
  text-align: start;
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  border: 1px solid #f3f3f3;
  background: white;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
  &:hover {
    background-color: #f3f3f3;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important; 
  }
  .MuiSelect-select {
    border: none !important;
  }
  .MuiMenuItem-root:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 98%;
  }
`;
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:13px;
align-items:center;
color:rgba(32, 32,32, 0.75);
font-weight:500;
cursor:pointer;
white-space: normal; /* Allow text to wrap */
word-break: break-word; /* Break long words */
padding: 0 8px; /* Add padding */
  box-sizing: border-box; /* Include padding in width */

`
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  align-self: start;
  white-space: normal; /* Allow text to wrap */
  word-break: break-word; /* Break long words */
  padding-bottom:5px;
  padding-top:5px;
  padding: 8px; /* Add padding */
  box-sizing: border-box; /* Include padding in width */
  :hover {
    background: #dddddd;
    border-radius: 5px;
 
  }
`;
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:75px;
width:75px;
display:flex;
justify-content:center;
align-items:center;
font-size:20px;
padding-bottom:0px;
border-radius:45px;
font-weight:bold;
margin-right:25px;
`
const Header = styled.p`
font-size:13px;
font-weight:500;
color:gray;

`
const Heading = styled.p`
font-weight:600;
font-size: 16px;
font-weight:900;
margin-bottom:-10px;
text-align:start;

`
const IconWrapper = styled.div`
  display:flex;
  flex-direction:column;
justify-content:start;
align-items:start;
`;
const InputText = styled.textarea`
font-family:Inter;
width: 100%;
border-radius: 5px;
font-size: 16px;
height: 45px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 0.5px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;
export default CustomerOnlineRequestForm;
