import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import cross from '../../assets/cross.png'
import Autocomplete from 'react-google-autocomplete';
import { sendMessage } from '../../store/Actions/Auth.action'
import { colorThemes, formatMobileNumber, putCommaInvalue, TimeListAm, TimeListPM } from '../../utils/Commonfunction';
import Modals from '../Modal';
import moment from 'moment-timezone'
import { addHasgtag, editCreateBookings, fetchbookingDetails, getBookings, getTravelTime, getUserDetails, submitassignTag, submitBookingStatus, submitchecklist, submitEditContacts, submitNotes, updatejobData, UploadNotesImage } from '../../store/Actions/dashboard.action';
import plus from '../../assets/plus.png'
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { CardContent, Divider, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material'
import LineItemComponent from './LineItemComponent'
import AddContact from '../Settings/AddContact'
import FullScreenModal from '../FullScreenModal'
import CreateInvoice from './CreateInvoice'
import RightModal from '../RightSideModal'
import CreateQuote from './CreateQuote'
import TravelTime from './Bookings/TimeTravel'
import { palette } from '../../utils/Palette'
import { Add, ArrowBackIos, LockOpen, PauseCircleOutline, Refresh, Check, CalendarMonth, Cancel, DeleteOutlined, VisibilityOutlined, ModeEdit, More, MoreHoriz, People, Photo, Schedule, KeyboardArrowDown, ArrowForwardIosOutlined, Transform, CancelOutlined, LocationOnOutlined, Edit, PlusOne, AddAPhoto, AddPhotoAlternateOutlined, ArrowDownwardOutlined, KeyboardArrowUp } from '@mui/icons-material'
import DatePickerComponent from '../../ReuseableComponets/DatePickerComponent'
import IOSSwitchComponent from '../../ReuseableComponets/IOSSwitch'
import InputBox from '../../ReuseableComponets/InputBox'
import CustomizedMenu from '../../ReuseableComponets/MenuComponent'
import TopModal from '../TopModal'
import RippleButton from '../../ReuseableComponets/RippleButton'
import InputPicker from '../../ReuseableComponets/InputPicker'
import { useNavigate, useParams } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import Checkbox from '../../ReuseableComponets/CheckBox';
import DeleteModal from './Contacts/DeleteModal';
import ImageCarousel from '../../ReuseableComponets/CuroselImage';
import BreakdownComponent from '../../ReuseableComponets/BreakdownJob';
import mapStyles from '../Tracking/mapStyles';
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import JobCalander from './Bookings/JobCalander';
import SiteVisit from './Bookings/EventList';
import AddSiteVisit from './Bookings/AddVisits';
import RecurringCard from './Bookings/Component/RecurrringJobCard';
import MultiSelectWithSearch from '../Settings/component/MultiSelectSearch';
import AddCustomField from '../Settings/component/AddCustomField';
import { pdf } from '../../assets';
import { FiArrowDown, FiEye, FiTrash2 } from 'react-icons/fi';
import ViewChecklist from '../../Pages/Checklist/ViewChecklist';
// import ViewChecklist from '../../Pages/Checklist/ViewChecklist';

const filter = createFilterOptions();
function JobDetails(props) {
    const navigate = useNavigate()
    let { id } = useParams()
    const globename = useSelector(state => state?.auth?.globename)
    const globenumber = useSelector(state => state?.auth?.globenum)
    const userDetails = useSelector(state => state?.auth?.userData)
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
    const checklist = useSelector(state => state?.auth?.userData?.checkLists)
    const [selectedChecklist, setSelectedChecklist] = useState([])
    const [checkedaddOwnerData, setcheckedAddOwnerData] = useState([])
    const [addOwnerData, setAddOwnerData] = useState([])
    const [position, setPosition] = useState({})
    const [hastTag, setHasTag] = useState(hashTags)
    const customFieldData = useSelector(state => state?.auth?.userData?.customeFields)
    const [customField, setDataCustomField] = useState(customFieldData)
    const [selectCustomField, setCustomField] = useState([])
    const [invoiceData, setInvoiceData] = useState([])
    const [selectedViewchecklist, setSelectedViewChecklist] = useState({})
    const [quoteData, setQuoteData] = useState([])
    const [notes, setNotes] = useState('')
    const [selectHash, setSelectedHash] = useState([])
    const [loader, setLoader] = useState(false)
    const [isModalopen1, setIsModalOpen1] = useState(false)
    const [photodata, setphotoData] = useState([])
    const [edit, setEdit] = useState(false)
    const [value, setValue] = React.useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [scheduleLater, setScheduleLater] = useState(false)
    const [postData, setpostData] = useState([]);
    const [opendAddContact, setOpenAddContact] = useState(false)
    const [isbookingStatus, setBookingStatus] = React.useState()
    const [tempData, setTempData] = useState({})
    const [team, setTeam] = React.useState([])
    const [selectedTeam, setSelectedTeam] = React.useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpen1, setModalOpen1] = useState(false)
    const [expanded, setExpanded] = React.useState('panel1');
    const [addchecklist, setAddChecklist] = useState({
        "title": "",
        "data": []
    })
    const [checklistItemData, setChecklistItemData] = useState([{
        type: '',
        value: ''
    }])
    const handleChange = (panel) => {
        if (panel === expanded) {
            setExpanded(null)
        }
        else {
            setExpanded(panel);
        }
    };
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const dispatch = useDispatch()
    const userdata = useSelector(state => state?.auth?.userdata)
    const [showMore, setShowMore] = useState(false);
    const itemsToShow = showMore ? selectCustomField.length : 3;
    const [value1, setValue1] = React.useState(null);
    const [value3, setValue3] = React.useState(null);
    const [editValue, setEditValue] = useState({
        "name": "",
        "email": "",
        "address": "",
        "to": "",
        "businessName": ''

    })
    const [address, setAddress] = React.useState({
        "street": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "title": ''
    })
    const [travelData, setTravelData] = useState([])
    const [newCutomField, setNewCustomField] = useState({
        fieldName: '',
        fieldValue: ''
    })
    const [boolValue, setBoolValue] = useState({
        description: false,
        edit: false,
        tag: false,
        customField: false,
        isEditItem: false
    })

    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const [values, setValues] = useState({
        date: '',
        time: '',
        endDate: '',
        endTime: '',
        "name": userdata?.name || globename || "",
        "email": userdata?.email || "",
        "address": userdata?.address || "",
        "notes": "",
        "service": "Moving",
        "phone": userdata?.to || globenumber || "",
        "workersAssigned": []
    })
    const statusData = [{ name: "Open", img: LockOpen, value: 'Waiting for confirmation' },
    { name: "On hold", img: PauseCircleOutline, value: 'On Hold' },
    { name: "In Progress", img: Refresh, value: 'In Progress' },
    { name: "Completed", img: Check, value: 'Completed' }]


    const detail = useSelector(state => state?.auth?.convo)
    const [active, setActive] = useState('start')

    const onSubmitLineItem = async (val) => {
        var newDate = values?.time ? new Date() : '';
        var newDate1 = values?.endTime ? new Date() : '';
        const selectedData = []

        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (values?.time) {
            const val = values.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (values?.endTime) {
            const val3 = values.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }

        if (props?.serviceRequest) {
            const res = await dispatch(editCreateBookings({ ...values, "items": val, "tags": selectHash, "customField": selectCustomField, "notes": notes, "images": selectedImage, ...editValue, phone: editValue?.to, "referenceNo": props?.value?.referenceNo, "workersAssigned": selectedData, time: newDate, endTime: newDate1 }))

        }

    }
    const handleCheckboxChange = (item) => {

        const newData = [...checkedaddOwnerData];

        const existingIndex = newData.findIndex((el) => `${el.ids}` === `${item.ids}`);


        if (existingIndex !== -1) {
            newData.splice(existingIndex, 1);
        } else {
            newData.push(item);
        }

        setcheckedAddOwnerData(newData);
    };

    const imageHandleChange = async (e) => {
        const FileList = e.target.files
        const imageList = []

        Object.keys(FileList).map((item, index) => {
            const fileURL = URL.createObjectURL(FileList[item])
            imageList.push(fileURL)

        })

        const res = await dispatch(UploadNotesImage([...FileList]));
        setpostData([...postData, ...res?.data])
        setSelectedImage([...selectedImage, ...res?.data])
    }
    const sendchat = async (val, data) => {
        if (val) {
            await dispatch(sendMessage(
                {
                    "from": `+${localStorage.getItem('number')}`,
                    "to": data?.phone,
                    "body": val
                }
            ))



        }
    }

    const addNewHashTag = async (val) => {
        setSelectedHash([...selectHash, val])
        const res = await dispatch(addHasgtag({
            "hashTags": val
        }))
        setHasTag([...hastTag, val])



    }
    const bookingStatus = async (value) => {
        const data = {
            "referenceNo": tempData?.referenceNo,
            "bookingStatus": value
        }

        const res = await dispatch(submitBookingStatus(data))
        if (res.status === true) {
            setBookingStatus(value)
        }
    }

    const editContact = async () => {
        const res = await dispatch(updatejobData({ ...editValue, "address": address?.address, "mainAddress": address }, id))
        init()

    }
    const editSchedule = async () => {
        var newDate = values?.time ? new Date() : '';
        var newDate1 = values?.endTime ? new Date() : '';
        if (values?.time) {
            const val = values.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (values?.endTime) {
            const val3 = values.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }
        const res = await dispatch(updatejobData({ "date": values?.date, "time": newDate, "endDate": values?.endDate, "endTime": newDate1 }, id))
        setBoolValue({ ...boolValue, edit: false })
        init()

    }

    const init = async () => {
        setLoader(true)
        const res3 = await dispatch(getUserDetails())
        const res1 = await dispatch(fetchbookingDetails(id))
        const res = await dispatch(getTravelTime(id));
        console.log(res1, "dddaaaaa")
        setTravelData(res?.data || [])
        setTempData(res1?.data)
        setEditValue({
            ...editValue,
            name: res1?.data?.name,
            email: res1?.data?.email,
            address: res1?.data?.customerAddress,
            "to": res1?.data?.phone,
            mainAddress: res1?.data?.mainAddress,
            businessName: res1?.data?.businessName,

        })
        setAddress({ ...res1?.data?.mainAddress, address: res1?.data?.customerAddress })
        setValues({
            ...values, mainAddress: res1?.data?.mainAddress, email: res1?.data?.email,
            address: res1?.data?.customerAddress, "to": res1?.data?.phone, businessName: res1?.data?.businessName, priorityJob: res1?.data?.priorityJob || '', typeOfJob: res1?.data?.typeOfJob || '', date: moment(res1?.data?.date).format('hh:mm A') === 'Invalid date' ? res1?.data?.date : res1?.data?.timezone ? moment(res1?.data?.date).tz(res1?.data?.timezone).format('DD-MMM YYYY') : moment(res1?.data?.date).format('DD-MMM YYYY'), time: res1?.data?.time ? res1?.data?.timezone ? moment(res1?.data?.time).tz(res1?.data?.timezone).format('hh:mm A') : moment(res1?.data?.time).format('hh:mm A') : '', endDate: moment(res1?.data?.endDate).format('hh:mm A') === 'Invalid date' ? res1?.data?.endDate : res1?.data?.timezone ? moment(res1?.data?.endDate).tz(res1?.data?.timezone).format('DD-MMM YYYY') : moment(res1?.data?.endDate).format('DD-MMM YYYY'), endTime: res1?.data?.endTime ? res1?.data?.timezone ? moment(res1?.data?.endTime).tz(res1?.data?.timezone).format('hh:mm A') : moment(res1?.data?.endTime).format('hh:mm A') : '',
            fieldNotes: res1?.data?.notes,
        })
        setInvoiceData(res1?.data?.invoiceData)
        setQuoteData(res1?.data?.quoteData || [])
        setAddOwnerData(res1?.data?.items)
        setSelectedChecklist(res1?.data?.checkList)
        setSelectedHash(res1?.data?.hashTags)
        setCustomField(res1?.data?.customeField)
        setNotes(res1?.data?.description)
        setSelectedTeam(res1?.data?.team)
        setBookingStatus(res1?.data?.bookingStatus)
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${res1?.data?.customerAddress}&key=AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA`)
            .then(response => response.json())
            .then(data => {
                if (data?.results && data?.results?.length > 0) {

                    const location = data.results[0].geometry.location;
                    const jitteredLatitude = location.lat;
                    const jitteredLongitude = location.lng;
                    setPosition({ lat: jitteredLatitude, lng: jitteredLongitude })

                }
                else {
                    setPosition({ lat: 0, lng: 0 })
                }
            });
        setLoader(false)
        let res2 = await dispatch(getBookings())
        if (res2.status == true) {
            setTeam(res2?.data?.team)

        }

    }
    useEffect(() => {
        setNotes(props?.value?.description)
        init()
    }, [])

    const menuItemsc = [
        {
            label: 'Edit information',
            icon: <ModeEdit style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Delete job',
            icon: <DeleteOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },

    ];
    const updateData = async (key, value) => {
        const res = await dispatch(updatejobData({ [key]: value }, id))
        init()
    }
    const onPressAttachPhoto = async () => {
        const detail = {
            "referenceNo": tempData?.referenceNo,
            "description": values?.attachmentDesc || '',
            "images": selectedImage
        }

        const res = await dispatch(submitNotes(detail));
        init()
    }

    const calculateGrossProfit = () => {
        let totalRevenue = 0;
        let totalCostPrice = 0;
        let serviceCost = 0;
        let materialCost = 0;
        tempData?.items?.forEach(item => {
            const quantity = parseInt(item?.quantity || 1);
            const sellingPrice = parseFloat(item?.price || 0);
            const costPrice = parseFloat(item?.unitPrice || 0);
            const revenue = quantity * sellingPrice;
            totalRevenue += revenue;
            totalCostPrice += quantity * costPrice;
            if (item?.type === 'Material') {
                materialCost += quantity * costPrice;
            }
            else {
                serviceCost += quantity * costPrice;
            }

        });
        const totalGrossProfit = totalRevenue - totalCostPrice;
        const percentageGrossProfit = totalRevenue === 0 ? 0 : (totalGrossProfit / totalRevenue) * 100;
        const percentageCost = totalRevenue === 0 ? 0 : (totalCostPrice / totalRevenue) * 100;
        const servicepercentageCost = totalRevenue === 0 ? 0 : (serviceCost / totalRevenue) * 100;
        const materialpercentageCost = totalRevenue === 0 ? 0 : (materialCost / totalRevenue) * 100;

        return { percentageData: [percentageGrossProfit, percentageCost], totalGrossProfit: totalRevenue, totalCostPrice: totalCostPrice, serviceData: { cost: serviceCost, percentage: servicepercentageCost }, materialData: { cost: materialCost, percentage: materialpercentageCost } }

    }
    const renderTravelItem = React.useCallback((item, index) => {
        return <TravelTime item={item} index={index} />
    }, [])

    const isImageUrl = (url) => {

        const hasImageExtension = /\.(jpg|jpeg|png|gif|bmp|webp|tiff)$/i.test(url);
        const commonImagePatterns = /(logo|image|img|picture)/i.test(url);
        return hasImageExtension || commonImagePatterns;
    };

    return (
        <Body>
            {loader ? <div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                src={require('../../assets/loaders.json')}
                className="player"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
            />
            </div> :
                <Main>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => navigate(-1)}>
                                <ArrowBackIos style={{ fontSize: '16px' }} />
                            </CloseButton>
                                <h3 style={{marginLeft:'20px'}}>{tempData?.bookingName}</h3>

                        </div>
                        {tempData?.bookingNumber && <h3>Job #{tempData?.bookingNumber}</h3>}
                    </HeaderContainer>
                    <Flexbox>
                        <div style={{ width: '450px', display: 'flex', flexDirection: 'column' }}>
                            <WrapperHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <ValueName style={{ fontWeight: '500', fontSize: '16px', color: palette.black }}>{editValue?.name}</ValueName>
                                    <CustomizedMenu
                                        buttonStyle={{
                                            backgroundColor: 'transparent',
                                            minWidth: 'auto',
                                            padding: '2px',
                                            border: 'none',
                                        }}
                                        menuItems={menuItemsc}
                                        onClick={(lab) => {
                                            if (lab === 'Delete job') {
                                                setBoolValue({ ...boolValue, delete: true })

                                            }
                                            else {
                                                setEdit(true)

                                            }
                                        }}
                                    />
                                </div>
                                {values?.address && <p className='text'>{values?.address}</p>}
                                {values?.email && <p className='text'>{values?.email}</p>}
                                {values?.to && <p className='text'>{formatMobileNumber(values?.to)}</p>}

                            </WrapperHeader>
                            <StyleBox>
                                {tempData?.jobType === "Reoccuring" ?
                                    <RecurringCard
                                        detail={{ ...tempData?.recurrence, date: tempData?.date }}
                                    /> : <>
                                        <div className='keyContainer'>
                                            <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                                Scheduling
                                            </h5>
                                            <EditContainer onClick={() => setBoolValue({ ...boolValue, calendarVisible: true })}>
                                                <CalendarMonth style={{ fontSize: '16px' }} />
                                            </EditContainer>
                                        </div>
                                        <div style={{ flexDirection: 'column' }} className='valueContainer'>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: 'center' }}>

                                                <DatePickerComponent
                                                    disabled={scheduleLater}
                                                    label="Start date"
                                                    selectedDate={values?.date}
                                                    onDateChange={(text) => {
                                                        if (new Date(text) > new Date(values?.endDate)) {
                                                            setValues({ ...values, endDate: text, date: text });
                                                          } else {
                                                            setValues({ ...values, date: text });
                                                           
                                                          }
                                                        if (!boolValue?.edit) {
                                                            setBoolValue({ ...boolValue, edit: true })
                                                        }     
                                                    }}
                                                />
                                                <div style={{ position: 'relative' }}>
                                                    <Label style={{ marginLeft: '15px', fontWeight: '600' }}>Time</Label>
                                                    <SelectTimebox
                                                        disabled={scheduleLater}
                                                        renderValue={() =>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                                {values?.time || 'Select time'}
                                                                <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                            </div>
                                                        }
                                                        defaultValue={"REAL_ESTATE"} onChange={(event) => {
                                                            setValues({ ...values, time: event.target.value })
                                                            if (!boolValue?.edit) {
                                                                setBoolValue({ ...boolValue, edit: true })
                                                            }
                                                        }}>
                                                        {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                            <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                        )}
                                                    </SelectTimebox>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', justifyContent: 'center' }}>

                                                <DatePickerComponent
                                                    disabled={scheduleLater}
                                                    label="End date"
                                                    selectedDate={values?.endDate}
                                                    onDateChange={(text) => {
                                                        if (new Date(text) < new Date(values?.date)) {
                                                            setValues({ ...values, endDate: text, date: text });
                                                          } else {
                                                            setValues({ ...values, endDate: text });
                                                           
                                                          }
                                                        if (!boolValue?.edit) {
                                                            setBoolValue({ ...boolValue, edit: true })
                                                        }
                                                    }}
                                                />
                                                <div style={{ position: 'relative' }}>
                                                    <Label style={{ marginLeft: '15px', fontWeight: '600' }}>End time</Label>
                                                    <SelectTimebox
                                                        disabled={scheduleLater}
                                                        renderValue={() =>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                                {values?.endTime || 'Select time'}
                                                                <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                            </div>
                                                        }
                                                        defaultValue={"REAL_ESTATE"} onChange={(event) => {
                                                            if (!boolValue?.edit) {
                                                                setBoolValue({ ...boolValue, edit: true })
                                                            }
                                                            setValues({ ...values, endTime: event.target.value })
                                                        }}>
                                                        {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                            <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                        )}
                                                    </SelectTimebox>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '15px' }}>
                                                <p>Schedule later</p>
                                                <IOSSwitchComponent checked={scheduleLater} onChange={(val) => {
                                                    if (!boolValue?.edit) {
                                                        setBoolValue({ ...boolValue, edit: true })
                                                    }
                                                    setScheduleLater(val.target.checked)
                                                    if (val.target.checked) {
                                                        setValues({ ...values, date: '', time: '', endDate: '', endTime: '' })
                                                    }
                                                }} />

                                            </div>
                                            {boolValue?.edit && <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', marginBottom: '10px', marginRight: '5%', justifyContent: 'flex-end', }}>
                                                <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                    setValues({ ...values, date: tempData?.date, time: tempData?.time ? moment(tempData?.time).format('hh:mm A') : '', endDate: tempData?.endDate, endTime: tempData?.endTime ? moment(tempData?.endTime).format('hh:mm A') : '' })
                                                    setBoolValue({ ...boolValue, edit: false })
                                                    setScheduleLater(false)
                                                }}>Cancel</RippleButton>
                                                <RippleButton onClick={async () => {
                                                    await editSchedule()
                                                    setBoolValue({ ...boolValue, edit: false })
                                                }}>Save</RippleButton>
                                            </div>}
                                        </div>
                                    </>}
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Location
                                    </h5>
                                    <EditContainer >
                                        <LocationOnOutlined style={{ fontSize: '18px' }} />
                                    </EditContainer>
                                </div>
                                {position && (
                                    <GoogleMap
                                        mapContainerStyle={{ width: "100%", height: "180px" }}
                                        center={position || {}}
                                        zoom={9}
                                        options={{
                                            styles: mapStyles // Apply custom map styles
                                        }}

                                    >
                                        <MarkerF position={position || {}} />

                                    </GoogleMap>
                                )}
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Crew
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, crew: true })}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.crew ? selectedTeam?.length > 0 ?
                                    <WrapperField style={{ marginTop: '8px' }}>
                                        {selectedTeam?.map((item, index) =>
                                            <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>
                                                {item?.firstName}
                                            </WrapperContainer>
                                        )}
                                    </WrapperField> :
                                    null :
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', }}>
                                        <Autocomplete1
                                            value={value3}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Add new contact';
                                                const replacement = '';
                                                const result = newValue?.name

                                                if (newValue && newValue?.firstName?.includes(searchString)) {

                                                    setOpenAddContact(true)
                                                }

                                                else if (typeof newValue?.firstName === 'string') {

                                                    setSelectedTeam([...selectedTeam, newValue])

                                                }
                                                else {

                                                    setSelectedTeam([...selectedTeam, newValue])

                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;


                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={team}
                                            getOptionLabel={(option) => {

                                                if (typeof option?.firstName === 'string') {
                                                    return option.firstName;
                                                }

                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }

                                                return option;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option?.firstName}</li>}
                                            sx={{
                                                width: 315, '& input': {
                                                    height: '5px', fontSize: '13px',


                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#ccc',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#999',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                }
                                            }}
                                            freeSolo
                                            style={{ height: 30, marginLeft: '10px', marginBottom: '20px', fontSize: '13px' }}
                                            renderInput={(params) => (
                                                <div>
                                                    <TextField style={{ fontSize: '13px' }} {...params}


                                                        placeholder="Add crew member" />

                                                </div>
                                            )}
                                        />
                                        {selectedTeam?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                                            {selectedTeam?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, }}>{item?.firstName} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                    const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                                    setSelectedTeam(arr)
                                                }} src={cross} /></WrapperContainer>
                                            )}
                                        </WrapperField> : null}
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', marginBottom: '10px', marginRight: '5%', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, crew: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={async () => {
                                                const selectedData = []
                                                selectedTeam?.map((item) => {
                                                    selectedData.push(item?.email)
                                                })
                                                await updateData('workersAssigned', selectedData)
                                                setBoolValue({ ...boolValue, crew: false })
                                            }}>Save</RippleButton>
                                        </div>

                                    </div>
                                }


                            </StyleBox>

                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Tags
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, tag: true })}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.tag ? selectHash?.length > 0 ? <WrapperField style={{ marginTop: '8px' }}>
                                    {selectHash?.map((item, index) =>
                                        <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>{item}</WrapperContainer>
                                    )}
                                </WrapperField> :
                                    null :
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', }}>
                                        <Autocomplete1
                                            value={value}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Create ';
                                                const replacement = '';
                                                const result = newValue?.replace(searchString, replacement)?.replace(/"/g, '');

                                                if (newValue && newValue?.includes(searchString)) {

                                                    addNewHashTag(result)
                                                    setValue('');
                                                }

                                                else if (typeof newValue === 'string') {
                                                    setValue('');
                                                    setSelectedHash([...selectHash, result])

                                                }
                                                else {
                                                    setValue('');

                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;

                                                const isExisting = options.some((option) => inputValue === option);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push(`Create "${inputValue}"`);
                                                }

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={hastTag}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }

                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }

                                                return option;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option}</li>}
                                            sx={{
                                                width: 315, marginLeft: '12px', '& input': {
                                                    height: '5px', fontSize: '13px',


                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#ccc',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#999',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                }
                                            }}
                                            freeSolo
                                            style={{ height: 30, marginLeft: '10px', marginBottom: '20px', fontSize: '13px' }}
                                            renderInput={(params) => (
                                                <TextField style={{ fontSize: '13px' }} {...params} placeholder="Search or create a tag" />
                                            )}
                                        />
                                        {selectHash?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                                            {selectHash?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>{item} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                    const arr = selectHash?.filter(obj => obj !== item)
                                                    setSelectedHash(arr)
                                                }} /></WrapperContainer>
                                            )}
                                        </WrapperField> : null}
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', marginBottom: '10px', marginRight: '4%', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, tag: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={async () => {
                                                await updateData('tags', selectHash)
                                                setBoolValue({ ...boolValue, tag: false })
                                            }}>Save</RippleButton>
                                        </div>

                                    </div>
                                }

                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Checklist
                                    </h5>
                                    <EditContainer onClick={() => {
                                        checklist?.length > 0 ? setBoolValue({ ...boolValue, isChecklist: true }) : setBoolValue({ ...boolValue, addchecklist: true })
                                    }}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {selectedChecklist?.length > 0 && <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '5px' }}>
                                    {selectedChecklist?.map((item) =>
                                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', margin: "5px" }}>
                                            <div>
                                                <Label>{item?.title}</Label>
                                                <Label style={{ color: palette.grey }}>{item?.data?.length} checklist items</Label>
                                            </div>
                                            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <VisibilityOutlined style={{ cursor: 'pointer', marginRight: '15px' }} onClick={async () => {
                                                    setBoolValue({ ...boolValue, viewChecklist: true })
                                                    setSelectedViewChecklist({ data: item, referenceNo: tempData?.referenceNo, filledData: tempData?.checkListData?.filter((ite) => ite?.checklistNo === item?.checklistNo) })

                                                }} /> */}
                                            <ActionIcons>
                                                {tempData?.checkListData?.some(
                                                    (ite) => ite?.checklistNo === item?.checklistNo
                                                ) && <FiEye style={{ cursor: 'pointer' }} onClick={async () => {
                                                    console.log(tempData, "dsdds")
                                                    setBoolValue({ checklistData: item, "filledData": tempData?.checkListData?.filter((ite) => ite?.checklistNo === item?.checklistNo), ViewChecklist: true })

                                                }} />}

                                                <FiTrash2 style={{ cursor: 'pointer' }} onClick={async () => {
                                                    const data = selectedChecklist.filter(items => items?.checklistNo !== item?.checklistNo);
                                                    await updateData('checklistNo', data)
                                                }} />
                                            </ActionIcons>
                                        </div>
                                        // </div>
                                    )}
                                </div>}
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Custom field
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, customField: true })}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.customField ? selectCustomField?.length > 0 ? <>
                                    {selectCustomField?.slice(0, itemsToShow).map((item, index) => (
                                        <CustomFieldWrapper>
                                            <Tooltip arrow placement='top' title={item?.fieldName}>
                                                <p className='fieldName'>{item?.fieldName}</p>
                                            </Tooltip>
                                            <p className='fieldValue'>{item?.fieldValue?.replace(/[\[\]"]/g, '').replace(/,/g, ', ')}</p>
                                        </CustomFieldWrapper>
                                    ))}
                                    {selectCustomField.length > 3 && (
                                        <OptionBox style={{ alignSelf: 'end', margin: '15px' }} onClick={toggleShowMore}>{showMore ? 'Show Less' : 'Show More'}</OptionBox>
                                    )}
                                </>
                                    : null

                                    :
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                        <Autocomplete1
                                            value={value1}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Create ';
                                                const replacement = '';
                                                const result = newValue?.fieldName?.replace(searchString, replacement)?.replace(/"/g, '');

                                                if (typeof newValue === 'string') {
                                                    setValue1('');
                                                    setCustomField([...selectCustomField, { fieldName: 'cdsf' }])
                                                } else if (newValue && newValue.fieldName) {
                                                    setCustomField([...selectCustomField, { fieldName: newValue?.fieldName, fieldValue: newValue?.fieldValue, fieldType: newValue?.fieldType, options: newValue?.options }])
                                                    setValue1('');
                                                } else {
                                                    setValue1('');

                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={customField?.filter((item => item?.type === 'job'))}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                if (option?.fieldName) {
                                                    return option.fieldName;
                                                }
                                                return '';
                                            }}
                                            renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.fieldName}</li>}
                                            sx={{
                                                width: 315, marginLeft: '12px', '& input': {
                                                    height: '5px', fontSize: '13px',


                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#ccc',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#999',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                }

                                            }}
                                            freeSolo
                                            style={{ height: 30, marginLeft: '10px', marginBottom: '0px', fontSize: '13px' }}
                                            renderInput={(params) => (
                                                <TextField style={{ fontSize: '13px' }} {...params} placeholder="Search custom field" />
                                            )}
                                        />
                                        <div style={{ marginTop: '20px', fontWeight: '500', color: '#4169e1', cursor: 'pointer' }} onClick={() => setIsModalOpen1(true)}>Create new custom field</div>
                                        {selectCustomField?.length > 0 ? <CustomBox style={{ width: '100%' }}>
                                            {selectCustomField?.map((item, index) =>
                                                <div style={{ display: 'flex', alignItems: 'center', width: '310px', }}>
                                                    {item?.fieldType === "dropdownSingle" ?
                                                        <div style={{ width: '100%', marginBottom: '10px' }}>
                                                            <Label style={{ fontWeight: '550' }}>{item?.fieldName}</Label>

                                                            <SelectDrpoDown
                                                                id="category-visibility"
                                                                value={item?.fieldValue}
                                                                style={{ width: '110%' }}
                                                                defaultValue=''
                                                                onChange={(e) => {
                                                                    const data = [...selectCustomField]
                                                                    data[index].fieldValue = e.target.value
                                                                    data[index].fieldType = item?.fieldType
                                                                    setCustomField(data)

                                                                }}
                                                            >
                                                                <Option value="" disabled>
                                                                    Choose value
                                                                </Option>

                                                                {
                                                                    item?.options?.map((vars, index) => (
                                                                        <Option key={index} value={vars}>
                                                                            {vars}
                                                                        </Option>
                                                                    ))
                                                                }
                                                            </SelectDrpoDown>

                                                        </div> : item?.fieldType === "dropdownMultiple" ?
                                                            <div style={{ width: '84%', marginBottom: '10px' }}>
                                                                <Label style={{ fontWeight: '550' }}>{item?.fieldName}</Label>
                                                                <MultiSelectWithSearch
                                                                    options={item?.options}
                                                                    containerStyle={{ width: '97%' }}
                                                                    dropDownStyle={{ width: '96%' }}
                                                                    onSelectionChange={(value) => {
                                                                        const data = [...selectCustomField]
                                                                        data[index].fieldValue = JSON.stringify(value)
                                                                        data[index].fieldType = item?.fieldType
                                                                        setCustomField(data)
                                                                    }}

                                                                />


                                                            </div> : <div style={{ width: '100%', marginBottom: '10px' }}>
                                                                <Label>{item?.fieldName}</Label>
                                                                <InputBox

                                                                    placeholder="Value"
                                                                    type="text"
                                                                    value={item?.fieldValue}
                                                                    style={{ height: '15px', width: '100%' }}
                                                                    onChange={(e) => {
                                                                        const data = [...selectCustomField]
                                                                        data[index].fieldValue = e.target.value
                                                                        data[index].fieldType = item?.fieldType
                                                                        setCustomField(data)
                                                                    }}


                                                                />
                                                            </div>}

                                                    <DeleteOutlined style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '20px', marginTop: '10px' }} onClick={() => {
                                                        const data = [...selectCustomField]
                                                        data.splice(index, 1)
                                                        setCustomField(data)
                                                    }} /></div>

                                            )}
                                        </CustomBox> : null}
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', marginBottom: '0px', marginRight: '4%', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, customField: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={async () => {
                                                await updateData('customField', selectCustomField)
                                                setBoolValue({ ...boolValue, customField: false })
                                            }}>Save</RippleButton>
                                        </div>

                                    </div>
                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Field notes
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, description: true })}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.description ? (tempData?.notes && tempData?.notes !== 'No Due') ? <p className='valueContainer' >{tempData?.notes}</p> : null :
                                    <>
                                        <div className='valueContainer' style={{ flexDirection: 'column', justifyContent: 'center' }}>

                                            <InputText
                                                placeholder="Note"
                                                type="text"
                                                value={values?.fieldNotes}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values, fieldNotes: e.target.value
                                                    })
                                                }}

                                            />
                                        </div>
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '0px', marginBottom: '15px', marginRight: '4%', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, description: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={async () => {
                                                await updateData('description', values?.fieldNotes)
                                                setBoolValue({ ...boolValue, description: false })
                                            }}>Save</RippleButton>
                                        </div>

                                    </>
                                }
                            </StyleBox>
                            {/* <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Photos / Attachments
                                    </h5>
                                    <EditContainer onClick={() => {
                                        setSelectedImage([])
                                        setBoolValue({ ...boolValue, photo: true })
                                        }}>
                                        <Add style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {boolValue?.photo ? <>
                                    <div className='valueContainer' style={{ flexDirection: 'column', }}>
                                        <InputText
                                            placeholder="Note"
                                            type="text"

                                            // value={editValue?.email}

                                            onChange={(e) => {
                                                setValues({ ...values, attachmentDesc: e.target.value }

                                                )
                                            }}

                                        />
                                        <h5 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'start', marginLeft: '10px' }}>Attach files</h5>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', }}>
                                            {selectedImage?.map((item) =>
                                                <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                                    <PhotosStyle src={isImageUrl(item)?item:pdf} />
                                                    <div
                                                        onClick={() => {
                                                            const arr = selectedImage?.filter(obj => obj !== item)
                                                            setSelectedImage(arr)
                                                        }}
                                                        style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                        <MoreIcon style={{ width: '15px' }} src={cross} />
                                                    </div>
                                                </div>
                                            )}

                                            <FileInputButton htmlFor="fileInput">
                                                <MoreIcon src={plus} />
                                            </FileInputButton>
                                            <FileInput
                                                id="fileInput"
                                                type="file"
                                                accept="image/*,.pdf"
                                                multiple
                                                onChange={imageHandleChange}
                                            />
                                        </div>
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '0px', marginBottom: '15px', marginRight: '4%', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, photo: false })}>Cancel</RippleButton>
                                            <RippleButton onClick={async () => {
                                                await onPressAttachPhoto()
                                                setBoolValue({ ...boolValue, photo: false })
                                            }}>Save</RippleButton>
                                        </div>

                                    </div>
                                </> : (tempData?.images?.length > 0 || tempData?.notesPhone?.length > 0) ? <div className='valueContainer' style={{ flexDirection: 'column', }}>

                                    {(tempData?.images?.length > 0 ? [...tempData?.notesPhone, { description: '', images: tempData?.images }] : [...tempData?.notesPhone])?.map((item, index) => <>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', borderTop: index > 0 && '1px solid #ddd', paddingTop: '3px' }}>
                                            <p className='valueContainer' style={{ width: '68%', marginTop: '0px', marginBottom: '0px' }} >{item?.description || `Note #${index + 1}`}</p>
                                            <p style={{ marginTop: '8px', width: '30%', marginBottom: '0px' }} >{moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                                            {item?.pdf?.map((items) => <PhotosStyle onClick={() => {
                                               window.open(items?.link)
                                            }} src={pdf} />)}
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                                            {item?.images?.map((items) => <PhotosStyle onClick={() => {
                                                if(isImageUrl(items)){
                                                setBoolValue({ ...boolValue, photoVisible: true })
                                                setphotoData(item?.images)
                                                }
                                                else{
                                                    window.open(items)
                                                }
                                            }} src={isImageUrl(items)?items:pdf} />)}
                                        </div>
                                    </>
                                    )}

                                </div> : null}
                            </StyleBox> */}
                        </div>
                        <StyleRight>
                            {<Wrapper style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {
                                    statusData.map((item) => {
                                        const OutlinedComponent = item?.img
                                        return (
                                            <ProcessWrapper onClick={() => bookingStatus(item?.value)} style={{

                                                color: item?.value === isbookingStatus ? '#0F52BA' : '#000',
                                                border: item?.value === isbookingStatus ? '1.5px solid #0F52BA' : '1px solid #ddd'

                                            }}>
                                                <OutlinedComponent />
                                                <p className='text'>{item?.name}</p>
                                            </ProcessWrapper>
                                        )
                                    })
                                }
                            </Wrapper>}
                            {<CardContainer>
                                <CardContentStyled>
                                    <Header>
                                        Invoices
                                    </Header>
                                    <>
                                        {invoiceData?.length > 0 ?
                                            invoiceData.map((item, index) =>
                                                <div>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                        <div>
                                                            <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`Invoice #${item?.numberOfInvoice}`}  <span style={{ fontSize: '12px', marginTop: '3px' }}><span style={{ marginLeft: '5px', color: item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f' }}> ● </span>  {item?.invoiceStatus === 'Paid' ? item?.paymentMethod?.replace(/\(In-person\)/g, '') || item?.invoiceStatus : item?.invoiceStatus === 'Rejected' ? "Void" : item?.invoiceStatus}</span></h5>

                                                        </div>
                                                        <div>
                                                            <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'end' }}>{`$${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}`}</h5>
                                                            {item?.deposit && <Label>(${item?.deposit} Deposit Paid)</Label>}
                                                        </div>
                                                    </div>
                                                    <LineItemsToggle style={{ paddingBottom: expanded === index ? '0px' : '15px' }} onClick={() => handleChange(index)}>
                                                        {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosOutlined style={{ fontSize: '14px' }} />}
                                                        <h5>Line items</h5>
                                                    </LineItemsToggle>
                                                    {expanded === index && (
                                                        <LineItemsContainer>
                                                            {item?.items?.map((item, idx) => (
                                                                <LineItem key={idx}>
                                                                    <div>
                                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                        {item?.description && <p className="description">{item?.description}</p>}
                                                                    </div>
                                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                </LineItem>
                                                            ))}
                                                        </LineItemsContainer>
                                                    )}

                                                    {invoiceData?.length > 1 && index !== invoiceData?.length - 1 && <Divider style={{ color: palette.smokeWhite }} />}
                                                </div>) : <div style={{ margin: '20px', fontSize: '13px' }}>No Invoices </div>}
                                    </>
                                </CardContentStyled>
                            </CardContainer>
                            }
                            {<CardContainer>
                                <CardContentStyled>
                                    <Header>
                                        Quotes
                                    </Header>
                                    <>

                                        {quoteData?.length > 0 ?
                                            quoteData.map((item, index) =>

                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                        <div>
                                                            <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`Quote #${item?.numberOfQuote}`} <span style={{ marginLeft: '5px', fontSize: '12px', marginTop: '3px' }}><span style={{ color: item?.status === 'Waiting for confirmation' ? '#fa811f' : item?.status === 'Draft' ? '#586171' : item?.status === "Rejected" ? '#c0113d' : '#29740f' }}>● </span> {item?.status === 'Waiting for confirmation' ? 'Waiting' : item?.status === 'Rejected' ? 'Void' : item?.status}</span></h5>

                                                        </div>
                                                        <div>
                                                            <h5 style={{ marginBottom: '0px', marginTop: '15px', textAlign: 'start' }}>{`$${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}`}</h5>

                                                        </div>
                                                    </div>
                                                    <LineItemsToggle style={{ paddingBottom: expanded === index ? '0px' : '15px' }} onClick={() => handleChange(index)}>
                                                        {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosOutlined style={{ fontSize: '14px' }} />}
                                                        <h5>Line items</h5>
                                                    </LineItemsToggle>
                                                    {expanded === index && (
                                                        <LineItemsContainer>
                                                            {item?.items?.map((item, idx) => (
                                                                <LineItem key={idx}>
                                                                    <div>
                                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                        {item?.description && <p className="description">{item?.description}</p>}
                                                                    </div>
                                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                </LineItem>
                                                            ))}
                                                        </LineItemsContainer>
                                                    )}
                                                    {quoteData?.length > 1 && index !== quoteData?.length - 1 && <Divider style={{ color: palette.smokeWhite }} />}
                                                </div>) : <div style={{ margin: '20px', fontSize: '13px' }}>No Quotes </div>}
                                    </>
                                </CardContentStyled>
                            </CardContainer>
                            }
                            <CardContainer  >
                                <CardContentStyled>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <InputPicker
                                            value={values?.typeOfJob}
                                            data={
                                                userDetails?.jobType?.map((item) => (
                                                    { label: item, value: item }
                                                ))
                                            }
                                            label='Job type'
                                            onChange={(val) => updateData('typeOfJob', val)}
                                        />
                                        <InputPicker
                                            value={values?.priorityJob}
                                            data={
                                                userDetails?.jobPriority?.map((item) => (
                                                    { label: item, value: item }
                                                ))
                                            }
                                            label='Priority'
                                            onChange={(val) => updateData('priorityJob', val)}
                                        />
                                    </div>

                                    <div className='flexcontainer'>
                                        <h4 >{"Line items"}</h4>
                                        <div onClick={() => {
                                            setBoolValue({ ...boolValue, convert: true })
                                            setcheckedAddOwnerData([])
                                        }} className='button'>
                                            <Transform style={{ marginRight: '5px', fontSize: '18px' }} />
                                            <h5 >Convert</h5>
                                        </div>
                                    </div>

                                    {!loader && <LineItemComponent
                                        edit={boolValue}
                                        isEdit={true}
                                        onCancel={() => {
                                            init()
                                        }}
                                        value={async (val) => {

                                            if (true) {
                                                setAddOwnerData(val)
                                                onSubmitLineItem(val)
                                                await updateData('items', val)
                                            }
                                            else {
                                                setAddOwnerData(val)
                                            }

                                        }}
                                        items={[...addOwnerData]}
                                        serviceRequest={true}
                                    />}
                                </CardContentStyled>
                            </CardContainer>
                            <CardContainer  >
                                <CardContentStyled>
                                    <Header>
                                        Site visits
                                    </Header>
                                    <div style={{ width: '100%', marginTop: '20px' }}>
                                        <SiteVisit visits={tempData?.multiVist}
                                            referenceNo={id}
                                            onSubmit={async () => {
                                                const res1 = await dispatch(fetchbookingDetails(id))
                                                setTempData(res1?.data)

                                            }}
                                            onClickEdit={(item) => setBoolValue({ addVisit: true, visitValue: item })}

                                        />

                                        <VisitButton onClick={() => setBoolValue({ ...boolValue, addVisit: true, visitValue: {} })}>
                                            <h4>+ New visit</h4>
                                        </VisitButton>

                                    </div>
                                </CardContentStyled>
                            </CardContainer>
                            {<CardContainer>
                                <CardContentStyled>
                                    <Header>
                                        Photos / Attachments
                                        <AddPhotoAlternateOutlined style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => {
                                            setSelectedImage([])
                                            setBoolValue({ ...boolValue, photo: true })
                                        }} />
                                    </Header>
                                    {boolValue?.photo ? <>
                                        <div style={{ flexDirection: 'column', }}>
                                            <InputText
                                                placeholder="Note"
                                                type="text"
                                                style={{ width: '90%', marginLeft: '0px', marginTop: '20px' }}
                                                // value={editValue?.email}

                                                onChange={(e) => {
                                                    setValues({ ...values, attachmentDesc: e.target.value }

                                                    )
                                                }}

                                            />
                                            <h5 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'start', marginLeft: '10px' }}>Attach files</h5>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', }}>
                                                {selectedImage?.map((item) =>
                                                    <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                                        <PhotosStyle src={isImageUrl(item) ? item : pdf} />
                                                        <div
                                                            onClick={() => {
                                                                const arr = selectedImage?.filter(obj => obj !== item)
                                                                setSelectedImage(arr)
                                                            }}
                                                            style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                            <MoreIcon style={{ width: '15px' }} src={cross} />
                                                        </div>
                                                    </div>
                                                )}

                                                <FileInputButton htmlFor="fileInput">
                                                    <MoreIcon src={plus} />
                                                </FileInputButton>
                                                <FileInput
                                                    id="fileInput"
                                                    type="file"
                                                    accept="image/*,.pdf"
                                                    multiple
                                                    onChange={imageHandleChange}
                                                />
                                            </div>
                                            <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '0px', marginRight: '4%', justifyContent: 'flex-end', }}>
                                                <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, photo: false })}>Cancel</RippleButton>
                                                <RippleButton onClick={async () => {
                                                    await onPressAttachPhoto()
                                                    setBoolValue({ ...boolValue, photo: false })
                                                }}>Save</RippleButton>
                                            </div>

                                        </div>
                                    </> : (tempData?.images?.length > 0 || tempData?.notesPhone?.length > 0) ? <div className='valueContainer' style={{ flexDirection: 'column', }}>

                                        {(tempData?.images?.length > 0 ? [...tempData?.notesPhone, { description: '', images: tempData?.images }] : [...tempData?.notesPhone])?.map((item, index) => <>
                                            <div style={{ width: '100%', marginTop: '12px', display: 'flex', alignItems: 'flex-start', borderTop: index > 0 && '1px solid #ddd', paddingTop: '3px' }}>
                                                <p className='valueContainer' style={{ width: '70%', textAlign: 'start', fontSize: '13px', marginTop: '12px', marginBottom: '0px' }} >{item?.description || `Note #${index + 1}`}</p>
                                                <p style={{ width: '30%', marginBottom: '0px', fontSize: '13px', textAlign: 'end' }} >{moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                                                {item?.pdf?.map((items) => <PhotosStyle onClick={() => {
                                                    window.open(items?.link)
                                                }} src={pdf} />)}
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                                                {item?.images?.map((items) => <PhotosStyle onClick={() => {
                                                    if (isImageUrl(items)) {
                                                        setBoolValue({ ...boolValue, photoVisible: true })
                                                        setphotoData(item?.images)
                                                    }
                                                    else {
                                                        window.open(items)
                                                    }
                                                }} src={isImageUrl(items) ? items : pdf} />)}
                                            </div>
                                        </>
                                        )}

                                    </div> : null}
                                </CardContentStyled>
                            </CardContainer>}
                            <CardContainer>
                                <CardContentStyled>
                                    <Header>
                                        Job costing breakdown
                                        <IconButton style={{ marginTop: '-5px', width: '28px', height: '28px', background: '#f3f3f3' }}>
                                            {
                                                boolValue?.brekdownExpend ? <KeyboardArrowUp style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                                                    setBoolValue({ ...boolValue, brekdownExpend: false })
                                                }} /> : <KeyboardArrowDown style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                                                    setBoolValue({ ...boolValue, brekdownExpend: true })
                                                }} />
                                            }

                                        </IconButton>
                                    </Header>
                                    {boolValue?.brekdownExpend && <BreakdownComponent
                                        totalRevenue={5600}
                                        totalJobCosts={0}
                                        totalGrossProfit={5600}
                                        profitMargin={100}
                                        chartArray={calculateGrossProfit()}
                                    />}

                                </CardContentStyled>
                            </CardContainer>

                            <CardContainer  >
                                <CardContentStyled>
                                    <Header>
                                        Activity feed
                                        <IconButton style={{ marginTop: '-5px', width: '28px', height: '28px', background: '#f3f3f3' }}>
                                            {
                                                boolValue?.activityExpend ? <KeyboardArrowUp style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                                                    setBoolValue({ ...boolValue, activityExpend: false })
                                                }} /> : <KeyboardArrowDown style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                                                    setBoolValue({ ...boolValue, activityExpend: true })
                                                }} />
                                            }

                                        </IconButton>
                                    </Header>
                                    {boolValue?.activityExpend && <>
                                        {
                                            travelData?.jobTimer?.slice(0, 2)?.map((item, index) =>
                                                renderTravelItem(item, index)
                                            )
                                        }
                                        {travelData?.jobTimer?.length - 2 > 0 && <SendButton1 style={{ marginTop: '40px', width: '150px', borderRadius: '20px' }} onClick={async () => {
                                            setBoolValue({ ...boolValue, travelModal: true })
                                        }}>See all activity</SendButton1>}

                                        {travelData?.jobTimer?.length > 0 ? null : <div style={{ margin: '20px', fontSize: '13px' }}>No activity </div>}
                                    </>}

                                </CardContentStyled>
                            </CardContainer>


                        </StyleRight>
                    </Flexbox>

                    {boolValue?.travelModal && <Modals
                        onClose={() => setBoolValue({ ...boolValue, travelModal: false })}
                        customStyle={{ background: '#fff' }}
                    >
                        <CardContentStyled>
                            <Header>
                                Activity feed
                                <Cancel style={{ cursor: 'pointer' }} onClick={() => setBoolValue({ ...boolValue, travelModal: false })} />
                            </Header>
                            {
                                travelData?.jobTimer?.map((item, index) =>
                                    renderTravelItem(item, index)
                                )
                            }
                        </CardContentStyled>
                    </Modals>
                    }
                    <AddCustomField
                        selectedField={{}}
                        isVisible={isModalopen1}
                        type={'job'}
                        onClose={() => {
                            setIsModalOpen1(false)

                        }}
                        onSubmit={(value) => {
                            setDataCustomField([...customField, { ...value, type: 'job' }])
                            setCustomField([...selectCustomField, { fieldName: value?.fieldName, fieldType: value?.fieldType, options: value?.options, type: 'job' }])
                            setIsModalOpen1(false)
                        }}
                    />
                    {opendAddContact && <RightModal
                        isOpen={opendAddContact}
                        onClose={() => setOpenAddContact(false)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                            <h2>Add contact</h2>

                            <CloseButton onClick={() => setOpenAddContact(false)}>
                                <Icons src={cross} />
                            </CloseButton>
                        </div>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <AddContact
                                onSubmit={() => {
                                    init()
                                    setOpenAddContact(false)
                                }}
                                onCancel={() => {

                                    setOpenAddContact(false)
                                }}
                            />
                        </div>
                    </RightModal>}

                    {modalOpen && (
                        <FullScreenModal onClose={() => setModalOpen(false)}>

                            <CreateInvoice
                                {...props}
                                value={{ ...editValue, number: editValue?.to, items: checkedaddOwnerData, deposit: tempData?.bookingDeposit, depositType: tempData?.bookingDepositType, referenceNo: id }}
                                setModalOpenInvoice={() => {
                                    setModalOpen(false)
                                    init()
                                }}
                                booking={true}
                                onSubmitsw={(val, data) => {

                                    sendchat(val, data)
                                    init()
                                    setModalOpen(false)


                                }
                                }
                            />


                        </FullScreenModal>
                    )}
                    {boolValue?.calendarVisible && (
                        <FullScreenModal onClose={() => setBoolValue({ ...boolValue, calendarVisible: false })}>
                            <div style={{ display: 'flex', alignItems: 'center', background: 'white', height: 'auto', paddingLeft: '30px', paddingRight: '30px' }}>
                                <Cancel style={{ fontSize: '28px', marginRight: '10px', marginTop: '8px' }} onClick={() => setBoolValue({ ...boolValue, calendarVisible: false })} />
                                <h2 style={{ marginTop: '15px', marginBottom: '5px' }}>Calendar</h2>

                            </div>
                            <JobCalander
                                setValue={(val) => {
                                    setValues({ ...values, ...val })
                                    setBoolValue({ ...boolValue, calendarVisible: false, edit: true })

                                }}
                            />

                        </FullScreenModal>
                    )}
                    {modalOpen1 && (
                        <FullScreenModal onClose={() => setModalOpen1(false)}>
                            <CreateQuote
                                {...props}
                                value={{ ...editValue, number: editValue?.to, items: checkedaddOwnerData, referenceNo: id }}
                                setModalOpenInvoice={() => {
                                    setModalOpen1(false)
                                    init()
                                }}
                                booking={true}
                                onSubmitsw={(val, data) => {

                                    sendchat(val, data)
                                    init()
                                    setModalOpen1(false)


                                }
                                }
                            />
                        </FullScreenModal>
                    )}

                    {
                        edit && <TopModal
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                            onClose={() => {
                                setEdit(false)

                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4>
                                    {"Edit contact"}
                                </h4>
                                <Cancel onClick={() => {
                                    setEdit(false)
                                }} style={{ cursor: 'pointer' }} />
                            </div>
                            <div style={{ paddingLeft: '15px', paddingRight: '20px', width: '90%' }}>
                                <InputBox
                                    header={"Name"}
                                    placeholder="Name"
                                    type="text"
                                    value={editValue?.name}

                                    onChange={(e) => {
                                        setEditValue({
                                            ...editValue, name: e.target.value
                                        })
                                    }}

                                />
                                <InputBox
                                    header={"Business name"}
                                    placeholder="Business name"
                                    type="text"
                                    value={editValue?.businessName}

                                    onChange={(e) => {
                                        setEditValue({
                                            ...editValue, businessName: e.target.value
                                        })
                                    }}

                                />

                                <InputBox
                                    header={"Email"}
                                    placeholder="Email"
                                    type="text"
                                    value={editValue?.email}

                                    onChange={(e) => {
                                        setEditValue({
                                            ...editValue, email: e.target.value
                                        })
                                    }}

                                />

                                <Label style={{ marginTop: '20px', marginLeft: '3px' }} htmlFor={'Address'}>Address</Label>

                                <Place
                                    apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                    types={['address']}
                                    options={{
                                        types: ["address"],
                                    }}
                                    defaultValue={address?.address}
                                    style={{
                                        width: '100%',
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        height: '20px',
                                        fontWeight: '400',
                                        fontFamily: 'Inter, sans-serif',
                                        color: '#344054',
                                        alignSelf: 'start',
                                        background: '#ffffff',
                                        border: '0.5px solid #d0d5dd',
                                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                        padding: '10px 14px',
                                    }}
                                    placeholder='street 1'


                                    onPlaceSelected={(details) => {
                                        const street = `${details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('street_number')
                                        )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('route'),
                                        )?.short_name || ''}`;

                                        const zipCode = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('postal_code'),
                                        )?.short_name;
                                        const region = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('administrative_area_level_1'),
                                        )?.long_name;
                                        const city = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('locality'),
                                        )?.long_name;
                                        const country = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('country'),
                                        )?.long_name;
                                        setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address })


                                    }}

                                />


                                <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '-20px', justifyContent: 'flex-end', }}>
                                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                        setEdit(false)

                                    }}>Cancel</RippleButton>
                                    <RippleButton onClick={() => {
                                        editContact()
                                        setEdit(false)
                                    }}>Save</RippleButton>
                                </div>
                            </div>
                        </TopModal>
                    }
                    {boolValue?.addchecklist && <TopModal
                        onClose={() => setBoolValue({ ...boolValue, addchecklist: false })}
                        overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4>
                                {"Add checklist"}
                            </h4>
                            <Cancel onClick={() => {
                                setBoolValue({ ...boolValue, addchecklist: false })
                            }} style={{ cursor: 'pointer' }} />
                        </div>
                        <InputBox
                            header={'Checklist name'}
                            style={{ width: '95%' }}
                            placeholder='Checklist name'
                            value={addchecklist?.title}
                            onChange={(e) => setAddChecklist({ ...addchecklist, title: e.target.value })}
                        />
                        <h5 style={{ textAlign: 'start', margin: '15px 5px 0px 5px', fontWeight: '500' }}>Checklist item</h5>
                        {checklistItemData?.map((item, index) => <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                            <InputPicker
                                value={item?.type}
                                label={'Type'}
                                style={{ height: '40px', background: 'white' }}
                                data={[
                                    { label: 'Checkbox', value: 'Checkbox' },
                                    { label: 'Text', value: 'Text' },
                                    { label: 'Link', value: 'Link' },
                                ]}
                                onChange={(val) => {
                                    const data = [...checklistItemData]
                                    data[index].type = val
                                    setChecklistItemData([...data])
                                }}
                            />
                            <InputBox
                                style={{ width: '85%' }}
                                placeholder='Item'
                                value={item?.value}
                                onChange={(e) => {
                                    const data = [...checklistItemData]
                                    data[index].value = e.target.value
                                    setChecklistItemData([...data])
                                }}
                            />
                            <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                const data = [...checklistItemData]
                                data.splice(index, 1)
                                setChecklistItemData(data)
                            }} />
                        </div>)}
                        <h4 onClick={() => {
                            setChecklistItemData([...checklistItemData,
                            {
                                type: '',
                                value: '',
                            }
                            ])
                        }} style={{ textAlign: 'start', color: palette.newBlue, cursor: 'pointer' }}>+ Checklist item</h4>
                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', justifyContent: 'flex-end', }}>
                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                setBoolValue({ ...boolValue, addchecklist: false })
                            }}>Cancel</RippleButton>
                            <RippleButton onClick={async () => {
                                const res1 = await dispatch(submitchecklist({ ...addchecklist, 'data': checklistItemData }))
                                setSelectedChecklist([...selectedChecklist, { ...addchecklist, 'data': checklistItemData, "checklistNo": res1?.data?.checklistNo }])
                                setBoolValue({ ...boolValue, addchecklist: false })
                            }}>Save</RippleButton>
                        </div>
                    </TopModal>}
                    {boolValue?.isChecklist &&
                        <TopModal
                            onClose={() => setBoolValue({ ...boolValue, isChecklist: false })}
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4>
                                    {"Checklist"}
                                </h4>
                                <Cancel onClick={() => {
                                    setBoolValue({ ...boolValue, isChecklist: false })
                                }} style={{ cursor: 'pointer' }} />
                            </div>
                            {checklist?.map((item, index) => <div onClick={() => {
                                const isItemSelected = selectedChecklist?.some(items => items?.checklistNo === item?.checklistNo);
                                if (isItemSelected) {
                                    const data = selectedChecklist.filter(items => items?.checklistNo !== item?.checklistNo);
                                    setSelectedChecklist(data);
                                } else {
                                    const data = [...selectedChecklist, item];
                                    setSelectedChecklist(data);
                                }
                            }} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '15px', cursor: 'pointer' }}>
                                <Checkbox style={{ width: '25px', marginTop: '-12px' }} checked={selectedChecklist?.some(items => items?.checklistNo === item?.checklistNo)} />
                                <div>
                                    <Label>{item?.title}</Label>
                                    <Label style={{ color: palette.grey }}>{item?.data?.length} checklist items</Label>
                                </div>
                            </div>
                            )}
                            <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', alignItems: 'center', marginBottom: '20px', justifyContent: 'flex-end', }}>
                       
                                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                        setBoolValue({ ...boolValue, isChecklist: false })
                                    }}>Cancel</RippleButton>
                                    <RippleButton onClick={async () => {
                                        await updateData('checklistNo', selectedChecklist)
                                        setBoolValue({ ...boolValue, isChecklist: false })
                                    }}>Save</RippleButton>
                                </div>
                
                        </TopModal>
                    }
                    {
                        boolValue?.convert && <TopModal
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                            onClose={() => {
                                setBoolValue({ ...boolValue, convert: false })
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4>
                                    {"Select item"}
                                </h4>
                                <Cancel onClick={() => {
                                    setBoolValue({ ...boolValue, convert: false })
                                }} style={{ cursor: 'pointer' }} />
                            </div>
                            {addOwnerData?.map((item, index) =>
                                <LineItem style={{ background: 'none' }} >
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        {<Checkbox
                                            style={{ width: '25px', display: 'flex', marginTop: '-10px' }}
                                            checked={checkedaddOwnerData.some((el) => el.ids === index)}
                                            onChange={() => handleCheckboxChange({ ...item, ids: index })}
                                        />}

                                        <div>
                                            <p className="name">{item?.name} × {item?.quantity || '1'} {item?.optional ? '(Optional)' : null}</p>
                                            {/* {item?.description&& <p className="description">{item?.description}</p>} */}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))} </p>
                                    </div>
                                </LineItem>
                            )}
                            <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', justifyContent: 'flex-end', }}>
                                <RippleButton style={{ marginRight: '15px' }} onClick={() => {
                                    setModalOpen1(true)
                                    setBoolValue({ ...boolValue, convert: false })
                                }}>Convert to quote</RippleButton>
                                <RippleButton style={{ marginRight: '0px' }} onClick={() => {
                                    setModalOpen(true)
                                    setBoolValue({ ...boolValue, convert: false })
                                }}>Convert to invoice</RippleButton>
                            </div>

                        </TopModal>
                    }
                    {
                        boolValue?.delete && <TopModal
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                            onClose={() => setBoolValue({ ...boolValue, delete: false })}
                        >
                            <DeleteModal
                                value={{
                                    delete: true,
                                    val: 'Rejected',
                                    item: tempData,
                                    type: 'Job',

                                }}
                                onCancel={() => setBoolValue({ ...boolValue, delete: false })}
                                onSubmit={(val) => {
                                    setBoolValue({ ...boolValue, delete: false })
                                    navigate(-1)
                                }}
                            />
                        </TopModal>
                    }
                    {boolValue?.photoVisible && <ImageCarousel
                        visible={boolValue?.photoVisible}
                        images={photodata || []}
                        onClose={() => setBoolValue({ ...boolValue, photoVisible: false })}
                    />}

                    {/* ///adding site Visit */}


                    {boolValue?.addVisit &&
                        <TopModal
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                            customStyle={{ width: '370px', maxHeight: '90%', background: '#fff' }}
                            onClose={() => setBoolValue({ ...boolValue, addVisit: false })}
                        >
                            <AddSiteVisit
                                referenceNo={id}
                                header={'Add visit'}
                                team={team}
                                value={boolValue?.visitValue}
                                onClose={() => setBoolValue({ ...boolValue, addVisit: false })}
                                onSubmit={() => {

                                    setBoolValue({ ...boolValue, addVisit: false })
                                    init()
                                }}
                            />
                        </TopModal>
                    }
                    {boolValue?.ViewChecklist &&
                        <RightModal
                            isOpen={boolValue?.ViewChecklist}
                            customStyle={{paddingLeft:'0px',paddingRight:'0px',background:'#fff'}}

                            onClose={() => setBoolValue({ ...boolValue, ViewChecklist: false, checklistData: [] })}
                        >
                            <ViewChecklist
                                filledData={boolValue?.filledData}
                                data={boolValue?.checklistData}
                                bookingNo={tempData?.referenceNo}
                                onClose={() => setBoolValue({ ...boolValue, ViewChecklist: false, checklistData: [] })}
                            />
                        </RightModal>
                    }


                </Main>
            }
            {/* {
                boolValue?.viewChecklist && <RightModal
                    isOpen={boolValue?.viewChecklist}
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    customStyle={{ background: 'white' }}
                    onClose={() => setBoolValue({ ...boolValue, viewChecklist: false })}
                >
                    <ViewChecklist
                        filledData={selectedViewchecklist?.filledData}
                        data={selectedViewchecklist?.data}
                        bookingNo={id}
                        setSubmit={() => {
                            setBoolValue({ ...boolValue, viewChecklist: false })
                            init()
                        }}
                    />
                </RightModal>
            } */}
        </Body>
    )
}

export default JobDetails


const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const Main = styled.div`
padding-left:25px;
padding-right:25px;
width:100%;
height:99vh;
overflow-y:scroll;
&::-webkit-scrollbar {
    width: 0;
    height: 0;
  }


`
const ProcessWrapper = styled.div`
display:flex;
flex-direction:column;
background:#fff;
justify-content:center;
margin-bottom:-40px;
border:1px solid #ddd;
align-items:center;
width:100px;
padding-top:10px;
padding-bottom:10px;
height:60px;
margin-left:-15px;
border-radius:15px;
cursor:pointer;
.text{
    margin-top:10px;
    margin-bottom:0px;
    font-size:13px;
    font-weight:500;
}
`
const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;


const Wrapper = styled.div`
display:block;
width:90%;
align-self:center;

margin-bottom:50px;
margin-left:30px;
margin-right:10px;
box-size:border-box;
flex-wrap:wrap;
border-bottom:1px solid #ddd;
.inputwrapper{
    width:41%;
    @media (min-width: 260px) and (max-width: 721px){
        width:38.5%;
       
        }
}
.button{
    margin-left:230px;
    width:300px;
}
@media (min-width: 260px) and (max-width: 721px){
    width:90%;
    
    }

`
const ValueName = styled.p`
color:${palette.charcoleBlack};

font-size: 14px;
text-align:start;
margin-top:0px;
margin-bottom:12px;
width:100%;

flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const CardContainer = styled.div`
  box-shadow:0px 4px 16px rgba(30, 30, 30, 0.05);
  margin-top: 20px;
  border: 1px solid #f3f3f3;

`;

const CardContentStyled = styled(CardContent)`
  padding: 15px;

  .flexcontainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-right: 10px;
    width: 100%;
  }
  h4{
    margin-bottom: 0;
  }
  .button{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${palette.newBlue};
  }
`;
const EditContainer = styled.div`

border-left:1px solid  ${palette.smokeWhite};
display:flex;
align-items:center;
padding:10px;
cursor:pointer;

&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`
const LineItemsToggle = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
  h5 {
    text-align: start;
    margin: 0;
  }
  svg {
    margin-right: 5px;
  }
`;

const LineItemsContainer = styled.div`
  padding: 0 15px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-bottom:0px;
  
    }
    &.description {
      color: gray;
      margin-top:0px;
      text-align:start;
      margin-bottom:5px;
      line-height:16px;
      white-space:pre-line;
    }
    &.price {
      font-weight: 550;
    }
  }
`;

const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-bottom:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:60px;
  height:60px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 60px;
height: 60px;
cursor:pointer;

`

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const MoreIcon = styled.img`
width:15px;
cursor:pointer;
color:black
`

const CustomBox = styled.div`
display:flex;
flex-wrap:wrap;
margin-top:15px;
margin-left:12px;
width:285px;

`

const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: start;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 5px;
  .fieldName{
    width: 42%;
    font-weight: 600;
    font-size: 13px;
    text-align: start;
    line-height:16px;
    flex-wrap:wrap;
    word-break:break-all;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .fieldValue{
    width: 56%;
    font-size: 13px;
    line-height:16px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
  }
`;
const HeaderContainer = styled.div`
display:flex;
height:auto;
justify-content:space-between;
align-items:center; 
h3{
    margin-top:15px;
    margin-bottom:0px;
}
`
const Place = styled(Autocomplete)`
height:30px;
width:296px;
border-radius:4px;
border:1px solid lightgray;
font-weight:400;
padding-left:12px;
@media (min-width: 260px) and (max-width: 721px){
    width:85%;
    }
&::placeholder {
  font-size:14px;
  font-weight:400;
}
&:focus {
outline:none;
border:1px solid lightgray;
}
`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`

const Icons = styled.img`
width: 15px;
height: 15px;
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid ${palette.smokeWhite};
background:white;
width:350px;
margin-top:20px;
border-radius:4px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
h5{
    margin-top:0px;
    margin-bottom:0px;
}
p{
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left:10px;
    margin-right:10px;
    line-height:16px;
}
.keyContainer{
    padding: 0px 0px 0px 10px;
    font-size:16px;
    font-weight:500;
    color:${palette.black};
    background:#f9f9f9;
display:flex;
justify-content:space-between;
border-bottom:1px solid ${palette.smokeWhite};
align-items:center;
}
.valueContainer{
    padding:7px;
    
    background:#fff;
    font-size:14px;
  
display:flex;
}
`
const StyleRight = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
overflow-y:scroll;

width:90%;
margin-right:10px;
margin-left:30px;
margin-top:20px;
height:auto;
border-radius:8px;



`
const WrapperField = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
`;
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;


const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-size: 15px;
  font-weight: 600;
`;

const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
margin-top:15px;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const OptionBox = styled.div`
width:100px;
height:35px;
border-radius:8px;
border:1px solid #bbb;

display:flex;

font-size:14px;

align-items:center;
justify-content:center;
margin-left:30px;

font-weight:500;
cursor:pointer;

`

const Flexbox = styled.div`
display:flex;
height:auto;
margin-top:20px;
`

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  margin-top:10px;
  margin-bottom:10px;
  margin-left:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const WrapperHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    margin:5px;
    .text{
        font-size:13px;
        text-align:start;
        margin-top:0px;
        margin-bottom:5px;
        line-height: 16px;

    }
`;

const VisitButton = styled.div`
  border: 1.5px solid #0F52BA;
  max-width: 120px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 7px 12px;
  border-radius: 20px;
  cursor: pointer;

  h4{
    text-align: start;
    font-size: 13px;
    margin: 0;
    color: ${palette.newBlue};
    cursor: pointer;
  }
`;
const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
