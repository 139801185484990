export const formatMobileNumber = (text) => {
  var cleaned = ("" + text)?.replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var number = [match[1] ? `+${match[1]}` : match[1], " (", match[2], ") ", match[3], "-", match[4]].join(
      ""
    );
    return number;
  }
  return text;
}
export const putCommaInvalue = (price) => {

  const cleanText1 = price?.toString()?.replace(/,/g, '');
  const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formatted;
}
export const totalprice = (price, quantity, tax) => {

  const cleanText = price?.toString()?.replace(/,/g, '');
  const taxes = ((parseFloat(price ? price : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)
  const fromatedvalue = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) + taxes;


  return fromatedvalue
}
export const taxprice = (price, quantity, tax) => {
  const cleanText = price?.toString()?.replace(/,/g, '');
  const taxes = ((parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)

  return taxes;
}
export const calculateTotalTaxAmount = (items, discountAmount) => {
  // Calculate the total price of all items
  const totalOriginalPrice = items?.reduce((acc, item) => {
      const price = parseFloat(item?.price);
      const quantity = parseInt(item?.quantity);
      if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
          return acc;
      }
      return acc + (price * quantity);
  }, 0);

  let totalTaxAmount = 0;

  // Calculate the weightage and apply the discount accordingly
  items?.forEach(item => {
      const taxAmount = parseFloat(item?.tax);
      const price = parseFloat(item?.price);
      const quantity = parseInt(item?.quantity);

      if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
          return;
      }

      const totalPrice = price * quantity;
      const weightage = totalPrice / totalOriginalPrice;
      const discountForItem = discountAmount * weightage;
      const discountedPrice = totalPrice - discountForItem;

      if (!isNaN(taxAmount) && taxAmount > 0) {
          const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places
          totalTaxAmount += taxValue;
      }
  });

  return parseFloat(totalTaxAmount.toFixed(2)); // Ensure rounding to 2 decimal places
};

export const getName = (channel) => {
  if (!channel) {
      return
  }
  const val = channel?.split(' ')

  if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
  }
  else {
      return `${val[0]?.charAt(0).toUpperCase()}`
  }
}


export const TimeListAm = [
  '6:00 AM', '6:30 AM',
  '7:00 AM', '7:30 AM',
  '8:00 AM', '8:30 AM',
  '9:00 AM', '9:30 AM',
  '10:00 AM', '10:30 AM',
  '11:00 AM', '11:30 AM',
  '12:00 PM'
];

export const TimeListPM = [
  '12:30 PM',
  '1:00 PM', '1:30 PM',
  '2:00 PM', '2:30 PM',
  '3:00 PM', '3:30 PM',
  '4:00 PM', '4:30 PM',
  '5:00 PM', '5:30 PM',
  '6:00 PM', '6:30 PM',
  '7:00 PM', '7:30 PM',
  '8:00 PM', '8:30 PM',
  '9:00 PM'
];


export const colorArray = [




 
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9", "#FFA07A", "#FF7F50", "#FF6347",
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FFD700", "#FFA500", "#E68A00", "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9", "#FFA07A", "#FF7F50", "#FF6347",
];
export const dayViewColor=[
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#DC143C", "light": "#FFB4BA" },
  { "original": "#CD5C5C", "light": "#FFCECE" },
  { "original": "#8B4513", "light": "#D6A681" },
  { "original": "#A52A2A", "light": "#FFB1B1" },
  { "original": "#D2691E", "light": "#FFCBA2" },
  { "original": "#DEB887", "light": "#FFEDD7" },
  { "original": "#F4A460", "light": "#FFE5D2" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#E9967A", "light": "#FFE7DC" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF7F50", "light": "#FFDFC8" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#DC143C", "light": "#FFB4BA" },
  { "original": "#CD5C5C", "light": "#FFCECE" },
  { "original": "#8B4513", "light": "#D6A681" },
  { "original": "#A52A2A", "light": "#FFB1B1" },
  { "original": "#D2691E", "light": "#FFCBA2" },
  { "original": "#DEB887", "light": "#FFEDD7" },
  { "original": "#F4A460", "light": "#FFE5D2" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#E9967A", "light": "#FFE7DC" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FFD700", "light": "#FFF9D5" },
  { "original": "#FFA500", "light": "#FFE6B3" },
  { "original": "#E68A00", "light": "#FFDFAA" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF7F50", "light": "#FFDFC8" },
  { "original": "#FF6347", "light": "#FFDACF" }
]

export const calendarTeamColor=[
  { "original": "#66a8a9", "light": "#c1fbfb" },
  { "original": "#FFA500", "light": "#FFE6B3" },
  { "original": "#8458B3", "light": "#d0bdf4" },
  { "original": "#c89666", "light": "#E4C5A0" },
  { "original": "#c74a7c", "light": "#fd83b7" },
  { "original": "#D1B58A", "light": "#EDE0C7" },
  { "original": "#0025B3", "light": "#96B3E5" },
  { "original": "#596A80", "light": "#B4C0D0" },
  { "original": "#3B5627", "light": "#A4C59B" },
  { "original": "#A0E066", "light": "#CFF0A7" },
  { "original": "#71C957", "light": "#B7E5A4" },
  { "original": "#660E99", "light": "#C591D8" },
  { "original": "#D168D5", "light": "#E6B8E9" },
  { "original": "#121212", "light": "#707070" },
  { "original": "#A5A5A5", "light": "#D6D6D6" }
]




export const linethemes = [
  "#2E3A59", // Midnight Blue
  "#4B88A2", // Steel Blue
  "#A5BE00", // Olive Lime
  "#FFB627", // Vibrant Amber
  "#F5E6CA", // Champagne
  "#FF6978", // Coral Red
  "#37474F", // Charcoal Gray
  "#CBAACB"  // Lavender Blush

]

export const colorThemes = [
  

  { id: 1, background: "#FFF4CC", text: "#4A3D00", outline: "#4A3D00" },
  { id: 2, background: "#FFE5D9", text: "#5A3A2E", outline: "#5A3A2E" },
  { id: 3, background: "#FFE6F0", text: "#60294A", outline: "#60294A" },
  { id: 4, background: "#E5D9F2", text: "#372D56", outline: "#372D56" },
  { id: 5, background: "#D9EAF2", text: "#2A4A5E", outline: "#2A4A5E" },
  { id: 6, background: "#D9F2E5", text: "#2A4A39", outline: "#2A4A39" },
  { id: 7, background: "#F2E5D9", text: "#4D3A2C", outline: "#4D3A2C" },
  { id: 8, background: "#F2D9E6", text: "#5E2B4A", outline: "#5E2B4A" },
  { id: 9, background: "#FCE8D5", text: "#4A2F0F", outline: "#4A2F0F" },
  { id: 10, background: "#EBF2D9", text: "#354926", outline: "#354926" },
  { id: 11, background: "#F2DDE0", text: "#5A2E37", outline: "#5A2E37" },
  { id: 12, background: "#F2F2F2", text: "#333333", outline: "#333333" },
  { id: 13, background: "#ECE5F2", text: "#3F2C4D", outline: "#3F2C4D" },
  { id: 14, background: "#E5F2F9", text: "#2A4B58", outline: "#2A4B58" },
  { id: 15, background: "#F9E8E8", text: "#502A2A", outline: "#502A2A" },
  { id: 16, background: "#F2E5CE", text: "#4A3929", outline: "#4A3929" },
  { id: 17, background: "#F2F5E5", text: "#394A2A", outline: "#394A2A" },
  { id: 18, background: "#E5F2E5", text: "#2A4A3A", outline: "#2A4A3A" },
  { id: 19, background: "#F8E5F2", text: "#4A294A", outline: "#4A294A" },
  { id: 20, background: "#E5DDF2", text: "#3A2958", outline: "#3A2958" }
];



export const colorStatusArray = [
  '#A5B5FF', '#FFD4A5', '#A5D4FF', '#FFAAC4', '#AAC4FF', '#D1A5FF', '#FFB5D1',
  '#B5E6FF', '#FFD1B5', '#A5FFA5', '#FFB3BA', '#A3FFC4', '#B3FFFF', '#FFDFBA',
  '#BAE1FF', '#D4A5A5', '#A5D4D4', '#33FF57', '#3357FF', '#FF33A6', '#33FFF0',
  '#FFC733', '#C4A3FF', '#C733FF', '#A3C4FF',  '#FFC4C4', '#C4FFA5',
  '#A5A3FF', '#A3FFA5', '#C4D4A5', '#E6FFB5', '#BFFCC6', '#FF6347', '#FFC4A3',
  '#FFA6C4',  '#A5C4AA', '#C4AFAA', '#AAF3C4', '#C4AFFA',
  '#FAA4D4', '#FAD4A4',
];

export const  cardBrandImages = {
  visa: "https://cdn-icons-png.flaticon.com/512/179/179457.png",
  mastercard: "https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg",
  amex: "https://cdn-icons-png.flaticon.com/512/179/179431.png",
  discover: "https://cdn-icons-png.flaticon.com/512/179/179473.png",
  diners: "https://cdn-icons-png.flaticon.com/512/179/179468.png",
  jcb: "https://cdn-icons-png.flaticon.com/512/179/179479.png",
  unionpay: "https://cdn-icons-png.flaticon.com/512/5557/5557325.png",
  unknown: "https://cdn-icons-png.flaticon.com/512/179/179431.png", 
};


export const palettes = [
  { backgroundColor: "#4A3D00", textColor: "#FFF4CC" },
  { backgroundColor: "#5A3A2E", textColor: "#FFE5D9" },
  { backgroundColor: "#60294A", textColor: "#FFE6F0" },
  { backgroundColor: "#372D56", textColor: "#E5D9F2" },
  { backgroundColor: "#2A4A5E", textColor: "#D9EAF2" },
  { backgroundColor: "#2A4A39", textColor: "#D9F2E5" },
  { backgroundColor: "#4D3A2C", textColor: "#F2E5D9" },
  { backgroundColor: "#5E2B4A", textColor: "#F2D9E6" },
  { backgroundColor: "#4A2F0F", textColor: "#FCE8D5" },
  { backgroundColor: "#354926", textColor: "#EBF2D9" },
  { backgroundColor: "#5A2E37", textColor: "#F2DDE0" },
  { backgroundColor: "#333333", textColor: "#F2F2F2" },
  { backgroundColor: "#3F2C4D", textColor: "#ECE5F2" },
  { backgroundColor: "#2A4B58", textColor: "#E5F2F9" },
  { backgroundColor: "#502A2A", textColor: "#F9E8E8" },
  { backgroundColor: "#4A3929", textColor: "#F2E5CE" },
  { backgroundColor: "#394A2A", textColor: "#F2F5E5" },
  { backgroundColor: "#2A4A3A", textColor: "#E5F2E5" },
  { backgroundColor: "#4A294A", textColor: "#F8E5F2" },
  { backgroundColor: "#3A2958", textColor: "#E5DDF2" }
];