import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addHasgtag, addNumber } from '../../store/Actions/dashboard.action'
import { colorThemes } from '../../utils/Commonfunction'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Autocomplete from 'react-google-autocomplete';
import InputBox from '../../ReuseableComponets/InputBox'
import { palette } from '../../utils/Palette'
import Checkbox from '../../ReuseableComponets/CheckBox'
import RippleButton from '../../ReuseableComponets/RippleButton'
import CustomizedAutocomplete from '../../ReuseableComponets/CustomAutoComplete'
import { Close, DescriptionOutlined, HomeOutlined, PersonOutlined } from '@mui/icons-material'
function AddContact(props) {
    const [phoneNumber, setPhoneNumber] = useState(props?.value?.to || '');
    const phoneInputRef = useRef(null);
    const [values, setValues] = useState({
        "email": "",
        "name": "",
        "firstName": '',
        "lastName": '',
        "phoneNumber": "",
        "profilePhoto": "",
        "businessName": '',
        "address": props?.value?.address || '',
        "notes": '',

    })
    const [address, setAddress] = React.useState({

        "street": props?.value?.street || "",
        "city": props?.value?.city || "",
        "state": props?.value?.state || "",
        "postalCode": props?.value?.postalCode || "",
        "country": props?.value?.country || "",

    })
    const [addPhoneData, setPhoneData] = useState([])
    const [addEmailData, setEmailData] = useState([])
    const [addAddressData, setAddressData] = useState([])
    const [toogle, setToggle] = useState(false)
    const [onFocus, setOnfocus] = useState(false)
    const [error, setError] = useState('')
    const [errorname, setErrorName] = useState('')
    const [valuess, setValuess] = useState('');
    const [addressValue, setAddressValue] = useState(props?.value?.address || '');
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
    const [hastTag, setHasTag] = useState(hashTags)
    const [selectHash, setSelectedHash] = useState([])

    const dispatch = useDispatch()
    const onPress = async () => {

        if (!values?.firstName) {
            return setErrorName(true)
        }
        const status = props?.value?.name ? {
            status: {
              value: props?.value?.name,
              icon: props?.value?.icon,
              color:props?.value?.color,
             
            },
            assignedUser:props?.value?.assignedUsers?.length>0?props?.value?.assignedUsers[0]?._id:''

          } : {};
        const res = await dispatch(addNumber({
            ...values, displayName: toogle, phoneNumber: phoneNumber, mainAddress: address, tags: selectHash, "name": `${values?.firstName || ''} ${values?.lastName || ''}`?.trim(), 'address': addressValue, ...status
        }))
        if (res.status === true) {
            // props?.onCancel()
            props?.onSubmit({ ...values, phoneNumber: phoneNumber, address: addressValue, number: phoneNumber,"name": `${values?.firstName || ''} ${values?.lastName || ''}`?.trim(), '_id': res?.data?.contact?.[0]?._id })

        }
        else {
            setError(res?.meesage)
        }

    }
    const getName = (channel) => {
        if (!channel) {
            return 'A'
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const addPhoneFiled = () => {
        setPhoneData([...addPhoneData,
        {
            phoneNumber: '',
            default: '',
        }
        ])
    }
    const deleteHandler = (index) => {
        const data = [...addPhoneData]
        data.splice(index, 1)
        setPhoneData(data)
    }
    const addEmailFiled = () => {
        setEmailData([...addEmailData,
        {
            email: '',
            default: '',
        }
        ])
    }
    const deleteEmailHandler = (index) => {
        const data = [...addEmailData]
        data.splice(index, 1)
        setEmailData(data)
    }
    const addAddressFiled = () => {
        setAddressData([...addAddressData,
        {
            street: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',
            default: '',
        }
        ])
    }
    const deleteAddressHandler = (index) => {
        const data = [...addAddressData]
        data.splice(index, 1)
        setAddressData(data)
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = "Are you sure you want to leave? Changes you made may not be saved.";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);



    return (
        <Main>
            <HeaderContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CloseButton onClick={() => props?.onCancel(false)}>
                        <Close style={{ fontSize: '20px' }} />
                    </CloseButton>
                    <h3>New contact</h3>
                </div>
                <div>
                    <RippleButton style={{ marginRight: '40px' }} onClick={async () => await onPress()}>Save</RippleButton>
                </div>
            </HeaderContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>

                <div style={{ width: '45%' }}>

                    {props?.value?.name && <ClientDetails style={{ display: 'flex', alignItems: 'center', padding: '5px', justifyContent: 'center', background: '#ddd', maxWidth: '300px', borderRadius: '8px', alignSelf: 'self-start', marginBottom: '30px' }}>
                        <img
                            src={props?.value?.icon}
                            alt={props?.value?.icon}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        {props?.value?.name}
                    </ClientDetails>}
                    <ClientDetails>
                        <Profile>{
                            <PersonOutlined />
                        }</Profile>
                        Client details
                    </ClientDetails>


                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"First name"}
                                value={values?.firstName}
                                onFocus={() => {
                                    if (errorname) {
                                        setErrorName(false)
                                    }
                                    setOnfocus(true)
                                }}
                                style={{ display: 'flex', alignSelf: 'center', border: errorname && '1px solid red' }}
                                id="outlined-basic"
                                size="small"
                                placeholder="First name"
                                variant="outlined"
                                onChange={(e) => setValues({ ...values, firstName: e.target.value })} />
                        </div>

                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"Last name"}
                                value={values?.lastName}
                                onFocus={() => setOnfocus(true)}

                                id="outlined-basic" size="small"
                                placeholder="Last name"
                                variant="outlined"
                                onChange={(e) => setValues({ ...values, lastName: e.target.value })} />
                        </div>
                    </div>
                    {errorname && <Label style={{ color: 'red', marginTop: '10px' }}>{`⚠ ${"First name is required."}`}</Label>}
                    <InputBox
                        header={"Business name"}
                        value={values?.businessName}

                        id="outlined-basic" size="small"
                        placeholder="Business name"
                        variant="outlined"
                        onChange={(e) => setValues({ ...values, businessName: e.target.value })} />



                    <Checkbox

                        checked={toogle}
                        onChange={(e) => setToggle(e.target.checked)}
                        label='Use company name as the primary name'
                    />
                    <h4>Contact Details</h4>
                    <Label >Phone number</Label>
                    <div className='phoneInput'>

                        <PhoneFiled
                            ref={phoneInputRef}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(val) => {
                                if (error) {
                                    setError('')
                                }
                                setPhoneNumber(val)
                            }}
                            error={error}
                            defaultCountry="US"
                            countryCallingCodeEditable={false}
                            style={{
                                width: '100%', borderRadius: '5px', '&.PhoneInputInput': {
                                    border: error && '1px solid red'
                                }
                            }}

                            international
                            focusInputOnCountrySelection='true'
                            tabIndex="0"
                        />
                    </div>
                    {error && <Label style={{ color: 'red' }}>{`⚠ ${error}`}</Label>}


                    <div >

                        <InputBox
                            header={"Email Address"}
                            value={values?.email}

                            style={{ display: 'flex', }}
                            id="outlined-basic" size="small" placeholder="Email" variant="outlined"
                            onChange={(e) => setValues({ ...values, email: e.target.value })}
                        />
                    </div>


                </div>
                <div style={{ width: '45%', paddingRight: '30px' }}>
                    <ClientDetails>
                        <Profile>{
                            <HomeOutlined />
                        }</Profile>
                        Address details
                    </ClientDetails>

                    <Label style={{ marginTop: '30px' }} htmlFor={'Address'}>Street 1</Label>


                    <Place
                        apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                        types={['address']}
                        options={{
                            types: ["address"],
                        }}
                        style={{
                            width: '100%',
                            borderRadius: '5px',
                            fontSize: '14px',
                            height: '20px',
                            fontWeight: '400',
                            fontFamily: 'Inter, sans-serif',
                            color: '#344054',
                            background: '#ffffff',
                            border: '0.5px solid #d0d5dd',
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            padding: '10px 14px',
                        }}
                        placeholder='Street 1'

                        //onClick={onPlaceSelected}
                        onChange={(e) => {
                            console.log(e.target.value, "eee")
                            setAddress({ ...address, street: e.target.value })
                        }}
                        onPlaceSelected={(details) => {
                            const street = `${details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('street_number')
                            )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('route'),
                            )?.short_name || ''}`;

                            const zipCode = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('postal_code'),
                            )?.short_name;
                            const region = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('administrative_area_level_1'),
                            )?.long_name;
                            const city = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('locality'),
                            )?.long_name;
                            const country = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('country'),
                            )?.long_name;
                            setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country })
                            console.log(details, zipCode, city, street, "details")
                            setAddressValue(details?.formatted_address)
                            // setValues({ ...values, address: details?.formatted_address })
                        }}
                        defaultValue={address?.street}
                        value={address?.street}
                    />
                    <InputBox
                        header={"Street 2"}
                        value={address?.street2}
                        onFocus={() => setOnfocus(true)}
                        style={{ display: 'flex', alignSelf: 'center', }}
                        id="outlined-basic"
                        size="small"
                        placeholder="Street 2"
                        variant="outlined"
                        onChange={(e) => setAddress({ ...address, street2: e.target.value })} />
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"City"}
                                value={address?.city}
                                onFocus={() => setOnfocus(true)}
                                style={{ display: 'flex', alignSelf: 'center', }}
                                id="outlined-basic"
                                size="small"
                                placeholder="City"
                                variant="outlined"
                                onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                        </div>
                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"Province"}
                                value={address?.state}
                                onFocus={() => setOnfocus(true)}

                                id="outlined-basic" size="small"
                                placeholder="Province"
                                variant="outlined"
                                onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"Postal code"}
                                value={address?.postalCode}
                                onFocus={() => setOnfocus(true)}
                                style={{ display: 'flex', alignSelf: 'center', }}
                                id="outlined-basic"
                                size="small"
                                placeholder="Postal code"
                                variant="outlined"
                                onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
                        </div>
                        <div style={{ width: '45%' }}>

                            <InputBox
                                header={"Country"}
                                value={address?.country}
                                onFocus={() => setOnfocus(true)}

                                id="outlined-basic" size="small"
                                placeholder="Country"
                                variant="outlined"
                                onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                        </div>
                    </div>
                    {/* {
                        addAddressData?.map((item, index) =>

                            <>
                                <div style={{ borderTop: '1px solid #ddd', width: '100%', alignItems: 'flex-end', }}>
                                    <Label style={{ marginTop: '20px' }} htmlFor={'Address'}>Street</Label>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Place
                                        apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                        types={['address']}
                                        options={{
                                            types: ["address"],
                                        }}
                                        style={{
                                            width: '100%',
                                            borderRadius: '5px',
                                            fontSize: '14px',
                                            height: '25px',
                                            fontWeight: '400',
                                            fontFamily: 'Inter, sans-serif',
                                            color: '#344054',
                                            background: '#ffffff',
                                            border: '1px solid #d0d5dd',
                                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                            padding: '10px 14px',
                                        }}
                                        placeholder='Street'
                                        //onChange={onPlaceSelected}
                                        //onClick={onPlaceSelected}
                                        onPlaceSelected={(val) => setAddress(val?.formatted_address)}
                                        defaultValue={address}
                                    />
                                    <Profile onClick={() => deleteAddressHandler(index)} style={{ marginLeft: '15px', borderRadius: '8px', marginRight: '-25px' }}>{
                                        <UserIcon src={reddelete} />
                                    }</Profile>
                                </div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div style={{ width: '45%' }}>

                                        <InputBox
                                            header={"City"}
                                            value={values?.firstName}
                                            onFocus={() => setOnfocus(true)}
                                            style={{ display: 'flex', alignSelf: 'center', }}
                                            id="outlined-basic"
                                            size="small"
                                            placeholder="City"
                                            variant="outlined"
                                            onChange={(e) => setValues({ ...values, name: e.target.value })} />
                                    </div>
                                    <div style={{ width: '45%' }}>

                                        <InputBox
                                            header={"Province"}
                                            value={values?.businessName}
                                            onFocus={() => setOnfocus(true)}

                                            id="outlined-basic" size="small"
                                            placeholder="Province"
                                            variant="outlined"
                                            onChange={(e) => setValues({ ...values, businessName: e.target.value })} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                                    <div style={{ width: '45%' }}>

                                        <InputBox
                                            header={"Postal code"}
                                            value={values?.firstName}
                                            onFocus={() => setOnfocus(true)}
                                            style={{ display: 'flex', alignSelf: 'center', }}
                                            id="outlined-basic"
                                            size="small"
                                            placeholder="Postal code"
                                            variant="outlined"
                                            onChange={(e) => setValues({ ...values, name: e.target.value })} />
                                    </div>
                                    <div style={{ width: '45%' }}>

                                        <InputBox
                                            header={"Country"}
                                            value={values?.businessName}
                                            onFocus={() => setOnfocus(true)}

                                            id="outlined-basic" size="small"
                                            placeholder="Country"
                                            variant="outlined"
                                            onChange={(e) => setValues({ ...values, businessName: e.target.value })} />
                                    </div>
                                </div>
                            </>
                        )
                    } */}


                    <div style={{ marginTop: '10px' }}>
                        <ClientDetails>
                            <Profile>{
                                <DescriptionOutlined />
                            }</Profile>
                            Notes
                        </ClientDetails>

                        <Label >Notes</Label>
                        <InputText
                            placeholder='Notes'
                            onChange={(e) => setValues({ ...values, notes: e.target.value })}
                        />
                        <Label >Tags</Label>
                        <CustomizedAutocomplete
                            value={valuess}
                            onChange={(value) => {
                                setValuess('');
                                setSelectedHash([...selectHash, value])
                            }}
                            onNewChange={async (value) => {
                                setValuess('');
                                setSelectedHash([...selectHash, value])
                                const res = await dispatch(addHasgtag({
                                    "hashTags": value
                                }))
                                setHasTag([...hastTag, value])

                            }}
                            options={hastTag}
                            placeholder="Search or create a tag"
                            width={'103.1%'}
                            marginLeft="0px"
                            inputHeight="25px"
                            inputFontSize="14px"

                        />
                        {selectHash?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '30px', marginLeft: '10px', width: '100%' }}>
                            {selectHash?.map((item, index) =>
                                <div
                                    onClick={() => {
                                        const arr = selectHash?.filter(obj => obj !== item)
                                        setSelectedHash(arr)
                                    }}
                                    style={{ color: '#fff', cursor: 'pointer', background: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, display: 'flex', alignItems: 'center', padding: '4px', fontSize: '13px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}>{item} <Close style={{ marginLeft: '10px', color: '#fff', fontSize: '14px', }} /></div>
                            )}
                        </div> : null}
                    </div>
                </div>
            </div>

        </Main>
    )


}

export default AddContact;

const PhoneFiled = styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;

.PhoneInputCountry{
    border: 0.5px solid #d0d5dd;
    height:41px;
}
.PhoneInputInput{
    height:25px;
   
    border: ${(props) => props.error ? '1px' : '0.5px'} solid ${(props) => props.error ? 'red' : '#d0d5dd'};
    &:focus {
        outline: none;
        border: 1.5px solid ${palette.charcoleBlack};
      
      }
}

`


const Main = styled.div`
width:100%;
padding-left:30px;
padding-right:30px;
scrollbar-width: thin;
scrollbar-color: #888 transparent;
margin-top:60px;
padding-bottom:100px;
height: auto;
display: flex;
flex-direction: column;
background:#fff;
min-height: 100vh;
overflow-y: auto; 

h4{
    margin-left:5px;
    font-weight:500;
    text-align:start;
    margin-bottom:0px;

}
  
`
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000000; 
  padding: 0px 20px; 

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
`;


const ClientDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
`;
const Profile = styled.div`
width:30px;
height:30px;
cursor:pointer;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
margin-right:15px;
text-align:center;
border-radius:18px;
background:#ddd;

color:#000;
font-weight:600;
font-size:24px;

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;
margin-right:15px;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`

const Label = styled.label`

font-weight: 600;
font-size: 13px;
    margin-left:4px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:24px;
    align-self: flex-start;

    font-family:Inter;

  
`
const UserIcon = styled.img`
width: 15px;
height: 15px;
`

const Place = styled(Autocomplete)`
width:102%;
display:flex;
margin-right:0px;
margin-left:0px;
border-radius:5px;
font-size: 16px;
height:44px;
border-radius:4px;
border:1px solid  ${palette.charcoleBlack};
font-weight:400;
padding-left:12px;

&::placeholder {
  font-size:14px;
  font-weight:300;
}
&:focus {
    
    outline: 1.5px solid ${palette.charcoleBlack};
  
  }
`
const AddButton = styled.button`
border:1px solid ${palette.lightGrey};
 background:#fff; 
 borderRadius:4px;
color:${palette.newBlue}; 
font-size: 14px;
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
border:1px solid #DDD;
font-weight:600;
display:flex;
justify-content:center;
align-items:center;


cursor:pointer;
:hover{
    border:1px solid #9da6b4;
}

`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 100%;
  margin-top:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;