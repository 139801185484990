import React, { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { DateRange } from 'react-date-range'
import calenda from '../../assets/calenda.png'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,

    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { callGraph, filterGraph, invoiceGraph } from '../../store/Actions/dashboard.action';
import { linethemes, putCommaInvalue } from '../../utils/Commonfunction';
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import { IconButton, Menu, MenuItem, Select, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import JobList from './AppointmentJob';
import { Person2Outlined, HandymanOutlined, PhoneCallbackOutlined, MessageOutlined, Add, PersonOutlineOutlined, RequestQuoteOutlined, PaidOutlined } from '@mui/icons-material';
import Header from '../../Components/Header/MainHeader';
import { FiCalendar } from 'react-icons/fi';
import { palette } from '../../utils/Palette';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,

    Legend
);

function Reports() {

    const Days = ['Today', 'Yesterday', 'Week', 'Month', 'Quarter', 'Year']
    const [filter, setFilter] = useState("All Intervals")
    const [activeday, setActiveday] = useState('Today')
    const [data1, setData] = useState({})
    const [datacall, setDataCall] = useState({})
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [payments, setPayments] = useState([])
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const openAssign = Boolean(anchorEl1);
    const handleClickAssign = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleCloseAssign = () => {
        setAnchorEl1(null);
    };

    const invoiceData = [
        {
            name: 'Draft',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.Draft)?.toFixed(2))}`
        },
        {
            name: 'Unpaid',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.Unpaid)?.toFixed(2))}`
        },
        {
            name: 'Cash',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.Cash)?.toFixed(2))}`
        },
        {
            name: 'Credit card',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.['Credit Card (In-person)'])?.toFixed(2))}`
        },
        {
            name: 'Online transfer',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.['Online Transfer'])?.toFixed(2))}`
        },
        {
            name: 'Bank transfer',
            value: `${putCommaInvalue(parseFloat(data1?.invoice?.['Online Transfer'])?.toFixed(2))}`
        },

        {
            name: 'Overdue',
            value: `${putCommaInvalue(data1?.invoice?.Overdue)}`
        }
    ]
    const [showMore, setShowMore] = useState(false);
    const itemsToShow = showMore ? invoiceData?.length : 4;
    const [x, setX] = useState([])
    const containerRef = useRef(null);
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const refOne = useRef(null)

    let dispatch = useDispatch()
    const handleday = (day) => {
        setActiveday(day)
    }

    const init = async (val) => {
        const res = await dispatch(invoiceGraph(val))
        const res2 = await dispatch(invoiceGraph('daily'))
        const res1 = await dispatch(callGraph(val))
        setDataCall(res1?.data)
        setPayments(res2?.data)
        setData(res?.data)
        setX(res?.data?.x)

    }
    useEffect(() => {
        init('all')
    }, [activeday])
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const Revenuedetails = useSelector(state => state?.dashboardReducer?.revenue)
    const userdata = useSelector(state => state?.auth?.userData)
    let revenuelabels = Revenuedetails?.map(x => x.x)
    let revenuedata = Revenuedetails?.map(x => x.y)
    let result1 = revenuelabels?.every(e => e === 0);
    let result2 = revenuedata?.every(e => e === 0);

    const revenue = {
        labels: payments?.invoice?.x?.length > 0 ? payments?.invoice?.x : [new Date(), '', '', '', '', '', '', '', new Date()],
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: revenuedata && result2 !== true ? revenuedata : payments?.invoice?.y
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }
    const Bookcancel = {
        labels: data1?.invoice?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: data1?.invoice?.y
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            },
            {
                label: '',
                data: [0],
                borderColor: '#D81159',
                backgroundColor: '#D81159',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],

    }

    const Quotes = {
        labels: data1?.quote?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: data1?.quote?.y
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                tension: 0.4,
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }

    const serviceRequest = {
        labels: data1?.bookings?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: data1?.bookings?.y
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }
    const calls = {
        labels: datacall?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: datacall?.y,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }
    const message = {
        labels: data1?.message?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: data1?.message?.total
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }
    const leads = {
        labels: data1?.leads?.x,
        datasets: [
            {
                label: '',
                usePointStyle: true,
                data: data1?.leads?.y
                ,
                background: '#0F52BA',
                backgroundColor: '#0F52BA',
                borderColor: '#0F52BA',
                borderWidth: 2,
                lineTension: 0.3,
                pointRadius: 0
            }
        ],
    }

    const graphOption = (graph) => {
        const options = {
            layout: {
                padding: {
                    right: 30,
                    left: 30
                }
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            }
            ,
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'nearest',
                intersect: false
            }
            ,
            scales: {
                y: {

                    display: false,


                }, x: {
                    grid: {
                        display: false,
                        drawTicks: false
                    },

                    ticks: {
                        display: true,
                        callback: function (value, index, values) {
                            if (index === 0 || index === values.length - 1) {
                                return moment(graph[index]).format('DD MMM');
                            } else {
                                return '';
                            }
                        },
                        autoSkip: false,
                        maxTicksLimit: 2,




                        font: {

                            size: 10,
                            weight: 'bold',
                            color: '#f3f3f3',
                            lineHeight: 3,
                        }
                    },

                    scaleLabel: {
                        display: true,
                        labelString: 'Scale Label',
                        color: 'green',
                        font: {

                            size: 10,
                            weight: 'bold',
                            color: '#f3f3f3'
                        }
                    },

                }
            },
            plugins: {
                legend: {
                    align: 'start',
                    position: 'top',
                    labels: {
                        usePointStyle: false,
                        boxWidth: 0,
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
        }

        return options;
    }
    const paymentoptions = {
        layout: {
            padding: {
                right: 30,
                left: 30
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        }
        ,
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'nearest',
            intersect: false
        }
        ,
        scales: {
            y: {

                display: false,


            }, x: {
                grid: {
                    display: false,

                },

                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        if (index === 0 || index === values.length - 1) {
                            return moment(revenue?.labels[index]).format('DD MMM');
                        } else {
                            return '';
                        }
                    },
                    autoSkip: false,
                    maxTicksLimit: 2,




                    font: {

                        size: 10,
                        weight: 'bold',
                        color: '#f3f3f3',
                        lineHeight: 3,
                    }
                },

                scaleLabel: {
                    display: true,
                    labelString: 'Scale Label',
                    color: 'green',
                    font: {

                        size: 10,
                        weight: 'bold',
                        color: '#f3f3f3'
                    }
                },

            }
        },
        plugins: {
            legend: {
                align: 'start',
                position: 'top',
                labels: {
                    usePointStyle: false,
                    boxWidth: 0,
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    }
    const smallgraphoptions = {
        scales: {
            y: { display: false }, x: {
                grid: {
                    drawTicks: false
                }
            }
        },
        plugins: {
            legend: {
                align: 'start',
                position: 'bottom',
                datalabels: {
                    display: false
                },
                labels: {
                    usePointStyle: true,
                    boxWidth: 6,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    }
    const topTabData = [
        'All Intervals', 'Today', 'Last 7 days', 'Month to date', 'Quarter to date', 'Year to date',
    ]

    return (
        <React.Fragment>
            <Body>
                <Main>
                    <Header
                        nonsearchable={true}
                    />
                    <StyledContactFlex>
                        <div>
                            <h2>Dashboard</h2>
                            {/* <span><Notifications style={{ marginRight: '5px', fontSize: '20px' }} /> {data?.length || 0}</span> */}
                        </div>
                        <OptionContainer>
                            <Tooltip arrow title='Add' placement="top">
                                <IconButton onClick={handleClickAssign} style={{
                                    width: '30px',
                                    height: '30px', marginRight: '4px'
                                }}  >
                                    <Add style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </OptionContainer>
                        <Menu
                            anchorEl={anchorEl1}
                            id="account-menu"
                            open={openAssign}
                            onClose={handleCloseAssign}
                            onClick={handleCloseAssign}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    minWidth: '180px',

                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 20,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    navigate('/dashboard/contacts?contact=true')
                                }}
                                style={{ justifyContent: 'flex-start', alignItems: 'center', fontSize: '13px', color: 'black' }}>
                                <PersonOutlineOutlined
                                    style={{ fontSize: '18px', marginRight: '5px', color: palette.green }}

                                />
                                Contact

                            </MenuItem>
                            <MenuItem

                                onClick={() => {
                                    navigate('/dashboard/quotes?quote=true')

                                }}
                                style={{ justifyContent: 'flex-start', alignItems: 'center', fontSize: '13px', color: 'black' }}>
                                <RequestQuoteOutlined
                                    style={{ fontSize: '16px', marginRight: '5px', color: palette.orange }}

                                />
                                Quote

                            </MenuItem>

                            <MenuItem

                                onClick={() => {
                                    navigate('/dashboard/servicerequests?request=true')

                                }}
                                style={{ justifyContent: 'flex-start', alignItems: 'center', fontSize: '13px', color: 'black' }}>
                                <HandymanOutlined
                                    style={{ fontSize: '16px', marginRight: '5px', color: palette.pup }}

                                />
                                Job

                            </MenuItem>
                            <MenuItem
                                style={{ justifyContent: 'space-between', fontSize: '13px', color: 'black' }}
                                onClick={() => {
                                    navigate('/dashboard/invoices?invoice=true')

                                }}
                                style={{ justifyContent: 'flex-start', alignItems: 'center', fontSize: '13px', color: 'black' }}>
                                <PaidOutlined
                                    style={{ fontSize: '16px', marginRight: '5px', color: palette.indinRed }}

                                />
                                Invoice

                            </MenuItem>


                        </Menu>

                    </StyledContactFlex>

                    <Segment>
                        {/* <GraphContainer style={{ width: '100%' }}>
                          
                            <JobList />
                        </GraphContainer> */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '8px 20px', borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Selectbox
                                    sx={{
                                        border: 'none'
                                    }}
                                    placeholder='select' value={'Select an option'} displayEmpty

                                    renderValue={() => filter} onChange={(e) => {
                                        console.log(e.target)

                                    }}
                                    MenuProps={{ // Add MenuProps to customize menu styling
                                        PaperProps: {
                                            style: {

                                            },
                                        },
                                    }}
                                >
                                    {[...topTabData]?.map((item, index) =>
                                        <StyledMenuItem key={index} value={index}>
                                            <FilterBox
                                                onClick={() => {
                                                    setFilter(item)
                                                    if (item === 'All Intervals') {
                                                        init('all')
                                                    }
                                                    else if (item === 'Today') {
                                                        init('daily')
                                                    }
                                                    else if (item === 'Last 7 days') {
                                                        init('weekly')
                                                    }
                                                    else if (item === 'Month to date') {
                                                        init('monthly')
                                                    }
                                                    else if (item === 'Quarter to date') {
                                                        init('quarterly')
                                                    }
                                                    else {
                                                        init('yearly')
                                                    }


                                                }}
                                                style={{ color: filter === item ? '#4169e1' : '#000' }}>


                                                {item}</FilterBox>
                                        </StyledMenuItem>
                                    )}
                                </Selectbox>
                                <div style={{ position: 'relative', display: 'flex' }}>
                                    <StyledTextBox onClick={() => setOpen(open => !open)}>
                                        <FiCalendar style={{ marginRight: '10px' }} />
                                        {range1[0]?.startDate ? `${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}` : 'Date'}

                                    </StyledTextBox>
                                    {open &&
                                        <>
                                            <StyleBox style={{ position: 'absolute', left:range1[0]?.startDate?-80: -180, marginTop: '50px', marginLeft: '-90px', background: 'white' }} ref={refOne}>
                                                <DateRange
                                                    style={{ height: '220px' }}
                                                    onChange={item => setRange([item.selection])}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}

                                                    ranges={range}
                                                    months={1}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                />
                                                <ButtonBox>
                                                    <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                                        setOpen(false)
                                                        setRange1([{
                                                            startDate: '',
                                                            endDate: ''
                                                        }])
                                                        setRange([
                                                            {
                                                                startDate: new Date(),
                                                                endDate: addDays(new Date(), 3),
                                                                key: 'selection'
                                                            }
                                                        ])
                                                        init('all')

                                                    }}>Clear</SendButton1>
                                                    <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                        setOpen(false)
                                                        setRange1([{
                                                            startDate: range?.[0]?.startDate,
                                                            endDate: range?.[0]?.endDate
                                                        }])

                                                        const res = await dispatch(filterGraph(range?.[0]?.startDate?.toISOString(), range?.[0]?.endDate?.toISOString()))
                                                        setData(res?.data)

                                                        // const res = await dispatch(createNewCustomField({
                                                        //     "fields": [
                                                        //         newCutomField
                                                        //     ]
                                                        // }))
                                                        // setDataCustomField([...customField, newCutomField])
                                                        // setIsModalOpen1(false)
                                                    }}>Apply</SendButton1>
                                                </ButtonBox>
                                            </StyleBox>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ marginRight: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Jobs </h3>
                                                <span><HandymanOutlined style={{ marginRight: '5px', fontSize: '18px' }} /> {data1?.bookings?.totalBookings || 0}</span>
                                            </div>

                                        </StyledContactFlex>

                                        <StyledViewButton onClick={() => navigate('/dashboard/servicerequests')}>
                                            View more
                                        </StyledViewButton>

                                    </div>


                                    <div className='revenue'>
                                        <Line options={graphOption(serviceRequest?.labels)} data={serviceRequest} />
                                    </div>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[0] }} />
                                            <div style={{ color: '#51565e' }}>Open</div>
                                        </div>
                                        <div >{data1?.bookings?.['Waiting for confirmation']}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[1] }} />
                                            <div style={{ color: '#51565e' }}>On hold</div>
                                        </div>
                                        <div>{data1?.bookings?.['On Hold']}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[2] }} />
                                            <div style={{ color: '#51565e' }}>Active</div>
                                        </div>
                                        <div>{data1?.bookings?.['In Progress']}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[3] }} />
                                            <div style={{ color: '#51565e' }}>Done</div>
                                        </div>
                                        <div>{data1?.bookings?.['Completed']}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[4] }} />
                                            <div style={{ color: '#51565e' }}>Unscheduled</div>
                                        </div>
                                        <div>{data1?.bookings?.unscheduled}</div>
                                    </Flexbox>
                                </GraphContainer>

                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Invoices </h3>
                                                <span>${putCommaInvalue(parseFloat(data1?.totalInvocie || 0)?.toFixed(2))}</span>
                                            </div>

                                        </StyledContactFlex>

                                        <StyledViewButton onClick={() => navigate('/dashboard/invoices')}>
                                            View more
                                        </StyledViewButton>

                                    </div>

                                    <div className='revenue'>
                                        <Line options={graphOption(Bookcancel?.labels)} data={Bookcancel} />
                                    </div>
                                    {invoiceData?.slice(0, itemsToShow).map((item, index) => (
                                        <Flexbox>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ColorDiv style={{ background: linethemes[index] }} />
                                                <div style={{ color: '#51565e' }}>{item?.name}</div>
                                            </div>
                                            <div>${item?.value}</div>
                                        </Flexbox>)
                                    )}
                                    {invoiceData.length > 3 && (
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <StyledViewButton style={{ alignSelf: 'flex-end', margin: '15px' }} onClick={toggleShowMore}>{showMore ? 'Show Less' : 'Show More'}</StyledViewButton>
                                        </div>

                                    )}
                                </GraphContainer>
                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Quotes </h3>
                                                <span>${putCommaInvalue(parseFloat(data1?.totalQuote || 0)?.toFixed(2))}</span>
                                            </div>
                                        </StyledContactFlex>

                                        <StyledViewButton onClick={() => navigate('/dashboard/quotes')}>
                                            View more
                                        </StyledViewButton>

                                    </div>

                                    <div className='revenue'>
                                        <Line options={graphOption(Quotes?.labels)} data={Quotes} />
                                    </div>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[0] }} />
                                            <div style={{ color: '#51565e' }}>Draft</div>
                                        </div>
                                        <div>${putCommaInvalue(data1?.quote?.Draft)}</div>
                                    </Flexbox>
                                  
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[1] }} />
                                            <div style={{ color: '#51565e' }}>Pending approval</div>
                                        </div>
                                        <div>${putCommaInvalue(parseFloat(data1?.quote?.['Waiting for confirmation'])?.toFixed(2))}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[2] }} />
                                            <div style={{ color: '#51565e' }}>Approved</div>
                                        </div>
                                        <div>${putCommaInvalue(parseFloat(data1?.quote?.Accepted)?.toFixed(2))}</div>
                                    </Flexbox>
                                  
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[3] }} />
                                            <div style={{ color: '#51565e' }}>Voided</div>
                                        </div>
                                        <div>${putCommaInvalue(data1?.quote?.Rejected)}</div>
                                    </Flexbox>
                                </GraphContainer>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Leads </h3>
                                                <span><Person2Outlined style={{ marginRight: '5px', fontSize: '18px' }} /> {data1?.leads?.totalCustomerFormLeadCount + data1?.leads?.totalManualLeadCount}</span>
                                            </div>
                                        </StyledContactFlex>

                                        <StyledViewButton onClick={() => navigate('/dashboard/leads')}>
                                            View more
                                        </StyledViewButton>

                                    </div>
                                    <div className='revenue'>
                                        <Line options={graphOption(leads?.labels)} data={leads} />
                                    </div>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[0] }} />
                                            <div style={{ color: '#51565e' }}>Manual</div>
                                        </div>
                                        <div>{data1?.leads?.totalManualLeadCount}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[1] }} />
                                            <div style={{ color: '#51565e' }}>Forms</div>
                                        </div>
                                        <div>{data1?.leads?.totalCustomerFormLeadCount}</div>
                                    </Flexbox>

                                </GraphContainer>

                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Calls </h3>
                                                <span><PhoneCallbackOutlined style={{ marginRight: '5px', fontSize: '18px' }} /> {datacall?.totalCalls}</span>
                                            </div>
                                        </StyledContactFlex>

                                        <StyledViewButton >
                                            View more
                                        </StyledViewButton>

                                    </div>

                                    <div className='revenue'>
                                        <Line options={graphOption(calls?.labels)} data={calls} />
                                    </div>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[0] }} />
                                            <div style={{ color: '#51565e' }}>Total calls</div>
                                        </div>
                                        <div>{datacall?.totalCalls}</div>
                                    </Flexbox>
                                    {/* <Flexbox>
                                    <div style={{ color: '#51565e' }}>Missed calls</div>
                                    <div>20</div>
                                </Flexbox> */}
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[1] }} />
                                            <div style={{ color: '#51565e' }}>Inbound</div>
                                        </div>
                                        <div>{datacall?.incomingCount}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[2] }} />
                                            <div style={{ color: '#51565e' }}>Outbound</div>
                                        </div>
                                        <div>{datacall?.outgoingCount}</div>
                                    </Flexbox>
                                </GraphContainer>
                                <GraphContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <StyledContactFlex>
                                            <div>
                                                <h3>Messages </h3>
                                                <span><MessageOutlined style={{ marginRight: '5px', fontSize: '18px' }} /> {data1?.inboundMessage + data1?.outboundMessage}</span>
                                            </div>
                                        </StyledContactFlex>

                                        <StyledViewButton onClick={() => navigate('/dashboard/inbox')}>
                                            View more
                                        </StyledViewButton>

                                    </div>


                                    <div className='revenue'>
                                        <Line options={graphOption(message?.labels)} data={message} />
                                    </div>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[0] }} />
                                            <div style={{ color: '#51565e' }}>Total messages</div>
                                        </div>
                                        <div>{data1?.inboundMessage + data1?.outboundMessage}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[1] }} />
                                            <div style={{ color: '#51565e' }}>Incoming messages</div>
                                        </div>
                                        <div>{data1?.inboundMessage}</div>
                                    </Flexbox>
                                    <Flexbox>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColorDiv style={{ background: linethemes[2] }} />
                                            <div style={{ color: '#51565e' }}>Outgoing messages</div>
                                        </div>
                                        <div>{data1?.outboundMessage}</div>
                                    </Flexbox>
                                </GraphContainer>
                            </div>
                        </div>


                        {/* <div className='d-flex'>
                        <div className='subgraphs'>
                            <div className='d-flex'>nbsp;&nbsp;<h1 className='subhead'>Number of cancellations and bookings</h1></div>
                            <div className='d-flex mt-3'><h1 className='number'>0</h1>&nbsp;<h1 className='day'>Today</h1></div>
                            <div className='revenue'>
                                <Line options={smallgraphoptions} data={Bookcancel} />
                            </div>
                        </div>
                        <div className='subgraphs'>

                        </div>
                        <div className='subgraphs'>
                            <div className='d-flex'>&nbsp;&nbsp;<h1 className='subhead'>Jobs that are done today</h1></div>
                            <div className='d-flex mt-3'><h1 className='number'>0</h1>&nbsp;<h1 className='day'>Today</h1></div>
                            <div className='revenue'>
                                <Line options={smallgraphoptions} data={jobstoday} />
                            </div>
                        </div>
                        <div className='subgraphs'>

                        </div>
                        <div className='subgraphs'>
                            <div className='d-flex'>&nbsp;&nbsp;<h1 className='subhead'>Insurance claims</h1></div>
                            <div className='d-flex mt-3'><h1 className='number'>0</h1>&nbsp;<h1 className='day'>Today</h1></div>
                            <div className='revenue'>
                                <Line options={smallgraphoptions} data={insuranceclaims} />
                            </div>
                        </div>
                    </div> */}

                        <br />
                        <br />
                        {/* <GraphTable/> */}
                        <br />
                    </Segment>
                    <div>

                    </div>
                </Main>
            </Body>
        </React.Fragment>
    )
}
export default Reports
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const StyledTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px; 
  border-radius: 4px;
  cursor: pointer;
  margin-left:10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 13px;
  color: rgba(32, 32, 32, 0.85);
  font-weight:500;
  background-color: transparent; 
  svg {
    font-size: 16px; 
    color: rgba(32, 32, 32, 0.85);
  }
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
    color:#000;
    svg {
        color:#000;
    }
  }


`;
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right:20px;
  margin-top:0px;
`;
const Selectbox = styled(Select)`
height: 30px;
border-radius: 20px;
font-size: 13px;
text-align: start;
display: flex;
justify-content:center;
background-color: white;
border: none !important; 
outline: none;
transition: background-color 0.3s ease, box-shadow 0.3s ease;

&:hover {
  background-color: #f3f3f3;
  color:#000;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important; 
  font-size: 13px;
}
.MuiSelect-select {
  border: none !important;
  font-size: 14px;
}
.MuiMenuItem-root {
    font-size: 14px;
    padding: 8px 16px; 
    border-radius: 8px;
    
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #eeeeee;
      border-radius: 8px;
    }
  }

  .MuiMenu-paper {
    border-radius: 12px; 
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.01); 
    overflow: hidden;
  }


`;
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const ColorDiv = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 8px;
`;


const Main = styled.div`

width:100%;
display:flex;
overflow-x:hidden;
flex-direction:column;
.active{
    font-family:Inter;
    color:#787373;
}
.days{
    margin-right:30px;
    color:#787373;
    font-family:Roobert-medium;
    font-size: 14px;
    cursor:pointer;
}
.activeday{
background-color:#D81159;
background:#D81159
text-align:center;
border-radius:4px;
color:white;
width: 77px;
height: 32px;
margin-right:30px;
text-align:center;
font-family:Roobert-medium;
font-size: 14px;
padding-top:6px;
margin-top:-8px;
cursor:pointer;
}
`
const Segment = styled.div`

width:100%;

overflow-y:scroll;

::-webkit-scrollbar {
    display: none;
  }
.revenue{
height:250px;
width: 100%;
margin-top:0px;
margin-bottom:30px;

}

.dot{
width: 8px;
height: 8px;
background: #D81159;
border-radius:50%;
margin-top:4px;
}
.revenuehead{
font-size: 18px;
font-family:Roobert-medium;
font-weight: 500;
}
.subhead{
font-size: 14px;
font-family:Inter;
font-weight: 500;
margin-top:5px;
}
.info{
height:14px;
width:14px;
margin-top:5px;
}
.number{
font-weight: 600;
font-size: 24px;
font-family:Roobert-medium;
}
.day{
font-size: 12px;
font-family:Inter;
color: #D0CECE;
margin-top:9px;
}
.graphhead{
color: #787373;
font-size: 12px;
font-family:Roobert-medium;
}
.subgraphs{
    margin-right:30px;
}
`
const GraphContainer = styled.div`
width:31%;
margin-top:30px;
padding-top:20px;
padding-bottom:20px;
border-radius:8px;
margin-left:10px;
margin-right:10px;
background:white;


`
const Flexbox = styled.div`
display:flex;
justify-content:space-between;
font-size:14px;
padding-top:15px;
padding-bottom:15px;
border-bottom:1px solid #f3f3f3;
padding-left:20px;
padding-right:20px;
`

const CustomUrl = styled.input`
background: #FFF;
border-radius: 8px;
cursor:pointer;
border: 1px solid #fff;
height: 30px;

&:focus {
    outline: none;
    border: none; /* Remove border on focus */
  }
`
const StyledContactFlex = styled.div`
 display:flex; 
 justify-content:space-between; 
 align-items:center; 
 margin-left:20px; 
 margin-right:0px;

 h3{
  text-align:center; 
  margin-top:0px; 
  margin-bottom:5px; 
  line-spacing:0.5px;
 }
 span{
   color:grey;
   font-size:15px;
   text-align:start;
   display:flex;
   align-items:flex-end;
   margin-bottom:5px; 
   font-weight:400;
   
 }

`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #f3f3f3;
background:white;
width:350px;
margin-top:30px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;

align-items:center;

font-weight:500;
cursor:pointer;

`
const OptionBox = styled.div`
padding-left:15px;
padding-right:15px;
height:25px;
border-radius:8px;
border:1px solid #bbb;
width:100px;
display:flex;

font-size:12px;

align-items:center;
justify-content:center;
margin-right:20px;

font-weight:500;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }

`
const StyledViewButton = styled.h5`
display: flex;
justify-content: center;
align-items: center;
margin-top:3px;
margin-bottom:0px;
padding:5px 10px;
border-radius: 10px;
cursor: pointer;
transition: background-color 0.3s, color 0.3s;

font-size: 12px; 
color: #0f52ba;
background-color: transparent; 

&:hover {
  background-color: #dce8f5; 
}

svg {
  font-size: 16px; 
  color: #0f52ba;
  transition: color 0.3s;

  &:hover {
    color: #083f7c;
  }
}
`;