import React from 'react';

function Modal({ children, onClose,customStyle }) {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
     
      onClose();
    }
  };

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <div style={{...styles.modal,...customStyle}}>
        {children}
        {/* <button style={styles.closeButton} onClick={onClose}>X</button> */}
      </div>
    </div>
  );
}

const styles = {
  
  overlay: {
    zIndex: 150000,
    position: "fixed",

    backgroundColor: 'rgba(255, 255, 255, 0.5)', 

    width: "100%",
    height: "100%",
   
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignContent: "stretch",
    alignItems: "center",
  },
  modal: {
    backgroundColor: '#FAFAFA',
   

    boxShadow: "0 12px 48px rgba(32,40,77,.40),0 32px 80px rgba(32,40,77,.1)",
    opacity: 1.5,
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px',
    position: 'relative',
    flexShrink: 0,
    overflow: 'auto', 
    width: 'auto',
    height:'auto',
    maxHeight: '95vh',
    maxWidth: '94%',
    zIndex:1000000
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer'
  }
};

export default Modal;