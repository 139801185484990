import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { putCommaInvalue, totalprice } from "../../utils/Commonfunction";
import { addnewLineItems, submitEditLineItem } from "../../store/Actions/dashboard.action";
import RippleButton from "../../ReuseableComponets/RippleButton";
import { palette } from "../../utils/Palette";
import { submitServicePlan } from "../../store/Actions/JobAction";

const AddServicePlan = (props) => {
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const [lineItem, setLineItem] = useState({
        "name": "",
        "description": "",
        "amount": 0,
        "currency": "usd",
        "type": "recurring",
        "interval": {
            "interval": "month",
            "intervalCount": 1
        },
        "discount": ""
    })
    const [addImageData, setImagedata] = useState(props?.value?.picUrl || '')
    const [addEditImageData, setEditImagedata] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const onSubmit = async () => {
        if (props?.value?.edit) {

            const res = await dispatch(submitEditLineItem(addImageData, { ...lineItem, "itemId": props?.value?._id, picUrl: addEditImageData ? '' : lineItem?.picUrl }))
            if (res.status) {
                props?.onSubmitsw();
                props?.setModalOpenInvoice(false)
            }
            else {
                setError("Image size too large. The image size needs to be under 2 MB.")
            }
        }
        else {
            const res = await dispatch(submitServicePlan(lineItem))
            if (res.status) {
               
                props?.setSubmit(false)
            }
            else {
                setError(res?.message)
            }
        }
    }
    return (
        <>
            <Flexbox >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'start', }}>{props?.value?.edit ? "Edit plan" : " Add a plan"}</h2>
                    <RippleButton style={{ marginRight: -15 }} onClick={async () => {
                        await onSubmit()
                    }}>Save</RippleButton>
                </div>

                <div style={{ marginBottom: '10px', width: '95%' }} >
                    <Label>Name</Label>
                    <Inputbox
                        type="text"
                        placeholder='Plan name'
                        style={{ width: '100%' }}
                        value={lineItem?.name}
                        onChange={(e) => setLineItem({ ...lineItem, name: e.target.value })}
                    />
                </div>
                <div style={{ marginBottom: '10px', width: '93.5%' }} >
                    <Label>Description (Optional)</Label>
                    <InputText
                        placeholder="Description"
                        type="text"
                        value={lineItem?.description}
                        onChange={(e) => setLineItem({ ...lineItem, description: e.target.value })}

                    />
                </div>
                <div style={{ width: '97%' }}>
                    <Label>Amount (Required)</Label>
                    <DropdownContainer value={lineItem?.currency} style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                        <SelectDrpoDown id="category-visibility" style={{ width: '36%', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onChange={(event) => {
                            // setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })
                            // setReoccuringValue({ ...recurringValue, durationMonth: event.target.value })
                            setLineItem({...lineItem,currency:event.target.value})
                        }}>
                            <Option value="cad">
                                CAD
                            </Option>
                            <Option value="usd">
                                USD
                            </Option>

                        </SelectDrpoDown>
                        <CustomUrl type='number' placeholder='Price'
                            value={lineItem?.amount}
                            onChange={(e) => setLineItem({ ...lineItem, amount: e.target.value })}
                        />
                    </DropdownContainer>
                </div>
                <div style={{ width: '97%' }}>
                    <Label>Billing period</Label>
                    <DropdownContainer style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                        <Label style={{ marginTop: '10px', marginRight: '10px', color: palette.grey }}>Every</Label>
                        <input
                            type="number"
                            min="1"
                            value={lineItem?.interval?.intervalCount}
                            className='input'
                            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                            // value={recurringValue?.durationUnit}
                            onChange={(e) => setLineItem({ ...lineItem, interval: {
                                ...lineItem?.interval,
                                intervalCount:e.target.value
                            } })}
                            style={{ width: '30%', alignItems: 'center' }}
                        />
                        <SelectDrpoDown id="category-visibility" value={lineItem?.interval?.interval} style={{ width: '100%', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onChange={(event) => {
                            // setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })
                            // setReoccuringValue({ ...recurringValue, durationMonth: event.target.value })
                            setLineItem({ ...lineItem, interval: {
                                ...lineItem?.interval,
                                interval:event.target.value
                            } })
                        }}>
                            <Option value="day">
                                Days
                            </Option>
                            <Option value="week">
                                Weeks
                            </Option>
                            <Option value="month">
                                Months
                            </Option>

                            <Option value="year">Years</Option>
                        </SelectDrpoDown>
                    </DropdownContainer>
                    {
                      (lineItem?.interval?.interval === 'year' && lineItem?.interval?.intervalCount > 3) ||
                      (lineItem?.interval?.interval === 'month' && lineItem?.interval?.intervalCount > (12*3)) ||
                      (lineItem?.interval?.interval === 'week' && lineItem?.interval?.intervalCount > (52*3)) ||
                      (lineItem?.interval?.interval === 'day' && lineItem?.interval?.intervalCount > (365*3)) ? <div>
                        <br />
                        <p style={{ fontSize: '14px', color: '#D81159',textAlign:'start',marginTop:'-10px',marginBottom:'0px' }}>{"⚠ Invalid input: The billing period cannot exceed 3 years."}</p>
                    </div> : null
                }
                </div>

                <div style={{ width: '95%' }}>
                    <Label>Discount</Label>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                        <CustomUrl type='number' placeholder='Discount'
                            value={lineItem?.value}
                            onChange={(e) => setLineItem({ ...lineItem, discount: e.target.value })}
                        />
                        <span style={{
                            position: 'absolute',
                            right: '10px',
                            fontSize: '14px',
                            color: '#333'
                        }}>
                            %
                        </span>
                    </div>
                </div>
                {
                    error ? <div>
                        <br />
                        <p style={{ fontSize: '14px', color: '#D81159',textAlign:'start' }}>{"⚠ Invalid input fields"}</p>
                    </div> : null
                }


            </Flexbox>
        </>
    )
}

export default AddServicePlan


const Flexbox = styled.div`
width:32wv;
margin-left:10px;
flex-direction:column;
align-items:start;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`

const Inputbox = styled.input`
height: 35px;
font-size: 14px;
padding-left:10px;
width:99%;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;

border-radius:4px;
align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-top:15px;
    margin-bottom: 8px;
    margin-left:5px;
    align-self: flex-start;
    font-family:Inter;

  
`

const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:4px;
height: 35px;
width:99%;
font-size:14px;

&:focus {
    outline: none;
    border: 1px solid #000; /* Remove border on focus */
  }
`
const InputText = styled.textarea`
  font-family: Inter;
  height: 40px;
  font-size: 14px;
  padding: 10px;
  word-wrap: break-word;
  width:99%;
  display: block;
  border-radius: 4px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical; 
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;

  
p{
    text-align:start;
    display:flex;
    justify-content:start;
    align-self:start;
    margin-top:0px;
    margin-bottom:5px;
}
  .input{
    padding: 10.5px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    
    width: 20%;
    align-items: center;
    
    color: #344054;
 
  
    &:focus {
      outline: none;
      border-color: #000;
      box-shadow: none;
    }
  }
`;